export const linkifyOpts = {
	defaultProtocol: "https",
	format: {
		url: function (value) {
			return value.length > 50 ? value.slice(0, 50) + "…" : value;
		}
	},
	attributes: {
		rel: "nofollow"
	},
	target: {
		url: null
	}
}
