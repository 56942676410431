<template>
  <div class="" id="posts">
    <div
      class="project__content__posts__header pt-2 pb-2 px-2"
      :class="{
        'project__content__posts__header--stretch': isSearchShown,
        'project__content__posts__header--bottom': bottom_bar_active,
      }"
    >
      <transition name="show" appear>
        <StatusChanger
          v-model="filter"
          v-if="!isSearchShown"
          key="part-status"
          :statuses="getStatuses"
        ></StatusChanger>
        <div
          class="project__content__posts__header__search"
          v-else
          key="part-search"
        >
          <input
            type="text"
            class="input form__field__input--posts"
            placeholder="Search ideas..."
            ref="search"
            v-model="search_term"
          />
          <a
            href="#"
            @click.prevent="isSearchShown = false"
            class="btn px-1 ml-2 py-1 border-0"
          >
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </a>
        </div>
      </transition>

      <div class="flex items-center">
        <a
          href="#"
          v-if="!isSearchShown"
          @click.prevent="showSearch"
          class="btn px-3 ml-2"
        >
          <svg
            class="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </a>
        <router-link :to="{ name: 'new_post' }" class="btn-main px-3 ml-2">
          <svg
            class="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            ></path>
          </svg>
        </router-link>
      </div>
    </div>

    <div
      class="project__content__posts__nodata"
      v-if="posts.length == 0 && !isLoading"
    >
      <img :src="noPostsIcon" alt="No posts" />
      <p class="mt-2">Let's add some ideas!</p>
    </div>

    <div
      class="project__content__posts__nodata"
      v-if="paginatedPosts.length == 0 && posts.length !== 0"
    >
      <img :src="noPostsIcon" alt="No posts" />
      <p class="mt-2">No posts fit your criteria.</p>
    </div>

    <p class="text--center mt-2 mb-2" v-if="isLoading">Fetching posts...</p>

    <p
      class="text--center mt-2 mb-2"
      v-if="isSearchShown && search_results.length === 0 && !isLoading"
    >
      Start typing to search
    </p>

    <div :class="{ 'padding-of-missing-header': bottom_bar_active }">
      <PostList :posts="this.search_results" v-if="isSearchShown"></PostList>
      <PostList :posts="this.filteredPosts" v-else></PostList>
    </div>
  </div>
</template>

<script>
import Post from "./post.vue";
import PostList from "./postlist";
import noPostsIcon from "../images/empty_posts.png";
import plusIcon from "../images/icons/plus.svg";
import searchIcon from "../images/icons/search.svg";
import closeIcon from "../images/icons/close.svg";

import StatusChanger from "./status_changer";

import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import debounce from "lodash/debounce";

import { mapState } from "vuex";

export default {
  props: [],
  data: function () {
    return {
      noPostsIcon,
      plusIcon,
      searchIcon,
      closeIcon,
      sort: "trend_score",
      filter: null,
      page: 1,
      isLoading: false,
      isSearchShown: false,
      search_term: "",
      search_results: [],
      bottom_bar_active: false
    };
  },

  components: {
    Post,
    PostList,
    noPostsIcon,
    plusIcon,
    searchIcon,
    closeIcon,
    StatusChanger,
  },
  created: function () {
    this.isLoading = true;
    this.$store.dispatch("getAllPosts").then(() => {
      this.isLoading = false;
    });

    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed: function () {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted: function () {
    // applying URL params
    var current_url = new URL(window.location.href);
    var sort = current_url.searchParams.get("sort");
    var filter = current_url.searchParams.get("filter");

    if (sort) {
      this.sort = sort;
    }

    if (filter) {
      this.filter = filter;
    }
  },
  computed: {
    ...mapState({
      projectSubdomain: (state) => state.subdomain,
      posts: (state) => state.posts,
      statuses: (state) => state.statuses,
    }),

    filteredPosts: function () {
      var filtered = [];
      if(this.filter) {
        filtered = this.posts.filter((post) => {
          return post.status.id === this.filter;
        });
      } else {
        filtered = this.posts;
      }

      return filtered;
    },

    sortedAndFilteredPosts: function () {
      return reverse(sortBy(this.filteredPosts, this.sort));
    },

    paginatedPosts: function () {
      let perPage = 10;
      return this.sortedAndFilteredPosts.slice(0, this.page * perPage);
    },

    getStatuses: function(){
      return this.statuses;
    }
  },
  watch: {
    search_term: function (term) {
      this.performSearch();
    },
  },
  methods: {
    handleScroll(event) {
      var scrollTop =
        window.pageYOffset !== undefined
          ? window.pageYOffset
          : (
              document.documentElement ||
              document.body.parentNode ||
              document.body
            ).scrollTop;

      if (!this.bottom_bar_active && scrollTop > 100) {
        this.bottom_bar_active = true;
      }

      if (this.bottom_bar_active && scrollTop < 100) {
        this.bottom_bar_active = false;
      }
    },

    parameterize: function (input) {
      return input
        .toLowerCase()
        .replace(/[^a-z0-9]+/g, "-")
        .replace(/(^-|-$)/g, "");
    },

    changeSort(value) {
      this.sort = value;

      var current_url = new URL(window.location.href);
      current_url.searchParams.set("sort", value);
      window.history.replaceState({}, "", current_url);
    },

    changeFilter(el) {
      this.filter = el.value;

      var current_url = new URL(window.location.href);
      current_url.searchParams.set("filter", el.value);
      window.history.replaceState({}, "", current_url);
    },

    showSearch() {
      this.isSearchShown = true;
      this.$nextTick(() => this.$refs.search.focus());
    },

    performSearch: debounce(function () {
      if (this.search_term.length < 4) {
        this.search_results = [];
        return;
      }

      this.isLoading = true;
      this.$store.dispatch("searchPosts", this.search_term).then((data) => {
        this.search_results = data;
        this.isLoading = false;
      });
    }, 500),
  },
};
</script>

<style scoped>
.show-enter,
.show-leave-to {
  opacity: 0;
  transform: translateX(10px);
  position: absolute;
}

.show-enter-to,
.show-leave {
  opacity: 1;
  transform: translateX(0);
  position: relative;
}

.show-enter-active,
.show-leave-active {
  transition: opacity, transform 200ms ease-out;
}
</style>
