<template>
  <div class="add_vote_container mt-4">
    <div class="add_upvote_container">
      <div
        v-if="errorMessage"
        id="behalf-user-consent-error"
        class="error_show bg-red-100 border-red-300 px-4 py-3 mt-4 mb-3">{{this.errorMessage}}
      </div>
      <div class="">
        <input
        placeholder="Name required"
        autocomplete="off"
        required="required"
        v-model="name"
        type="text"
        name="name"
        class="input"
        @keyup.prevent="showEmail($event)"
        @keyup.enter="addbehalfVote()"
        />
      </div>
      <div class="mt-2" v-if="showEmailField1" :class="showEmailField ? 'field-visible' : 'field-hidden'">
        <input
        placeholder="Email (optional)"
        autocomplete="off"
        v-model="email"
        type="text"
        name="email"
        class="input"
        @keyup.prevent="showConsent($event)"
        @keyup.enter="addbehalfVote()"
        />
      </div>

      <div class="text-neutral-700 text-sm text-width mt-2 flex flex-row" 
           v-if="showConsentField1 && !projectPrivate" 
           :class="showConsentField ? 'field-visible' : 'field-hidden'"
        >
        <input
          type="checkbox"
          v-model="consent"
          value="1"
          name="notify_user"
          id="consent_agree_checkbox"
          class="consent-checkbox"
        />
        <div class="ml-2">
         <span class="text-neutral-700">Notify the user about updates and comments to this idea</span>
        </div>
      </div>

      <div class="mt-2">
        <button
          class="btn-main w-full text-center justify-center"
          @click="addbehalfVote()"
        >
          <svg v-if="isLoading" class="animate-spin h-8 w-5" viewBox="0 0 26 26">
            <circle class="opacity-0" cx="12" cy="12" r="10" fill="#EA4F36" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          <span v-else>
            Add Vote
          </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import EventBus from "event_bus";
  export default {
    props: ["post","project-private"],
    data: function() {
      return {
        name: "",
        email: "",
        errorMessage: "",
        isLoading: false,
        showEmailField: false,
        showEmailField1: false,
        showConsentField: false,
        showConsentField1: false,
        consent: false,
      };
    },
    methods: {
      showEmail (e){
        if (this.name){
          this.showEmailField1 = true;
          setTimeout(()=>{ this.showEmailField = true; }, 100);
        }
        else {
          this.showEmailField = false;
          setTimeout(()=>{ this.showEmailField1 = false; }, 500);
        }
      },
      showConsent (e){
        if (this.email){
          this.showConsentField1 = true;
          setTimeout(()=>{ this.showConsentField = true; }, 100);
        }
        else {
          this.showConsentField = false;
          setTimeout(()=>{ this.showConsentField1 = false; }, 500);
        }
      },
      addbehalfVote: function() {
        if(!this.name) {
          return this.errorMessage = "Please fill out name field"
        }
        this.errorMessage = ""

        var validEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (this.email && !this.email.match(validEmail))
          return this.errorMessage = "Invalid email address!"
        this.errorMessage = ""

        this.errorMessage = ""

        var behalf_user =
          "/boards/" + this.post.board_id + "/posts/" + this.post.id + "/vote_behalf_user";
        let data = new FormData();
        data.append("name", this.name);
        data.append("email", this.email);
        if (this.email){
          if(this.consent){
            data.append("voting_mode", "add_user_vote");
          }
          else{
            data.append("voting_mode", "add_post_voter_vote");
          }
        } 
        else{
          data.append("voting_mode", "add_anonymous_vote");
        }
        this.isLoading = true;
        Rails.ajax({
          url: behalf_user,
          type: "POST",
          data: data,

          beforeSend: function () {
            return true;
          },
          success: (data) => {
            this.isLoading = false;
            data.post && EventBus.$emit("updateUpvotes", data.post)
            if(data.error_message){
              return this.errorMessage = data.error_message
            }
            else if (data.vote_type == "add_user_vote"){
              EventBus.$emit("userVoter", data.voter)
            }
            else if (data.vote_type == "add_anonymous_vote"){
              EventBus.$emit("anonymousVoter", data.voter)
            }
            else if (data.vote_type == "add_post_voter_vote"){
              EventBus.$emit("postVoterVote", data.voter)
            }
            this.showEmailField = false;
            this.showEmailField1 = false;
            this.showConsentField = false;
            this.showConsentField1 = false;
            this.consent = false;
            this.errorMessage = ""
            this.name = ""
            this.email = ""
          }
        });
      }
    }
  };
</script>

<style scoped lang="scss">
  .add_vote_container{
    width: 100%;
    .add_upvote_container {
      justify-content: space-between;
      .input_name {
        width: calc(100% - 120px);
      }
      .btn-flat {
        @apply cursor-pointer flex items-center rounded-md text-gray-700 border border-brand-150 bg-brand-150 px-4 py-2 transition duration-150 ease-in-out outline-none;
        width: 100%;
        height: 42px;
        justify-content: center;
      }
      .btn-flat:hover {
    filter: brightness(0.95);
  text-decoration: none;
}
      .error_show {
        color: #e7210e;
        flex: inherit;
        border-radius: 0.375rem;
      }
    }
    .field-visible {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.5s linear;
    }
    .field-hidden {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.5s, opacity 0.5s linear;
    }
    .consent-checkbox {
      height: 15px;
      margin-top: 2px;
      width: 22px;
    }
  }

</style>
