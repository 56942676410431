<template>
    <div>
    <div v-show="showPicker" class="global-emoji-picker" :style="pickerStyle">
      <picker
        @select="selectEmoji"
        title="Pick your emoji…"
        emoji="point_up"
        color="#6b7280"
      />
    </div>
  </div>
</template>

<script>
import { Picker } from "emoji-mart-vue-2";
import EventBus from "../event_bus";

export default {
  components: {
    Picker,
  },
  data() {
    return {
      showPicker: false,
      pickerStyle: {},
      currentCallback: null,
      currentReactionableType: null,
      currentReactionableId: null,
      openButtonRef: null,
      isMobile: false,
    };
  },
  mounted() {
    EventBus.$on('openEmojiPicker', (data) => {
      this.openPicker(data);
    });
    document.addEventListener('click', this.handleOutsideClick);
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Initial check
  },
  beforeDestroy() {
    EventBus.$off('openEmojiPicker', this.openPicker);
    document.removeEventListener('click', this.handleOutsideClick);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    openPicker({ position, callback, reactionableType, reactionableId, openButtonRef }) {
      this.showPicker = true;
      this.isMobile = window.innerWidth < 768;
      this.updatePickerPosition(position);
      this.currentCallback = callback;
      this.currentReactionableType = reactionableType;
      this.currentReactionableId = reactionableId;
      this.openButtonRef = openButtonRef;
    },
    updatePickerPosition(position) {
      if (this.isMobile) {
        this.pickerStyle = {
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
          display: 'block',
        };
      } else {
        const pickerWidth = 338; // Default width of emoji-mart picker
        const pickerHeight = 435; // Default height of emoji-mart picker
        
        let top = position.top;
        let left = position.left;

        // Adjust top position if it would overflow the bottom of the viewport
        if (top + pickerHeight > window.innerHeight) {
          top = window.innerHeight - pickerHeight - 10; // 10px padding
        }

        // Adjust left position if it would overflow the right of the viewport
        if (left + pickerWidth > window.innerWidth) {
          left = window.innerWidth - pickerWidth - 10; // 10px padding
        }

        // Ensure top and left are not negative
        top = Math.max(10, top);
        left = Math.max(10, left);

        this.pickerStyle = {
          position: 'fixed',
          top: `${top}px`,
          left: `${left}px`,
          zIndex: 9999,
          display: 'block',
        };
      }
    },
    handleResize() {
      const newIsMobile = window.innerWidth < 768;
      if (this.isMobile !== newIsMobile) {
        this.isMobile = newIsMobile;
        if (this.showPicker) {
          // Hide the picker when resizing
          this.showPicker = false;
        }
      }
    },
    selectEmoji(emoji) {
      if (this.currentCallback) {
        this.currentCallback(emoji);
      }
      this.showPicker = false;
    },
    handleOutsideClick(event) {
      if (this.showPicker && 
          !this.$el.contains(event.target) && 
          this.openButtonRef && 
          !this.openButtonRef.contains(event.target)) {
        this.showPicker = false;
      }
    },
  },
};
</script>

<style scoped>
.global-emoji-picker {
  position: fixed;
  z-index: 9999;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
}

.emoji-mart {
  border: none!important;
}
</style>