<template>
    <section>
        <a class="whitespace-nowrap text-sm text-gray-500 sticky bg-white right-0 cursor-pointer" @click="this.openModal">Extend Trial</a>
    
        <Dialog title="Update Trial Days" v-model="dialogVisible">
          <div class="flex items-start ml-4 mr-2 pt-2 pb-2">
            <p> Enter number of days: </p>
          </div>
          <div class="flex items-center flex-col ml-4 mr-4">
            <input
              type="input"
              class="input"
              v-model="trialDays"
            />
          </div>
          <div class="mt-6 flex justify-center">
              <input type="submit" id="trial-btn" class="
                px-4 
                py-2 
                font-semibold 
                rounded-lg 
                border 
                flex 
                items-center 
                text-white 
                shadow-md 
                text-base 
                cursor-pointer 
                transition 
                duration-150 
                ease-in-out
                hover:no-underline 
                hover:shadow-lg
                mb-2" 
                @click="submitModal" 
                />
          </div>
        </Dialog>
    </section>
</template>

<script>
import Dialog from "../components/dialog";

export default {
    props: ["project-id", "project-update-url"],
    data() {
        return {
            dialogVisible: false,
            trialDays: "",
        };
    },
    components: {
        Dialog,
    },
    methods: {
        openModal() {
            this.dialogVisible = true;
        },
        submitModal() {
          var data = new FormData();
          data.append("project[trial_days]", this.trialDays);
          Rails.ajax({
              url: "/admin/projects/" + this.projectId + "/update_trial_days/",
              type: "PATCH",
              data: data,
              dataType: 'JSON'
          });
        },

    },
};
</script>

<style scoped>
#trial-btn {
    background-color: #f84339;
    border-color: #f84339;
}

#trial-btn:hover {
    color: #f84339;
    background-color: white;
}
</style>
