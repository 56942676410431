<template>
  <div>
    <div class="flex flex row items-center mt-4 cursor-pointer " id="show-status">
      <span class="handle" >
        <div class="cursor-move" id="roadmap-status-draggable-icon">
          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16"></path></svg>
        </div>
      </span>
      <div class="flex flex-row items-center justify-between roadmap-statuses" >
        <div class="ml-4 mr-3" id="roadmap-status-name">
          <span class="labels__item-inside branded-link">
            <span v-if="screenWidth < 401">
              <span v-if="label.name.length<10">{{ label.name}} </span>
              <span v-else>{{ label.name.substring(0,10)+".." }}</span>
            </span>
            <span v-else-if="screenWidth > 400 && screenWidth < 600">
              <span v-if="label.name.length<18">{{ label.name}} </span>
              <span v-else>{{ label.name.substring(0,18)+".." }}</span>
            </span>
            <span v-else>
              <span v-if="label.name.length<25">{{ label.name}}</span>
              <span v-else>{{ label.name.substring(0,25)+".." }}</span>
            </span>
          </span>
        </div>
        <div class="" id="roadmap-status-visible-hidden">
          <a
            href="#"
            class="text-neutral-800"
            @click.prevent="statusHideRoadmap()"
          >
           <span class="visible-btn-flat justify-center" v-if="!hideRoadmap">
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" :width="'20px'" :height="'20px'" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg>
              <span class="ml-1 text-sm">Visible</span>
            </span>
            <span class="visible-btn-flat hide-button justify-center" v-else >
            <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" :width="'20px'" :height="'20px'"xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path></svg>
              <span class="ml-1 text-sm">Hidden</span>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import trashIcon from "../images/icons/trash.svg";
import questionIcon from "../images/icons/question-circle.svg";

import VSwatches from "vue-swatches";

export default {
  props: ["original-label", "project-id"],

  data: function() {
    return {
      label: this.originalLabel,
      newName: this.originalLabel.name,
      hideRoadmap: this.originalLabel.hide_roadmap,
      isLoading: false,
      successMessage: "",
      screenWidth: null,
    };
  },

  mounted: function() {
    window.addEventListener("keydown", e => {
      if (e.key == "esc") {
        this.labelsOpen = false;
      }
    });
    this.screenWidth = window.innerWidth;
  },

  methods: {
    statusHideRoadmap() {
      this.isLoading = true;
      let params = new FormData();
      this.hideRoadmap = !this.hideRoadmap
      params.append("label[hide_roadmap]", this.hideRoadmap);
      Rails.ajax({
        url: "/statuses/" + this.label.id,
        type: "PATCH",
        data: params,
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          this.$emit("updatedStatusRoadmap", data);
          this.isLoading = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped>
  .labels__item--editing {
    height: 14.2rem !important;
  }

  .roadmap-statuses{
    width:100%;
  }

  .visible-btn-flat:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 250, 249, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(251, 250, 249, var(--tw-border-opacity));
    text-decoration: none !important;
  }
  .visible-btn-flat {
    width: 90px;
    --tw-bg-opacity: 1;
    background-color: rgba(232, 229, 229, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(232, 229, 229, var(--tw-border-opacity));
    border-radius: 0.375rem;
    border-width: 1px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .hide-button{
    filter: opacity(0.5);
  }

</style>
