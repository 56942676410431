<template>
  <div>
    <label class="font-semibold text-sm leading-3 text-gray-700">
      {{ label }}
    </label>
    <div class="relative" @click="showInput = true">
      <div class="flex flex-row input rounded mt-1 p-1">
        <div
          v-if="selectedAuthor"
          class="
            flex
            box-border
            bg-white
            border border-solid border-gray-300
            rounded
            max-w-max
            min-w-max
            p-1
          "
        >
          <avatar
            class="rounded-lg bg-center bg-cover mr-1 border-2 border-white"
            :size="28"
            :src="selectedAuthor.avatar_url"
            :username="selectedAuthor.name"
          >
          </avatar>
          <div
            class="
              flex
              items-center
              mr-1
              font-normal
              text-sm
              leading-3
              text-gray-700
            "
          >
            {{ selectedAuthor.name }}
          </div>
          <img
            class="ml-2 mr-1 cursor-pointer"
            src="../../assets/images/cross-mark.svg"
            width="5.66"
            height="5.66"
            @click="clearAuthor()"
          />
        </div>
        <input
          v-if="showInput"
          id="select-input"
          placeholder=""
          v-model="value"
          type="text"
          name="select-input"
          class="w-full border-none p-1"
          autofocus
          autocomplete="off"
          :required="!selectedAuthor"
          @input="$emit('input', $event.target.value)"
          @keydown="resetSelectedAuthor"
        />
      </div>
    </div>
    <div
      class="
        z-50
        mt-1
        options
        box-border
        absolute
        bg-white
        border border-solid border-gray-400
        rounded
      "
      :class="boardPostAuthorSelect ? 'dropdown-width' : ''"
      :style="{border: (filteredOptions.length == 0 && !showOptions)? 'none': ''}"
      v-if="showResultDropdown"
      v-click-outside="onClickOutside"
    >
      <div id="option-dropdown" class="overflow-y-auto">
        <p
          id="option-item"
          class="cursor-pointer px-2 py-1"
          v-for="option in filteredOptions"
          :key="option.id"
          @click="selectAuthor(option)"
        >
          {{ option.name }} ({{ option.email }})
        </p>
        <p class="px-3 py-4" v-if="filteredOptions.length == 0 && showOptions">
          No results found
        </p>
      </div>
      <button
        v-if= "showOptions"
        class="w-full p-3 border-t text-left"
        type="button"
        @click="showField()"
      >
        + Create new user
      </button>
    </div>
  </div>
</template>

<script>
import clickOutSide from "v-click-outside";

export default {
  name: "custom-select",

  props: ["value", "label", "options", "current-user", "showOptions","board-post-author-select"],

  emits: ["showAuthorEmailField", "addSelectedAuthor", "removeSelectedAuthor"],

  directives: {
    clickOutSide,
  },
  data() {
    return {
      selectedAuthor: this.currentUser,
      showInput: false,
      showResultDropdown: false,
      searchResults: this.options,
    };
  },

  computed: {
    filteredOptions() {
      return this.searchResults;
    },
  },

  methods: {
    resetSelectedAuthor(event) {
      if (event.keyCode == 8) {
        this.selectedAuthor = null;
        this.$emit("removeSelectedAuthor");
      }
    },
    selectAuthor(author) {
      this.selectedAuthor = author;
      this.$emit("addSelectedAuthor", this.selectedAuthor.id);
      this.$emit("hideAuthorEmailField");
      this.value = "";
      this.showResultDropdown = false;
    },
    onClickOutside (event) {
      console.log('Clicked outside. Event: ', event)
      this.showResultDropdown = false;
    },
    showField() {
      this.$emit("showAuthorEmailField", this.value);
      this.$emit("showDropdown", false);
      this.clearAuthor();
    },
    clearAuthor() {
      this.selectedAuthor = null;
      this.$emit("removeSelectedAuthor");
      if (this.showResultDropdown) this.showResultDropdown = false;
    },
  },

  watch: {
    value(val) {
      if (val.length >= 2) {
        var query = new RegExp(val, "i");
        this.searchResults = this.options.filter((user) => {
          return user.name.match(query);
        });
        this.showResultDropdown = true;
      } else if (val.length == 1 && this.selectedAuthor != null) {
        this.clearAuthor();
      } else if (val.length == 0) {
        this.showResultDropdown = false;
        this.$emit("hideAuthorEmailField");
        this.$emit("showDropdown", true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.options {
  width: 456px;
}
#option-dropdown p:first-child {
  padding-top: 12px;
}
#option-dropdown p:last-child {
  padding-bottom: 12px;
}
#select-input:focus-visible {
  outline: none;
}
#option-item:hover {
  background-color: #ededed;
}
@media screen and (max-width: 640px) {
  .options {
    width: 350px;
  }
}
.dropdown-width {
  width: 18.7rem
}
</style>