<template>
  <div class>
    <a
      href="#"
      class="redeem-button text-neutral-600"
      v-if="!isInputShown"
      @click.prevent="open()"
    >
      <simple-svg :filepath="RedeemIcon" :width="'30px'" />
    </a>
    <div class="backdrop" v-else>
      <div class="" v-if="hasError">
        <p class="text-right text-red-500">{{ error }}</p>
        <a
          href="#"
          @click.prevent="hasError = false"
          class="block mt-2 text-right backdrop__close"
          >Try again</a
        >
      </div>
      <div class="flex flex-col items-end" v-else>
        <label for="code" class="label">Coupon code</label>
        <input
          id="code"
          type="text"
          class="input"
          placeholder="Your code here"
          ref="code_input"
          v-model="code"
        />
        <button
          class="btn mt-2"
          @click="performRedeem()"
          :disabled="isLoading || code.length < 3"
        >
          {{ isLoading ? "Working..." : "Redeem" }}
        </button>
        <a
          href="#"
          class="backdrop__close mt-2"
          @click.prevent="isInputShown = false"
          >Never mind</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import RedeemIcon from "../images/icons/redeem.svg";

import debounce from "lodash/debounce";

export default {
  data() {
    return {
      isInputShown: false,
      RedeemIcon,
      code: "",
      isLoading: false,
      hasError: false,
      error: "",
    };
  },
  components: {
    RedeemIcon,
  },
  methods: {
    open() {
      this.isInputShown = true;
      this.$nextTick(() => {
        this.$refs.code_input.focus();
      });
    },

    performRedeem() {
      this.isLoading = true;
      this.redeem();
    },

    redeem: debounce(function () {
      var data = new FormData();
      data.append("code", this.code);

      Rails.ajax({
        url: "/redeem",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isLoading = false;
        },
        error: (data, status) => {
          this.error = "Error: " + data.message;
          this.hasError = true;
          this.isLoading = false;
        },
      });
    }, 500),
  },
};
</script>

<style scoped>
.redeem-button {
  display: block;
}

.backdrop {
  position: absolute;
  right: -2rem;
  top: -2.5rem;
  width: 300px;
  height: 200px;
  background: #2e4052;
  box-shadow: -10px 10px 20px 0px #0000002b;
  border-bottom-left-radius: 250px;
  padding: 2rem 2rem 2rem 3rem;
  z-index: 2;
  animation: comein 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-origin: top right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@keyframes comein {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.backdrop label {
  color: white;
  text-align: end;
  font-size: 1.1rem;
}

.backdrop input {
  background: #374d63;
  border-color: #45607a;
  color: white;
}

.backdrop input::placeholder {
  color: #fefefe;
  opacity: 0.7;
}

.backdrop .backdrop__close {
  color: white;
  opacity: 0.7;
}
</style>
