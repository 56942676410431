<template>
  <ul :class="user_admin ? 'mt-1' : 'flex items-center flex-wrap'">
    <li v-for="(voter, index) in voters" :key="voter.id">
      <div class="flex flex-row items-center justify-between mb-1 ">
        <div>
          <li :class="user_admin ? 'flex items-center rounded-md hover:bg-brand-50' : 'mt-2 flex-row flex'" class="pr-1">
            <div v-if="user_admin" class="flex flex-row items-center ">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 258.75 258.75" style="enable-background:new 0 0 258.75 258.75;    background-color: #4299e1;
                height: 32px;
                width: 32px;
                border-radius: 10px;
                border: 2px solid white;
                margin-right: 0.6rem;" xml:space="preserve"
                >
              <g>
                <circle fill="#fff" cx="129.375" cy="90" r="30"/>
                <path fill="#fff" style="transform: scale(0.5) translate(130px, 100px);" d="M129.375,150c-60.061,0-108.75,48.689-108.75,108.75h217.5C238.125,198.689,189.436,150,129.375,150z"/>
              </g>
              </svg>

              <div class="text-neutral-800 hover:text-project-500 hover:no-underline flex-1">
                Anonymous Voter
              </div>
            </div>
          </li>
        </div>

        <div v-if="user_admin">
          <div v-if="showIcon(voter)">
            <div @click="AnnonymousVoteDelete(voter, voter.id, index)" class="" >
              <svg
                class="w-5 h-5 icon"
                fill="rgba(199, 199, 199)"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                title="Remove this vote"
                v-tippy="{placement: 'top'}"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import Avatar from "vue-avatar";
import EventBus from "event_bus";

export default {
  props: ["all_voters", "post", "user_admin", "id", "current_user", "image-url"],
  data: function() {
    return {
      voters: this.all_voters || [],
      voteIndex: "",
    };
  },
  
  methods: {
    AnnonymousVoteDelete: function(voter, id, indexValue) {
      var Annonymous_voter =
        "/boards/" + this.post.board_id + "/posts/" + this.post.id + "/vote_behalf_user";
      let data = new FormData();
      data.append("vote_id", id);
      data.append("voting_mode", "delete_annonymous_vote");
      this.voteIndex = indexValue;

      Rails.ajax({
        url: Annonymous_voter,
        type: "POST",
        data: data,
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.voters.splice(this.voteIndex, 1);
          data.post && EventBus.$emit("updateUpvotes", data.post)
        }
      });
    },
    showIcon (voter){
     if (voter.email){
      return (this.current_user.email != voter.email)
     } 
     else{
      return true; 
     }
    },
  },
};
</script>
<style scoped>
  .icon:hover {
  fill: #DA4567;
  cursor: pointer;
  @apply outline-none
}
</style>
