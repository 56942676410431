<template>
  <section>
    <div class="bg-white border border-red-500 shadow rounded-lg mt-4">
      <div class="px-4 py-5 sm:p-6 flex items-center justify-between">
        <div class="">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Delete account
          </h3>
          <div
            class="mt-2 max-w-xl text-sm text-gray-500"
            v-if="this.projectsCount === 0"
          >
            <p>
              Once you delete your account, you will lose all data associated
              with it.
            </p>
          </div>
          <div
            class="mt-2 max-w-xl text-sm text-red-600 flex items-center"
            v-else
          >
            <svg
              class="w-6 h-6 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
              ></path>
            </svg>
            <p>
              You can't delete your account while being an admin of a project.
            </p>
          </div>
        </div>
        <button
          type="button"
          v-if="this.projectsCount === 0"
          @click.prevent="dialogVisible = true"
          class="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
        >
          Delete your account
        </button>

        <button
          v-else
          class="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-gray-700 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm cursor-not-allowed"
        >
          Delete your account
        </button>
      </div>
    </div>

    <Dialog title="Confirmation" v-model="dialogVisible">
      <div class="px-4 py-4">
        <h2 class="text-lg text-neutral-800">
          You are about to permanently delete your account
        </h2>

        <p class="mt-4">This action cannot be undone.</p>

        <div class="flex justify-end items-center mt-4 space-x-2">
          <button class="btn" @click="dialogVisible = false">Cancel</button>
          <button
            class="btn-main bg-red-500 border-red-500 hover:border-red-500 hover:text-red-500"
            type="primary"
            @click="deleteUser"
          >
            Delete account
          </button>
        </div>
      </div>
    </Dialog>
  </section>
</template>

<script>
import Dialog from "../components/dialog";

export default {
  props: ["user-handle", "projects-count"],
  data() {
    return {
      dialogVisible: false,
    };
  },
  components: {
    Dialog,
  },
  methods: {
    deleteUser() {
      let delUrl = "/users/" + this.userHandle;

      Rails.ajax({
        url: delUrl,
        type: "DELETE",
        processData: true,
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          console.log("Deleted", data);
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
  },
};
</script>

<style></style>
