<template>
  <span
    class="status text-sm font-medium rounded-full tracking-wide inline-block"
    :style="{'color': status.color}"
    >{{ status.name }}</span
  >
</template>

<script>
export default {
  props: ["status"]
};
</script>

<style scoped>
.status::first-letter {
  text-transform: uppercase;
}
</style>
