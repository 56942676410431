<template>
  <div class="border border-gray-300 rounded-md">
    <div class="flex flex-col m-4">
      <div class="h-12 flex justify-between">
        <img
          src="../../../assets/images/jira_logo_icon.svg"
          alt="jira-icon"
          width="36"
          height="36"
        />
        <div class="flex flex-row" v-if="connected">
          <a
            class="btn-main hover:bg-project-500 hover:text-white h-10 shadow-none text-sm border hover:border-project-500 rounded mr-2"
          >
            Connected
          </a>
          <a
            id="settings-btn"
            class="btn-main bg-gray-200 hover:bg-project-500 h-10 text-sm shadow-none border-gray-200 hover:border-project-500 rounded"
            @click="this.openModal"
          >
            <svg
              class="w-5 h-5"
              viewBox="0 0 15 16"
              fill="#999999"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.47357 16L5.17929 13.4228C5.08774 13.4085 4.92425 13.3298 4.68883 13.1866C4.45341 13.0434 4.18529 12.8644 3.88447 12.6497L1.72643 13.6376L0 10.3517L1.88338 8.78389C1.85722 8.66935 1.841 8.55109 1.83472 8.4291C1.82792 8.30769 1.82452 8.16823 1.82452 8.01074C1.82452 7.91051 1.83106 7.79225 1.84414 7.65595C1.85722 7.52021 1.8703 7.36644 1.88338 7.19463L0 5.64832L1.72643 2.38389L3.90409 3.39329C4.08719 3.23579 4.28652 3.08546 4.50206 2.94228C4.71813 2.79911 4.94387 2.68456 5.17929 2.59866L5.47357 0H8.92643L9.22071 2.57718C9.46921 2.67741 9.68841 2.79195 9.87832 2.92081C10.0677 3.04966 10.2605 3.20716 10.4567 3.39329L12.6736 2.38389L14.4 5.64832L12.4381 7.25906C12.4643 7.40224 12.4808 7.52737 12.4876 7.63447C12.4939 7.74214 12.497 7.86756 12.497 8.01074C12.497 8.1396 12.4939 8.26502 12.4876 8.38701C12.4808 8.50842 12.4643 8.64072 12.4381 8.78389L14.3804 10.3517L12.6343 13.6376L10.4567 12.6282C10.2866 12.7857 10.1035 12.9289 9.90736 13.0577C9.71117 13.1866 9.48229 13.3083 9.22071 13.4228L8.92643 16H5.47357ZM7.18038 10.502C7.80818 10.502 8.34128 10.2586 8.77968 9.77181C9.21757 9.28501 9.43651 8.69799 9.43651 8.01074C9.43651 7.32349 9.21757 6.73646 8.77968 6.24966C8.34128 5.76286 7.80818 5.51946 7.18038 5.51946C6.53951 5.51946 6.00013 5.76286 5.56225 6.24966C5.12384 6.73646 4.90463 7.32349 4.90463 8.01074C4.90463 8.69799 5.12384 9.28501 5.56225 9.77181C6.00013 10.2586 6.53951 10.502 7.18038 10.502Z"
                fill="#999999"
              />
            </svg>
          </a>
        </div>
        <div v-else>
          <button
            @click="openModal"
            :disabled="this.isDisabled"
            class="btn-main text-sm bg-gray-200 hover:bg-project-500 text-gray-700 shadow-none hover:text-white border-gray-200 hover:border-project-500 rounded"
            type="button"
          >
            Connect
          </button>
        </div>
      </div>
      <div class="">
        <h4 class="font-bold mt-2 text-neutral-800 text-base">Jira</h4>
        <p class="text-sm mt-2 text-gray-500">
          Send ideas to your Jira project
        </p>
      </div>
    </div>

    <div v-show="dialogVisible">
      <transition name="showUp" appear>
        <div
          class="fixed flex inset-0 bg-black bg-opacity-25 z-40"
          @click.self="handleClose"
        ></div>
      </transition>
      <transition name="comeIn">
        <div class="fixed flex inset-0 z-50" @click.self="handleClose">
          <div
            id="dialog-modal"
            class="w-full max-w-screen-sm max-h-screen bg-white rounded-lg shadow-lg m-auto overflow-auto"
          >
            <div
              class="flex text-gray-700 items-center justify-center m-4 pl-4 pr-1 mt-7"
            >
              <h4 class="font-bold text-xl text-gray-700">Connect Jira</h4>
              <a
                href="#"
                class="text-neutral-700 hover:text-project-500 ml-auto -mt-4"
                @click.prevent="handleClose"
                ><svg
                  class="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path></svg
              ></a>
            </div>
            <jira-integration
              v-if="allowJiraIntegration || project.trialValid"
              :resource-url="resourceUrl"
              :project-id="project.id"
              :integration="integration"
              @updateIntegration="updateIntegration"
            />
            <alert
              v-else
              message="This integration is only available for higher plans, in order to use it please upgrade your plan"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Alert from "../alert.vue";
import Dialog from "../integration_dialog";

export default {
  props: ["resource-url", "project", "integration", "allow-jira-integration", "isDisabled"],
  data: function () {
    return {
      dialogVisible: false,
      notConnected: true,
      connectedIntegration: this.integration,
    };
  },
  components: {
    Dialog,
    Alert,
  },
  methods: {
    openModal() {
      this.dialogVisible = true;
    },
    updateIntegration(integration) {
      this.connectedIntegration = integration;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
  computed: {
    connected: {
      get() {
        return (
          this.connectedIntegration &&
          Object.keys(this.connectedIntegration).length > 0
        );
      },
      set(value) {
        this.notConnected = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
#settings-btn:hover {
  path {
    fill: white;
  }
}

.bg-dropdown {
  background: linear-gradient(180deg, #ffffff 0%, #efefef 100%);
}
.showUp-enter-active,
.showUp-leave-active {
  transition: opacity 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.showUp-enter,
.showUp-leave-to {
  opacity: 0;
}

.showUp-leave,
.showUp-enter-to {
  opacity: 1;
}

.comeIn-enter-active,
.comeIn-leave-active {
  transition: opacity 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.comeIn-enter,
.comeIn-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.comeIn-leave,
.comeIn-enter-to {
  opacity: 1;
  transform: scale(1);
}
@media screen and (max-width: 640px) {
  #dialog-modal {
    width: 90%;
  }
}
</style>
