<template>
  <div class="relative mt-2">
    <Dialog title="Confirmation" v-model="dialogOpen">
      <div class="px-4 py-4 text-neutral-800">
        <h2 class="text-lg">
          You are about to merge this idea into
          <span class="font-bold">{{ postToMerge.title }}</span>
        </h2>

        <ul class="mt-4">
          <li class="flex items-center">
            <svg
              class="w-5 h-5 mr-2 text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path></svg
            ><span>Upvotes will be merged</span>
          </li>
          <li class="flex items-center">
            <svg
              class="w-5 h-5 mr-2 text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path></svg
            ><span>Comments will be merged</span>
          </li>
          <li class="flex items-center">
            <svg
              class="w-5 h-5 mr-2 text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path></svg
            ><span>Attachments will be merged</span>
          </li>
          <li class="flex items-center">
            <svg
              class="w-5 h-5 mr-2 text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clip-rule="evenodd"
              ></path></svg
            ><span>
              This idea will appear as a Merged idea in
              <span class="font-bold">{{ postToMerge.title }}</span></span
            >
          </li>
        </ul>

        <p class="mt-4 flex items-center">
          <svg
            class="w-5 h-5 mr-2 text-orange-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            ></path>
          </svg>
          <span>This action can't be undone.</span>
        </p>

        <div class="mt-4 flex items-center justify-end space-x-2">
          <button class="btn" @click.prevent="dialogOpen = false">
            Cancel
          </button>
          <button
            class="btn bg-project-500 text-white border-project-500 hover:text-project-500"
            @click.prevent="performMerge()"
            :disabled="isLoading"
          >
            <svg
              v-if="isLoading"
              class="w-6 h-6 animate-pulse"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"
              ></path>
            </svg>
            <span v-else>Merge</span>
          </button>
        </div>
      </div>
    </Dialog>
    <transition name="switch" mode="out-in" @after-enter="afterAnim">
      <input
        class="input"
        v-if="inputVisible"
        type="text"
        v-model="inputValue"
        ref="postInput"
        placeholder="Search ideas..."
        @keyup.esc="handleInputCancel()"
        @blur="handleBlur"
      />
      <button v-else class="w-full btn-flat" @click.prevent="showInput">
        <simple-svg
          :filepath="mergeIcon"
          :width="'16px'"
          :height="'16px'"
          class="mr-2"
        />
        <span>Merge into another idea</span>
      </button>
    </transition>

    <transition name="show" appear>
      <ul
        class="absolute z-10 bg-white rounded-b-md border border-neutral-200 shadow-md w-full py-2"
        v-if="mergeSuggestions.length > 0 && inputVisible"
      >
        <li
          class=""
          v-for="suggestionItem in mergeSuggestions"
          :key="suggestionItem.id"
        >
          <a
            href="#"
            class="flex items-center w-full py-3 px-3 text-neutral-800 text-sm hover:no-underline hover:bg-neutral-100 transition duration-150 ease-in-out"
            @click.prevent="
              openConfirmDialog(suggestionItem.id, suggestionItem.title)
            "
          >
            <div class="flex items-center mr-2">
              <span class="">
                {{
                  suggestionItem.cached_votes_up + suggestionItem.manual_upvotes
                }}
              </span>
              <svg
                class="w-4 h-4 text-neutral-700"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <div>{{ suggestionItem.title }}</div>
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import mergeIcon from "images/merge.svg";
import Dialog from "../components/dialog";

import throttle from "lodash/throttle";

export default {
  props: ["original-post", "search-url", "merge-url", "merged-posts"],

  data() {
    return {
      inputVisible: false,
      inputValue: "",
      mergeIcon,
      mergeSuggestions: [],
      dialogOpen: false,
      postToMerge: {},
      isLoading: false,
    };
  },

  components: {
    mergeIcon,
    Dialog,
  },

  methods: {
    afterAnim(el) {
      if (el.classList.contains("input")) {
        el.focus();
      }
    },

    showInput() {
      this.inputVisible = true;
    },

    handleInputCancel() {
      this.inputVisible = false;
      this.inputValue = "";
    },

    handleBlur(event) {
      if (this.inputValue || event.relatedTarget) {
      } else {
        this.inputVisible = false;
      }
    },

    getPostSuggestions: throttle(function () {
      if (this.inputValue.length < 2) {
        this.mergeSuggestions = [];
        return;
      }

      var original_post = this.originalPost;

      Rails.ajax({
        url: this.searchUrl + "?search_term=" + this.inputValue,
        type: "GET",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          // remove current post from options
          let originalPostIndex = data.findIndex((post) => { return post.id == original_post.id })
          if(originalPostIndex != -1) {
            data.splice(originalPostIndex,1);
          }
          this.mergeSuggestions = data
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    }, 500),

    openConfirmDialog(post_id, post_name) {
      this.dialogOpen = true;
      this.postToMerge = {
        id: post_id,
        title: post_name,
      };
    },

    performMerge() {
      this.isLoading = true;
      var params = new FormData();
      params.append("post_id", this.postToMerge.id);

      Rails.ajax({
        url: this.mergeUrl,
        type: "POST",
        data: params,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isLoading = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isLoading = false;
        },
      });
    },
  },

  watch: {
    inputValue: function () {
      this.getPostSuggestions();
    },
  },
};
</script>

<style scoped>
.switch-enter {
  opacity: 0;
}

.switch-leave-to {
  opacity: 0;
}

.switch-enter-to,
.switch-leave {
  opacity: 1;
}

.switch-enter-active,
.switch-leave-active {
  transition: opacity 150ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.show-enter,
.show-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.show-enter-to,
.show-leave {
  opacity: 1;
  transform: translateY(0);
}

.show-enter-active,
.show-leave-active {
  transition: opacity, transform 150ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
