import Vue from "vue/dist/vue.esm";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  billing_warnings: [],
  plans: [],
  currentProjectPlan: null,
  planMessages: [],
  warningsUrl: null,
  plansUrl: null,
  comparePlanUrl: null,
  currentPlanUrl: null,
  stripeSubscriptionID: '',
  stripePriceKeys: {},
  stripeSubscription: {},
  allUsers: [],
};

const getters = {
  getWarningMessages(state) {
    return state.billing_warnings;
  },
  getCurrentProjectPlan(state) {
    return state.currentProjectPlan;
  },
  getPlanCompareMessages(state) {
    return state.planMessages;
  },
  getPlanFeatures(state) {
    return state.plans;
  },
  getSubscriptionId(state){
    return state.stripeSubscriptionID;
  },
  getStripePriceKeys(state){
    return state.stripePriceKeys;
  },
  getStripeSubscription(state){
    return state.stripeSubscription;
  },
  allUsers(state){
    return state.allUsers
  }
}

const actions = {
  async setWarningsUrl({ state, commit }, url) {
    await commit("setWarningsUrl", url);
  },
  async setPlansUrl({ state, commit }, url) {
    await commit("setPlansUrl", url);
  },
  async setCurrentPlanUrl({ state, commit }, url) {
    await commit("setCurrentPlanUrl", url);
  },
  async setComparePlanUrl({ state, commit }, url) {
    await commit("setComparePlanUrl", url);
  },
  async getAllWarnings({ state, commit }) {
    await Rails.ajax({
      url: state.warningsUrl,
      type: "GET",
      beforeSend: function () {
        return true;
      },
      success: (data) => {
        commit("setWarnings", data.data);
      },
      error: (data) => {
        console.log("Error: ", +data);
      },
    });
  },
  async getcurrentProjectUsers({ commit }, url) {
    await Rails.ajax({
      url: url,
      type: "GET",
      beforeSend: function () {
        return true;
      },
      success: (data) => {
        commit("setAllUsers", data);
      },
      error: (data) => {
        console.log("Error: ", +data);
      },
    });
  },
  async getPlan({ state, commit }) {
    await Rails.ajax({
      url: state.plansUrl,
      type: "GET",
      beforeSend: function () {
        return true;
      },
      success: (data) => {
        commit("setPlans", data.data);
      },
      error: (data) => {
        console.log("Error: ", +data);
      },
    });
  },
  async fetchCurrentPlan({ state, commit }) {
    await Rails.ajax({
      url: state.currentPlanUrl,
      type: "GET",
      beforeSend: function () {
        return true;
      },
      success: (data) => {
        commit("setCurrentPlan", data.current_plan);
      },
      error: (data) => {
        console.log("Error: ", +data);
      },
    });
  },
  async comparePlan({ state, commit }, planName) {
    await Rails.ajax({
      url: state.comparePlanUrl + `?plan_name=${planName}`,
      type: "GET",
      beforeSend: function () {
        return true;
      },
      success: (data) => {
        commit('setPlanCompareMessages', data.messages);
      },
      error: (data) => {
        console.log("Error: ", +data);
      },
    });
  },
  async stripePriceKeys({state, commit}, priceUrl){
    await Rails.ajax({
      url: priceUrl,
      type: "GET",
      beforeSend: function () {
        return true;
      },
      success: (data) => {
        commit('setStripePriceIDs', data.keys);
      },
      error: (data) => {
        console.log("StripePriceResponseError: ", +data);
      },
    });
  }
}

const mutations = {
  setWarningsUrl(state, url) {
    state.warningsUrl = url;
  },
  setPlansUrl(state, url) {
    state.plansUrl = url;
  },
  setCurrentPlanUrl(state, url) {
    state.currentPlanUrl = url;
  },
  setComparePlanUrl(state, url) {
    state.comparePlanUrl = url;
  },
  setWarnings(state, warnings) {
    state.billing_warnings = warnings;
  },
  setAllUsers(state, users){
    state.allUsers = users;
  },
  setPlans(state, plan) {
    state.plans = plan;
  },
  setCurrentPlan(state, plan) {
    state.currentProjectPlan = plan;
  },
  setPlanCompareMessages(state, messages) {
    state.planMessages = messages;
  },
  setStripeSubscriptionID(state, id){
    state.stripeSubscriptionID = id;
  },
  setStripePriceIDs(state, keys){
    state.stripePriceKeys = keys;
  },
  setStripeSubscription(state, data){
    state.stripeSubscription = data;
  }
}
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
});
