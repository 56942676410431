<template>
  <div>
    <h3 class="text-xl font-bold text-neutral-800">
      Widget <sup class="beta">Beta</sup>
    </h3>
    <p class="text-neutral-800">
      FeedBear Widget lets you embed the idea form anywhere on your site. This allows your users to submit their ideas without leaving what they're doing.
      The setup and configuration of the widget require some changes to your code. You can do it yourself (💪) or invite an engineer to your FeedBear project via team settings.
    </p>

    <div class="alert alert--info import-errors-alert mb-5" v-if="!this.project.jwt">
      <p></p>
      <p class="mt-2">
        <strong>Using a new key instead of the SSO key</strong><br/>
        If you don't have a new key, we'll keep using the SSO key.<br/>For new projects, a new private key is generated. When a new key is generated, the SSO key won't be used for JWT in the Widget.
      </p>
    </div>

    <h4 class="mt-6 font-semibold text-neutral-900">
      1. Configure the widget
    </h4>
    <p class="mt-2 text-neutral-800">
      Pick a board you want to use and add the following code right before the closing
      <code>&#x3C;body&#x3E;</code> tag:
    </p>

     <div class="mt-2">
      <label for="board-select" class="label">Fill board id for:</label>
      <div class="relative max-w-md">
        <select
          name="board"
          id="board-select"
          class="select"
          v-model="selectedBoard"
          ref="boardSelect"
        >
          <option
            :value="board.slug"
            v-for="board in this.project.boards"
            :key="board.id"
          >
            {{ board.name }}
          </option>
          <option
            value="All boards"
            key="All boards"
          >
            {{ 'All boards' }}
          </option>
        </select>
        <svg
          class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
    </div>

    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="">
          <div class="pb-5 pt-2 flex-auto">
            <div class=" sso-webhook-style">
              <div>
                <prism-editor class="prism-code-editor rounded" v-model="scriptCode" :line-numbers="lineNumbers" :highlight="highlighter" readonly></prism-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h4 class="mt-4 font-semibold text-neutral-900">
      2. Hook FeedBear to an element
    </h4>
    <p class="mt-2 text-neutral-800">
      Pick an element (usually a button or a link) that will trigger FeedBear
      widget popup on click. When using a default configuration, simply add
      <code>data-feedbear-button</code> to that element. For example:
    </p>
    
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="">
          <div class="pb-5 pt-2 flex-auto">
            <div class=" sso-webhook-style">
              <div>
                <prism-editor class="prism-code-editor rounded" v-model="scriptCode1" :line-numbers="lineNumbers" :highlight="highlighter" readonly></prism-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <h4 class="mt-4 font-semibold text-neutral-900">
      3. Configure the JWT (optional)
    </h4>
    <p class="mt-2 text-neutral-800">
      JWT allows your users to post feedback using their
      existing accounts without leaving your site.
    </p>
    <p class="mt-2 text-neutral-800">
      Here's your <strong>private signature key</strong>:
    </p>
    <div class="flex mt-2 inp-width ">
      <div class="relative flex justify-between items-center input ">
        <div class="parentInput">
          <input
            v-model="projectToken"
            class="childInput"
            :readonly="true"
          />
        </div>
        <tippy
          interactive
          :animate-fill="false"
          theme="translucent"
          animation="fade"
          trigger="click"
          arrow="roundArrow"
        >
          <template v-slot:trigger>
            <div @click="clipCopy" class="cursor-pointer">
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"></path></svg>
            </div>
          </template>
          <span>
            <p class="font-bold">COPIED!</p>
          </span>
        </tippy>
      </div>
      <button class="btn-main whitespace-nowrap ml-2" @click.prevent="generateNewKeyPopup = true">Generate new key</button>
    </div>
    <!-- <p class="mt-2 text-neutral-800">
      Follow our guide,
      <a
        href="https://help.feedbear.com/article/25-sso"
        data-beacon-article-sidebar="5f32bfeb042863444aa02a10"
        target="_blank"
        >How to set up Single Sign-On</a
      >
      to generate SSO token on your server.
    </p> -->

    <h4 class="mt-6 font-semibold text-neutral-900">Questions?</h4>
    <p class="mt-2 text-neutral-800">
      If you have any questions or issues,
      <a href="#" @click.prevent="openBeacon()">contact us</a> using the button
      in the bottom right or send an email to
      <a href="mailto:support@feedbear.com" target="_blank" style= "text-decoration: underline;"
        >support@feedbear.com.</a
      >
    </p>
    <Dialog v-model="generateNewKeyPopup" title="Generate new key" custom-width-class="w-3/12">
      <div class="p-4 pt-0">
        <div class="p-2 pl-0">
          Are you sure you want to generate a new key?<br> Once a new key is
          generated your old key will be deleted, this is not reversible.
        </div>
        <div class="flex justify-start mt-2">
          <a
            href="#"
            class="btn-flat justify-center mr-2 whitespace-nowrap"
            @click.prevent="generateNewKeyPopup = false"
          >
            Cancel</a
          >
          <a
            href="#"
            class="btn-main justify-center mx-2 whitespace-nowrap"
            @click.prevent="regenerateFeedbearToken"
          >
            Generate new key</a
          >
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import 'highlight.js/styles/github.css';
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
import Dialog from "../../components/dialog";

export default {
  props: ["project","projectToken", "projectUpdateUrl", "projectJwt"],
  data: function () {
    return {
      selectedBoard: this.project.boards[0].slug,
      //selectedBoard: 'All boards',
      codes: '',
      lineNumbers: true,
      generateNewKeyPopup: false,
      boards_text: '',
    };
  },

  mounted() {
    this.$refs.boardSelect.selectedIndex = 0;
    Beacon("suggest", ["5f328f5d042863444aa0250e", "5f32bfeb042863444aa02a10"]);
  },
  computed: {
    scriptCode1() {
      return this.codes = `<button data-feedbear-button>Feedback?</button>`;
    },
    scriptCode() {
      this.boards_text = this.project.boards.map(function(board) {
        return board['slug'];
      });
      if (this.project.jwt && this.selectedBoard == 'All boards') {
        return this.codes = `<script>
  (function (w, d, s, o, f, js, fjs) { w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', 'FeedBear', 'https://sdk.feedbear.com/widget.js'));
  FeedBear("button", {
  element: document.querySelector("[data-feedbear-button]"),
  project: "`+ this.project.subdomain +`",
  boards: "`+ this.boards_text +`",
  jwt: null // see step 3,
  });
 <\/script>`;
      } else if (this.project.jwt && this.selectedBoard != 'All boards') {
        return this.codes = `<script>
  (function (w, d, s, o, f, js, fjs) { w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', 'FeedBear', 'https://sdk.feedbear.com/widget.js'));
  FeedBear("button", {
  element: document.querySelector("[data-feedbear-button]"),
  project: "` + this.project.subdomain + `",
  board: "` + this.selectedBoard + `",
  jwt: null // see step 3,
  });
<\/script>`;
      } else if (!this.project.jwt && this.selectedBoard != 'All boards'){
          return this.codes = `<script>
  (function (w, d, s, o, f, js, fjs) { w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', 'FeedBear', 'https://sdk.feedbear.com/widget.js'));
  FeedBear("button", {
  element: document.querySelector("[data-feedbear-button]"),
  project: "`+ this.project.subdomain +`",
  board: "`+ this.selectedBoard +`",
  ssoToken: null // see step 3,
  });
<\/script>`;
      } else if (!this.project.jwt && this.selectedBoard == 'All boards') {
        return this.codes = `<script>
  (function (w, d, s, o, f, js, fjs) { w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; js = d.createElement(s), fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs); }(window, document, 'script', 'FeedBear', 'https://sdk.feedbear.com/widget.js'));
  FeedBear("button", {
  element: document.querySelector("[data-feedbear-button]"),
  project: "`+ this.project.subdomain +`",
  boards: "`+ this.boards_text +`",
  ssoToken: null // see step 3,
  });
 <\/script>`;
      }
    }
  },

  components: {
    Dialog,
  },

  methods: {
    openBeacon() {
      Beacon("open");
    },
    highlighter(code) {
      return highlight(code, languages.js);
    },
    clipCopy () {
      navigator.clipboard.writeText(this.projectJwt ? this.projectJwt : this.projectToken);
    },
    regenerateFeedbearToken(){
      this.generateNewKeyPopup = false;
      var data = new FormData();
      data.append("project[token_type]", "jwt");
      Rails.ajax({
        url: this.projectUpdateUrl,
        type: "PATCH",
        data: data,
        dataType: "json",
        success: data => {
          this.projectToken = data.jwt;
          this.$notify({
            group: 'success',
            text: "New FeedBear key is generated successfully",
          });
          this.$emit("updateJwt", data.jwt);
        },
        error: (status) => {
          this.$notify({
            group: 'alert',
            text: data,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.beta {
  color: #f84339;
  font-size: 0.9rem;
  font-weight: 600;
  top: -0.8em;
}
.inp-width {
  width: 60%;
}
.childInput {
  width: 100%;
  outline: none;
}
.parentInput {
  width: 100% !important;
}
.codeblock {
  display: flex;
  white-space: pre-wrap;
  overflow-x: scroll;
  @apply bg-neutral-100;
}

code {
  @apply bg-neutral-100;
}

.codeblock code {
  @apply text-neutral-800;
  width: 100%;
}

.form__field--select {
  max-width: 400px;
}

.select_arrow {
  bottom: 1.2rem;
  top: auto;
}
</style>
