<template>
  <div>
    <span class="text-neutral-800 font-bold text-xl">Embeds</span>
    <p class="mt-2">You can display your boards, roadmap and updates directly on your website using embeds.</p><br />
    <h2 class="font-semibold text-neutral-900">How does it work?</h2>
    <p class="mt-2">Before you proceed keep in mind that you will need to use a bit of HTML and CSS, if you are not familiar with
      these, we’d recommend finding a web developer to better assist you with the code implementation.</p><br />
    <p>Find the code that corresponds to the page you want to incorporate into your website below, copy it and then
      paste it into your website, in the area you want the page to be displayed.</p>

    <h2 class="mt-6 font-semibold text-neutral-900">Board embed</h2>
  
    <div class="relative" id="select-div" style="width: 90%"> 
      <select
        id="cars"
        class="input select"
        style="height: 45px; width: 90%;"
        v-model="selectedBoard"
        ref="boardSelect"
      >
      <option v-for="board in project.boards"> {{board.name}}</option>
      </select>
      <svg
        class="w-5 h-5 absolute right-0 top-0 mt-3 pointer-events-none"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        style="margin-right: 13%"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </div>

    <div class="flex flex-wrap mt-2">
      <div class="w-full">
        <div class="">
          <div class="pb-5 pt-2 flex-auto">
            <div class=" sso-webhook-style">
              <div>
                <prism-editor class="prism-code-editor rounded" v-model="code1" :line-numbers="lineNumbers" :highlight="highlighter" readonly></prism-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="mt-3 font-semibold text-neutral-900">Roadmap embed</h2>
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="">
          <div class="pb-5 pt-2 flex-auto">
            <div class=" sso-webhook-style">
              <div>
                <prism-editor class="prism-code-editor rounded" v-model="code2" :line-numbers="lineNumbers" :highlight="highlighter" readonly></prism-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2 class="mt-3 font-semibold text-neutral-900">Updates embed</h2>
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="">
          <div class="pb-5 pt-2 flex-auto">
            <div class=" sso-webhook-style">
              <div>
                <prism-editor class="prism-code-editor rounded" v-model="code3" :line-numbers="lineNumbers" :highlight="highlighter" readonly></prism-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <h2 class="font-semibold text-neutral-900">How do I customize the embeds?</h2>
    <p class="mt-2">All embeds include an “embed” class in the<code>&#x3C;body&#x3E;</code>tag, allowing you to easily apply Custom CSS to the content of
      the embeds when they are displayed on your website.</p><br />
    <p>Changing the background color of your Roadmap page can be achieved by adding the following piece of code to your
      Project’s Custom CSS area:</p>
    <div class="flex flex-wrap">
      <div class="w-full">
        <div class="">
          <div class="pb-5 pt-2 flex-auto">
            <div class=" sso-webhook-style">
              <div>
                <prism-editor class="prism-code-editor rounded" v-model="code4" :line-numbers="lineNumbers" :highlight="highlighter" readonly></prism-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'highlight.js/styles/github.css';
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
export default {
  props: ["project","projectDomain"],
  data() {
    return {
      code: '',
      lineNumbers: true,
      selectedBoard: this.project.boards[0].slug,
      url: this.projectDomain + "/boards/"  ,
    }
  },
  mounted() {
    this.$refs.boardSelect.selectedIndex = 0;
  },
  computed: {
    code1() {
      return this.code = `<iframe src="https://${this.url + this.selectedBoard}?embed=true" width="100%" height="300"></iframe>`
    },
    code2() {
      return this.code = `<iframe src="https://feedback.feedbear.com/roadmap?embed=true" width="100%" height="300"></iframe>`
    },
    code3() {
      return this.code = `<iframe src="https://feedback.feedbear.com/updates?embed=true" width="100%" height="300"></iframe>`
    },
    code4() {
      return this.code = `body.embed {
  background-color: grey !important;
}`;
    },
  },
  methods:{
    highlighter(code) {
      return highlight(code, languages.js);
    },
  }
};
</script>

<style scoped lang="scss">
// select {
//   height: 40px;
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   border-radius: 6px;
//   border: 1px solid #DDDDDD;
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 120%;
//   color: #666666;
//   flex: none;
//   order: 0;
//   flex-grow: 1;
//   background: linear-gradient(180deg, #FFFFFF 0%, #EFEFEF 100%);
//   margin-top: 20px;
// }

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  color: #666666;
}
</style>