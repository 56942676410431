<template>
  <span class="mr-1 text-sm" v-if="is_shown">
    <a
      class="text-neutral-800 hover:no-underline hover:text-project-500"
      :href="this.url"
      >{{ $t("Edit post") }}</a
    >
    <span class="text-neutral-700 tabular-nums">
      (<countdown
        :original-date="this.datefrom"
        @countdone="is_shown = false"
      ></countdown
      >)</span
    >
  </span>
</template>

<script>
import Countdown from "../countdown.vue";

export default {
  props: ["url", "datefrom"],

  data: function() {
    return {
      is_shown: true
    };
  },

  components: {
    countdown: Countdown
  }
};
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>
