<template>
  <div>
    <div class="flex flex-row">
      <div class="mr-2">
        <input
          v-model="searchField"
          type="text"
          class="input min-w-0 rounded px-4"
          placeholder="Search users"
          @keydown="
            (e) => {
              if (e.key === 'Enter') search();
            }
          "
        />
      </div>
      <button @click="search" class="button button--primary px-5 rounded">
        <span class="hidden md:block">Search</span>
      </button>
    </div>
    <transition name="fade">
      <div class="mt-10" v-if="searchResults.length > 0">
        <p class="font-semibold text-xl leading-6 text-neutral-800 mb-2">
          Search results
        </p>
        <table class="table-auto w-full mt-4">
          <thead>
            <tr>
              <th
                v-for="col in columns"
                :key="col.id"
                class="px-4 py-2 text-left"
              >
                {{ col.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="customer in searchResults"
              :key="customer.id"
              class="hover:bg-neutral-100 transition-colors duration-150 ease-in-out"
            >
              <td
                v-for="col in columns"
                :key="col.id"
                class="border px-4 py-2"
                v-html="col.format(customer, col.prop)"
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
    </transition>
    <p v-if="showNoResultsMessage" class="text--center text--lighter my-3">
      No results found
    </p>
  </div>
</template>

<script>
export default {
  props: ["customers"],
  data: function () {
    return {
      columns: [
        {
          id: 1,
          prop: "name",
          label: "Name",
          format: this.nameFormatter,
        },
        {
          id: 2,
          prop: "upvotes_received",
          label: "Upvotes received",
          format: this.passThrough,
        },
        {
          id: 3,
          prop: "upvotes_given",
          label: "Upvotes given",
          format: this.passThrough,
        },
        {
          id: 4,
          prop: "comments_count",
          label: "Comments count",
          format: this.passThrough,
        },
      ],
      results: [],
      searchField: "",
      showNoResultsMessage: false,
    };
  },
  methods: {
    countryFormatter(row, column = null) {
      return row.country_flag + " " + row.country_name;
    },

    nameFormatter(row, column) {
      return `<a href="/users/${row.handle}">${row.name}</a>`;
    },

    passThrough(row, column) {
      return row[column];
    },
    search() {
      if (this.searchField.length > 0) {
        var query = new RegExp(this.searchField, "i");
        this.results = this.customers.filter(
          (c) => c.name.match(query) || c.email.match(query)
        );
        this.showNoResultsMessage = !!!this.results.length;
      }
    },
  },
  computed: {
    searchResults() {
      return this.results;
    },
  },
  watch: {
    searchField(val) {
      if (val.length == 0) {
        this.results = [];
        this.showNoResultsMessage = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
