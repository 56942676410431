<template>
  <div class="relative mt-2">
    <div
      class="bg-red-100 rounded border border-red-300 text-red-900 px-4 py-3 mt-4 mb-3"
      v-if="error"
    >
      <p>{{ error }}</p>
    </div>
    <div v-if="tempPost.trello_card_url">
      <a
        :href="this.tempPost.trello_card_url"
        class="btn-flat w-full mb-2"
        target="_blank"
        >
        <simple-svg
          :filepath="openIcon"
          :width="'16px'"
          :height="'16px'"
          class="mr-2"
        />Open card in Trello
      </a>
      <button class="btn-flat w-full flex" @click.prevent="detachCard">
        <simple-svg
          :filepath="trashIcon"
          :width="'16px'"
          :height="'16px'"
          class="mr-2"
        />Unlink card
        <svg v-if="showLoader" class="animate-spin h-8 w-5 mr-3 mx-auto" viewBox="0 0 26 26">
          <circle class="opacity-0" cx="12" cy="12" r="10" fill="#EA4F36" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </button>
    </div>
    <div v-else>
      <transition name="switch" mode="out-in" @after-enter="afterAnim">
        <div>
          <div v-if="inputVisible" class="items-center">
            <div class="relative max-w-md mb-2">
              <select
                id="integrations_trello_config_default_board_id"
                name="integrations_trello_config[default_board_id]"
                v-model="selectedBoardId"
                required="required"
                class="select"
                v-on:change="getBoardLists"
                :disabled="showFields"
              >
                <option v-for="option in boards" :key="option[0]" :value="option[1]">{{option[0]}}</option>
              </select>
              <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
            </div>
            <div class="relative max-w-md mb-2">
              <select
                id="integrations_trello_config_default_list_id"
                name="integrations_trello_config[default_list_id]"
                v-model="selectedListId"
                required="required"
                class="select"
                :disabled="showFields"
              >
                <option v-for="option in boardLists" :key="option[0]" :value="option[1]">{{option[0]}}</option>
              </select>
              <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
              </svg>
            </div>
            <div >
              <div class="relative flex-1">
                <input
                  class="input mb-2"
                  v-model="inputValue"
                  placeholder="Card title.."
                  @keyup.esc="handleInputCancel()"
                  @blur="handleBlur"
                  :disabled="showFields"
                  @keyup="hideError"
                />
                <textarea
                  class="input mb-2"
                  v-model="inputDesc"
                  placeholder="Card description.."
                  @keyup.esc="handleInputCancel()"
                  @blur="handleBlur"
                  :disabled="showFields"
                  @keyup="hideError"
                /></textarea>

              </div>
              <div>
                <a
                  v-if='showLoader'
                  href="#"
                  class="btn-flat h-4/5"
                >
                  <svg class="animate-spin h-8 w-5" viewBox="0 0 26 26">
                    <circle class="opacity-0" cx="12" cy="12" r="10" fill="#EA4F36" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </a>
                <a
                  v-else
                  href="#"
                  class="btn-flat"
                  @click.prevent="attachTrelloCard(inputValue, inputDesc)"
                >Create
                </a>
              </div>
            </div>
          </div>
          <div v-else>
            <button class="btn-flat w-full" @click.prevent="showInput">
              <simple-svg
                :filepath="addIcon"
                :width="'16px'"
                :height="'16px'"
                class="mr-2"
              />Create new card
            </button>
          </div>
        </div>
      </transition>

      <transition name="switch" mode="out-in" @after-enter="afterAnim">
        <div class="mt-2">
          <div v-if="LinkExistingInput" class="flex">
            <div class="relative mr-2 flex-1">
              <input
                class="input"
                v-model="existingCardURL"
                placeholder="Paste card URL here"
                @keyup.esc="handleInputCancel()"
                @blur="handleBlur"
                @keyup="hideError"
              />
            </div>
            <div>
              <a
                v-if='showLoader'
                href="#"
                class="btn-flat h-4/5"
              >
                <svg class="animate-spin h-8 w-5" viewBox="0 0 26 26">
                  <circle class="opacity-0" cx="12" cy="12" r="10" fill="#EA4F36" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </a>
              <a
                v-else
                href="#"
                class="btn-flat"
                @click.prevent="attachTrelloLink(existingCardURL)"
              >
                Link
              </a>
            </div>
          </div>
          <button v-else class="btn-flat w-full" @click.prevent="showLinkExistingInput">
            <simple-svg
              :filepath="linkIcon"
              :width="'16px'"
              :height="'16px'"
              class="mr-2"
            />Link to existing card
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import addIcon from "images/add.svg";
import openIcon from "images/resize.svg";
import trashIcon from "images/unlink.svg";
import linkIcon from "images/icons/link.svg";

export default {
  props: [
          "original-post",
          "detach-card-url",
          "create-card-url",
          "link-existing-card-url",
          "trello-config",
          "boards",
          "lists",
          "fetch-list-url"
          ],
  data: function() {
    return {
      inputVisible: false,
      LinkExistingInput: false,
      inputValue: this.originalPost.title,
      inputDesc: this.originalPost.content,
      boardLists: this.lists,
      selectedBoardId: this.trelloConfig.default_board_id,
      selectedListId: this.trelloConfig.default_list_id,
      existingCardURL: "",
      showLoader: false,
      showFields: false,
      tempPost: this.originalPost,
      error: "",
      addIcon,
      openIcon,
      trashIcon,
      linkIcon,
    };
  },
  methods: {
    afterAnim(el) {
      if (el.classList.contains("input")) {
        el.focus();
      }
    },

    hideError(){
      this.error = '';
    },

    showInput() {
      this.LinkExistingInput = false;
      this.inputVisible = true;
    },

    showLinkExistingInput() {
      this.inputVisible = false;
      this.LinkExistingInput = true;
    },

    handleInputCancel() {
      this.inputVisible = false;
      this.inputValue = "";
      this.inputDesc = "";
    },

    handleBlur(event) {
      if (this.inputValue || this.inputDesc || event.relatedTarget) {
      } else {
        this.inputVisible = false;
      }
    },

    detachCard() {
      var data = new FormData();
      // data.append("card_name", card_name);
      this.showLoader = true;
      Rails.ajax({
        url: this.detachCardUrl,
        type: "DELETE",
        data: data,
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          this.showLoader = false;
          this.tempPost = data.post;
          this.inputVisible = false;
          this.$notify({
            group: 'success',
            text: data.message,
          });
          console.log(data);
        },
        error: (data, status) => {
          this.showLoader = false;
          this.$notify({
            group: 'alert',
            text: data.message,
          });
          console.log(data);
        }
      });
    },

    attachTrelloCard(card_name, card_desc) {
      var data = new FormData();
      data.append("card_name", card_name);
      data.append("card_desc", card_desc);
      data.append("list_id", this.selectedListId);

      if (this.inputValue == '') {

        this.error = "Card title is empty. Please add a title for card.";

      }
      else if (this.inputDesc == '') {
        this.error = "Card description is empty. Please add a title for card.";
      }
      else {

        this.showLoader = true;
        this.showFields = true;
        Rails.ajax({
          url: this.createCardUrl,
          type: "POST",
          data: data,
          dataType: "json",
          beforeSend: function() {
            return true;
          },
          success: data => {
            this.showLoader = false;
            this.showFields = false;
            this.tempPost = data.post;
            this.$notify({
              group: 'success',
              text: data.message,
            });
            console.log(data);
          },
          error: (data, status) => {
            this.showLoader = false;
            this.showFields = false;
            this.$notify({
              group: 'alert',
              text: data.message,
            });
            console.log(data);
          }
        });
      }
    },

    attachTrelloLink(card_url) {
      var data = new FormData();
      data.append("card_url", card_url);

      if (this.existingCardURL == '') {

        this.error = "Card URL is empty. Please add URL for card.";

      } else {
        this.showLoader = true;
        Rails.ajax({
          url: this.linkExistingCardUrl,
          type: "POST",
          data: data,
          dataType: "json",
          beforeSend: function() {
            return true;
          },
          success: data => {
            this.tempPost = data.post;
            this.showLoader = false;
            this.LinkExistingInput = false;
            this.existingCardURL = '';
            this.$notify({
              group: 'success',
              text: data.message,
            });
            console.log(data);
          },
          error: (data, status) => {
            this.showLoader = false;
            this.existingCardURL = '';
            this.$notify({
              group: 'alert',
              text: data.message,
            });
            console.log(data);
          }
        });
      }
    },

    getBoardLists: function() {
      Rails.ajax({
        url: this.fetchListUrl + '?board_id=' + this.selectedBoardId,
        type: "GET",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.boardLists = data.lists;
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },

  },
};
</script>

<style scoped>
.switch-enter {
  opacity: 0;
}

.switch-leave-to {
  opacity: 0;
}

.switch-enter-to,
.switch-leave {
  opacity: 1;
}

.switch-enter-active,
.switch-leave-active {
  transition: opacity 150ms ease-out;
}

.show-enter,
.show-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.show-enter-to,
.show-leave {
  opacity: 1;
  transform: translateY(0);
}

.show-enter-active,
.show-leave-active {
  transition: opacity, transform 150ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
