<template></template>
<script>
export default {
  props: [],
  data: function() {
    return {};
  }
};
</script>
<style scoped>
</style>
