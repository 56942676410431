<template>
  <div class="mt-1">
    <input
      type="hidden"
      id="changelog_entry_published_at"
      name="changelog_entry[published_at]"
      :value="published_at"
    />

    <div class="mt-4 flex flex-col lg:flex-row lg:items-center justify-between gap-3" id="main-div">
      <div class="flex flex-col md:flex-row lg:items-center justify-between gap-3">
        <button type="submit" id="draft-btn" class="btn justify-center flex-1 lg:flex-auto" @click="published_at = null">
          {{ getAccordingToAlreadyPublised }}
        </button>
        <button id="draft-btn" class="btn-main justify-center flex-1 lg:flex-auto" @click="change_btn">
          <span>{{ getAccordingToButtonStatus }}</span>
        </button>
        <v-date-picker
          v-model="published_at"
          mode="dateTime"
          color="gray"
          is24hr
          :attributes="attrs"
        >
          <template #default="{ inputValue, togglePopover }">
            <div class="flex items-center bg-white border border-neutral-200 rounded-lg px-3 py-2 hover:bg-neutral-50 transition-colors">
              <a
                href="#"
                @click.prevent="openSchedule(togglePopover)"
                class="text-sm leading-tight text-neutral-800 hover:no-underline flex items-center gap-2"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <rect x="3" y="4" width="18" height="18" rx="2" ry="2" stroke-width="2"/>
                  <line x1="16" y1="2" x2="16" y2="6" stroke-width="2"/>
                  <line x1="8" y1="2" x2="8" y2="6" stroke-width="2"/>
                  <line x1="3" y1="10" x2="21" y2="10" stroke-width="2"/>
                </svg>
                <span>{{ isNow ? "Schedule for now" : formatDateTime(published_at) }}</span>
                <svg
                  class="w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </template>
        </v-date-picker>
      </div>

      <div class="flex flex-col md:flex-row lg:justify-end gap-3">
        <button id="draft-btn" class="btn justify-center flex-1 lg:flex-auto" @click.prevent="suggestChangelog()" v-if="this.changelogSuggestions == 'true' && this.subscription_valid == 'true'">
          <svg width="24" height="24" viewBox="0 0 544 512" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 mr-2">
            <path d="M234.7 42.7L192 64L234.7 85.3L256 128L277.3 85.3L320 64L277.3 42.7L256 0L234.7 42.7ZM384.4 192.4L351.6 159.6L432 79.2L464.8 112L384.4 192.4ZM96 32L64 96L0 128L64 160L96 224L128 160L192 128L128 96L96 32ZM416 352L352 384L416 416L448 480L480 416L544 384L480 352L448 288L416 352ZM144 512L183.6 472.4L504.4 151.6L544 112L504.4 72.4L471.6 39.6L432 0L392.4 39.6L71.6 360.4L32 400L71.6 439.6L104.4 472.4L144 512Z" fill="black"/>
          </svg>
          {{ 'Write using AI' }}
        </button>
      </div>
    </div>
    <ChangelogSuggestModal :show-suggestion-modal="this.showSuggestionModal && this.subscription_valid == 'true'" @close-modal="closeModal()"/>
  </div>
</template>

<script>
import EventBus from "../event_bus";
import ChangelogSuggestModal from "./changelog_suggest_modal";
export default {
  props: ["current-value", "created-at", "subscription_valid", "already-post", "show-ai-message", "changelog-suggestions"],
  data: function () {
    return {
      published_at: new Date(),
      isNow: this.currentValue === "",
      button_status: true,
      already_published: false,
      cta_word: "Publish",
      changelog_body: false,
      changelog_title: false,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      attrs: [
        {
          key: "today",
          highlight: {
            fillMode: "light",
          },
          dates: new Date(),
        },
      ],
      showSuggestionModal: false,
      posts: [],
    };
  },
  components: { ChangelogSuggestModal },
  computed: {
    getAccordingToButtonStatus(){
      if(this.alreadyPost == 'true'){
        return "Save"
      }
      return this.button_status ? "Publish": "Schedule"
    },
    getAccordingToAlreadyPublised(){
      return this.already_published ? "Unpublish" : "Save as draft"
    }
  },
  mounted() {

  },
  created() {
    if (this.currentValue) {
      this.published_at = new Date(this.currentValue.replace(/-/g,'/'));

      if (this.published_at < new Date()) {
        this.already_published = true;
        this.cta_word = "Save";
      }
    } else {
      this.published_at = new Date();
    }
    EventBus.$on("changelogTextAdded", (data) => {
        this.changelog_body = data.body
        this.changelog_title = data.title
    })
  },
  methods: {
    openSchedule(toggle) {
      this.isNow = false;
      toggle();
    },
    change_btn(e){
      this.already_published = true;
      if(!this.changelog_body || !this.changelog_title){
        e.preventDefault()
        EventBus.$emit("errorChangelog", { body: !this.changelog_body, title: !this.changelog_title });
      }
    },
    suggestChangelog(){
      //this.$emit("suggest-changelog", "Trigger Changelog Suggestion AI entry");
      this.showSuggestionModal = true;
    },
    closeModal(){
      this.showSuggestionModal = false;
    },
    formatDateTime(date) {
      const options = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short'
      };
      return new Date(date).toLocaleString('en-US', options);
    },
  },
  watch: {
    published_at: function() {
      if(this.published_at < new Date()) {
        this.button_status = true
        }
      else{
        this.button_status = false
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vc-time-container {
  align-items: flex-start !important;
  justify-content: center;
}

.vc-time {
  justify-content: center !important;
}

.vc-time-container > div:first-child {
  display: none;
}

.vc-date-time {
  margin-left: 0 !important;
}
</style>
