<template>
  <div class="max-w-md mx-auto">
    <h3 class="text-center pt-4 font-semibold text-2xl text-neutral-800">
      FeedBear {{ planName }}
    </h3>
    <p class="text-center text-neutral-800">
      Great choice! Please enter your payment info below.
    </p>

    <form :action="this.action" method="post" id="payment-form" @submit.prevent="handleStripeFormSubmit"
      ref="paymentForm" class="mt-6">
      <h3 class="text-neutral-800 font-semibold">
        <span>Billing information</span>
      </h3>

      <div class="mt-2">
        <label for="customer_name" class="label">Full name</label>
        <input type="text" name="customer[name]" id="customer_name" class="input" v-model="customer_name" required />
      </div>

      <div class="mt-2">
        <label for="customer_address" class="label">Street address</label>
        <input type="text" name="customer[address]" id="customer_address" class="input" v-model="customer_address_line1"
          required />
      </div>

      <div class="mt-2">
        <label for="customer_city" class="label">City</label>
        <input type="text" name="customer[city]" id="customer_city" class="input" v-model="customer_address_city"
          required />
      </div>

      <div class="mt-2 form__field--select--flat">
        <label for="customer_country" class="label">Country</label>
        <div class="relative">
          <select name="customer[country]" id="customer_country" @change="countryChange"
            v-model="customer_address_country" ref="countrySelector" class="select" required>
            <option disabled value>Please select one</option>
            <option v-for="country in this.countries" :key="country.alpha3Code" :value="country.name">
              {{ country.name }}
            </option>
          </select>
          <svg class="w-6 h-6 absolute top-0 right-0 mt-2 pointer-events-none mr-2 text-neutral-700" fill="currentColor"
            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </div>
      </div>

      <div class="mt-2">
        <label for="customer_postal_code" class="label">Postal code</label>
        <input type="text" name="customer[postal_code]" id="customer_postal_code" class="input"
          v-model="customer_address_postal_code" required />
      </div>

      <div class="mt-2" v-if="this.is_eu">
        <label for="customer_vat_id" class="label">VAT ID (optional)</label>
        <div class="relative">
          <div class="form__field__loader" v-if="this.is_validating">
            <simple-svg :filepath="Loader" :width="'32px'" :height="'32px'" />
          </div>
          <div class="form__field__status" v-if="this.vat_id_valid">
            <simple-svg :filepath="Check" :width="'16px'" :height="'16px'" fill="#37bf64" />
          </div>
          <input type="text" name="customer[vat_id]" id="customer_vat_id" class="input" v-model="vat_id"
            @blur="checkVAT()" />
        </div>
        <small class="text-red-500" v-if="this.vat_id_valid === false">This VAT ID is invalid</small>
      </div>

      <h3 class="text-neutral-800 font-semibold mt-4">
        <span>Card details</span>
      </h3>

      <div class="mt-2">
        <div class="input py-3" ref="card"></div>
        <small id="card-errors" class="text-red-500" ref="cardErrors"></small>
      </div>

      <div class="mt-4">
        <transition name="component-fade" mode="out-in">
          <button type="submit" class="btn-main w-full text-center justify-center" v-if="!is_processing_payment">
            Complete Payment
          </button>
          <div v-else>
            <simple-svg :filepath="Loader" :width="'32px'" :height="'32px'" />
            <p class="text-center text-neutral-800">
              Stand up and stretch while we process the payment.
            </p>
          </div>
        </transition>

        <p class="text-left mt-4 text-neutral-800">
          <strong>
            We'll charge your card ${{ planPrice }}
            <span v-if="is_eu">(VAT included)</span>
          </strong>
          now and each
          <span>{{ planDuration.slice(0, -2) }}</span> thereafter. You can cancel any time, no questions asked.
        </p>
      </div>
    </form>
  </div>
</template>
  
<script>
import Loader from "images/loader.svg";
import Check from "images/check.svg";
import countries_data from "countries.json"

let stripe = undefined,
  elements = undefined,
  card = undefined;

export default {
  props: [
    "public-key",
    "submit-url",
    "validate-vat-url",
    "plan-name",
    "plan-price",
    "plan-duration",
    "stripe-price-id",
  ],
  data: function () {
    return {
      vat_id: "", // SK1121036510
      vat_id_valid: null,
      is_validating: false,
      countries: [],
      is_eu: false,
      customer_name: "",
      customer_address_line1: "",
      customer_address_city: "",
      customer_address_country: "",
      customer_address_postal_code: "",
      is_processing_payment: false,
      selectedPlan: this.planName,
      Loader,
      Check,
    };
  },
  components: {
    Loader,
    Check,
  },
  watch: {
    planName: function (new_val, old_val) {
      this.selectedPlan = new_val;
    },
  },
  mounted: function () {
    var style = {
      base: {
        fontSize: "16px",
      },
    };

    stripe = Stripe(this.publicKey);
    elements = stripe.elements();
    card = elements.create("card", { style: style, hidePostalCode: true });

    card.mount(this.$refs.card);
    this.countries = this.loadCountries();
  },
  methods: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    handleStripeFormSubmit: function () {
      if (this.is_processing_payment) return;
      this.is_processing_payment = true;
      let self = this;
      if (typeof fbq !== 'undefined') {
        fbq("track", "InitiateCheckout");
      }
      if (this.checkformValidity()) {
        stripe.createToken(card).then(function (result) {
          if (result.error) {
            self.$refs.cardErrors.textContent = result.error.message;
            self.is_processing_payment = false;
            self.$forceUpdate();
          } else {
            // Send the token to your server.
            self.stripeTokenHandler(result.token);
          }
        });
      }
    },

    checkformValidity: function () {
      let self = this;

      if (self.$refs.paymentForm.checkValidity()) {
        if (self.vat_id.length === 0) {
          return true;
        } else {
          if (self.vat_id_valid) {
            return true;
          }
        }
      }

      return false;
    },

    stripeTokenHandler: function (token) {
      let self = this;
      // Insert the token ID into the form so it gets submitted to the server
      var form = this.$refs.paymentForm;
      var hiddenInput = document.createElement("input");

      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("name", "stripeToken");
      hiddenInput.setAttribute("value", token.id);
      form.appendChild(hiddenInput);

      let stripeData = new FormData(form);
      stripeData.append("customer[is_eu]", self.is_eu);
      stripeData.append("plan", self.selectedPlan);
      stripeData.append("stripe_price_id", self.stripePriceId);
      stripeData.append("plan_period", self.planDuration);

      Rails.ajax({
        url: this.submitUrl,
        type: "POST",
        data: stripeData,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          console.log(data);
          switch (data.status) {
            case "action":
              var paymentIntentSecret =
                data.subscription.latest_invoice.payment_intent.client_secret;

              stripe
                .handleCardPayment(paymentIntentSecret)
                .then(function (result) {
                  if (result.error) {
                    console.log("3DS error", result);
                    self.$refs.cardErrors.textContent = result.error.message;
                    self.$forceUpdate();
                  } else {
                    console.log("True success");
                  }
                });
              break;

            case "error":
              console.log(
                "Payment method declined. Need to collect again and then update."
              );
              self.is_processing_payment = false;
              self.$refs.cardErrors.textContent = data.message;
              break;

            case "ok":
              console.log("Charge successful, subscription started.");
              window.location.href = '/edit?view=billing'
              break;
            default:
              break;
          }
        },
        error: (data, status) => {
          self.is_processing_payment = false;
          console.log("Error: ", +data);
        },
      });
    },

    loadCountries: function () {
      let self = this;
      self.countries = countries_data
      return countries_data;
    },

    checkVAT: function () {
      let self = this;

      if (self.vat_id.length > 0) {
        self.is_validating = true;

        fetch(self.validateVatUrl + "?vat_id=" + self.vat_id)
          .then(function (response) {
            return response.json();
          })
          .then(function (resultJson) {
            self.is_validating = false;
            self.vat_id_valid = resultJson.valid;
          });
      } else {
        self.vat_id_valid = null;
      }
    },

    countryChange: function (e) {
      let self = this;
      let country = this.countries[e.target.selectedIndex - 1];
      self.is_eu = (country.name == 'United Kingdom');
    },
  },
};
</script>
  
<style scoped>
.form__field__loader {
  position: absolute;
  width: 32px;
  right: 0.5rem;
  top: 0.3rem;
}

.form__field__status {
  position: absolute;
  width: 16px;
  right: 1rem;
  top: 0.8rem;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
</style>
  