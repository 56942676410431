<template>
  <div class="post-actions">
    <MoveAction
      :original-post="this.originalPost"
      :move-url="this.moveUrl"
      :all-boards="JSON.parse(this.allBoards)"
    ></MoveAction>
    <MergeAction
      :original-post="this.originalPost"
      :merged-posts="this.mergedPosts"
      :search-url="this.searchUrl"
      :merge-url="this.mergeUrl"
    ></MergeAction>
  </div>
</template>

<script>
import MoveAction from "./move_action";
import MergeAction from "./merge_action";

export default {
  props: [
    "original-post",
    "merged-posts",
    "all-posts",
    "search-url",
    "merge-url",
    "move-url",
    "all-boards"
  ],
  data: function() {
    return {};
  },
  components: {
    MoveAction,
    MergeAction
  }
};
</script>

<style scoped></style>
