<template>
  <transition name="appear" appear>
    <div
      class="inline-flex items-center bg-yellow-100 border border-yellow-200 rounded-md px-3 py-1 text-neutral-800"
    >
      <slot></slot>
      <button
        class="ml-2 text-neutral-600 hover:bg-red-600 hover:bg-opacity-75 hover:text-white rounded-full transition duration-150 ease-in-out close"
        @click.prevent="$emit('close')"
      >
        <svg
          class="w-3 h-3"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style scoped>
.close {
  padding: 2px;
}

.appear-enter-active,
.appear-leave-active {
  transition: opacity 200ms cubic-bezier(0.23, 1, 0.32, 1);
}

.appear-enter {
  opacity: 0;
  transform: translateX(-10px);
}

.appear-leave-to {
  opacity: 0;
  transform: translateX(10px);
}

.appear-leave,
.appear-enter-to {
  opacity: 1;
  transform: translateX(0);
}
</style>
