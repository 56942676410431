<template>
  <section>
    <div
      class="
        custom-domain-alert
        flex
        items-center
        border border-solid
        box-border
        rounded-sm
        mb-3
      "
    >
      <div class="px-6 py-6">
        <svg
          width="28"
          height="24"
          viewBox="0 0 28 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 24H28L14 0L0 24ZM15.2727 20.2105H12.7273V17.6842H15.2727V20.2105ZM15.2727 15.1579H12.7273V10.1053H15.2727V15.1579Z"
            fill="#EAAA06"
          />
        </svg>
      </div>
      <div class="my-4">
        <p>
          <span class="text-md font-bold text-neutral-800 mr-1">{{title}}:</span>
          {{ message }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
  },
};
</script>

<style scoped>
.custom-domain-alert {
  border-left-width: 5px;
  border-left-color: #eaaa06;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
</style>