<template>
  <div class= "border border-gray-300 rounded-md">
    <div class="flex flex-col m-4">
      <div class="h-12 flex justify-between">
        <img :src="trelloIcon" alt="trello" class="w-9 h-9" />
        <div v-if="trelloConfig.oauth_token" class="flex flex-row">
          <a class="btn-main hover:bg-project-500 hover:text-white h-10 shadow-none text-sm border hover:border-project-500 rounded mr-2">
            Connected
          </a>
          <a class="btn-main iconColor bg-gray-200 hover:bg-project-500 text-gray-700 h-10 text-sm shadow-none hover:text-white border-gray-200 hover:border-project-500 rounded" @click="this.openModal">
            <svg class="w-5 h-5 iconColor" viewBox="0 0 15 16" fill="#999999" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.47357 16L5.17929 13.4228C5.08774 13.4085 4.92425 13.3298 4.68883 13.1866C4.45341 13.0434 4.18529 12.8644 3.88447 12.6497L1.72643 13.6376L0 10.3517L1.88338 8.78389C1.85722 8.66935 1.841 8.55109 1.83472 8.4291C1.82792 8.30769 1.82452 8.16823 1.82452 8.01074C1.82452 7.91051 1.83106 7.79225 1.84414 7.65595C1.85722 7.52021 1.8703 7.36644 1.88338 7.19463L0 5.64832L1.72643 2.38389L3.90409 3.39329C4.08719 3.23579 4.28652 3.08546 4.50206 2.94228C4.71813 2.79911 4.94387 2.68456 5.17929 2.59866L5.47357 0H8.92643L9.22071 2.57718C9.46921 2.67741 9.68841 2.79195 9.87832 2.92081C10.0677 3.04966 10.2605 3.20716 10.4567 3.39329L12.6736 2.38389L14.4 5.64832L12.4381 7.25906C12.4643 7.40224 12.4808 7.52737 12.4876 7.63447C12.4939 7.74214 12.497 7.86756 12.497 8.01074C12.497 8.1396 12.4939 8.26502 12.4876 8.38701C12.4808 8.50842 12.4643 8.64072 12.4381 8.78389L14.3804 10.3517L12.6343 13.6376L10.4567 12.6282C10.2866 12.7857 10.1035 12.9289 9.90736 13.0577C9.71117 13.1866 9.48229 13.3083 9.22071 13.4228L8.92643 16H5.47357ZM7.18038 10.502C7.80818 10.502 8.34128 10.2586 8.77968 9.77181C9.21757 9.28501 9.43651 8.69799 9.43651 8.01074C9.43651 7.32349 9.21757 6.73646 8.77968 6.24966C8.34128 5.76286 7.80818 5.51946 7.18038 5.51946C6.53951 5.51946 6.00013 5.76286 5.56225 6.24966C5.12384 6.73646 4.90463 7.32349 4.90463 8.01074C4.90463 8.69799 5.12384 9.28501 5.56225 9.77181C6.00013 10.2586 6.53951 10.502 7.18038 10.502Z"/>
            </svg>
          </a>
        </div>
        <div v-else>
          <a class="btn-main text-sm bg-gray-200 hover:bg-project-500 text-gray-700 shadow-none hover:text-white border-gray-200 hover:border-project-500 rounded" turbolink="false"
          :href="this.isDisabled ? '#' : trelloOauthPath"
          :target="this.isDisabled ? '' : '_blank'"
          >
            Connect
          </a>
        </div>
      </div>
      <div class="">
        <h4 class="font-bold mt-2 text-neutral-800 text-base">Trello</h4>
        <p class="text-sm mt-2 text-gray-500">Create cards in your boards when new ideas are posted </p>
      </div>
    </div>
    <Dialog title="Connect Trello" v-model="dialogVisible" @handleMessages="resetMessages()">
      <p class="alert alert--good -mt-2 mb-2 ml-8 mr-8" v-if="this.msg">{{this.msg}}</p>
      <div class="ml-8 mb-8 mr-8 flex items-center">
        <!-- <div v-if="trelloConfig.oauth_token"> -->
        <form @submit.prevent="saveform" class="mt-1 w-4/5">
          <input
            type="hidden"
            name="authenticity_token"
            :value="this.authToken"
          />
          <input type="hidden" name="_method" value="patch">
          <!-- for trello boards -->
          <label for="integrations_trello_config_Default Board" class="text-sm text-gray-500">Default board to which ideas will be sent to</label>
          <div class="relative mb-2 mt-2">
            <select
              id="integrations_trello_config_default_board_id"
              name="integrations_trello_config[default_board_id]"
              v-model="trelloConfig.default_board_id"
              required="required"
              class="select bg-dropdown h-12 mt-2 mb-4 text-sm"
              v-on:change="getBoardLists(trelloConfig.default_board_id)"
            >
              <option value="null" selected hidden>None selected</option>
              <option v-for="option in boards" :key="option[0]" :value="option[1]">{{option[0]}}</option>
            </select>
            <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
            </svg>
          </div>
          <!-- for status list of trello -->
          <label for="integrations_trello_config_Default List" class="text-sm text-gray-500">Default list</label>
          <div class="relative">
            <select
              id="integrations_trello_config_default_list_id"
              name="integrations_trello_config[default_list_id]"
              v-model="trelloConfig.default_list_id"
              required="required"
              class="select bg-dropdown h-12 mt-2 text-sm"
            >
              <option value="null" selected hidden>None selected</option>
              <option v-for="option in boardLists" :key="option[0]" :value="option[1]">{{option[0]}}</option>
            </select>
            <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path>
            </svg>
          </div>

          <div class="flex mt-4 mb-2">
            <div>
              <a
                :href="disconnectTrelloUrl"
                class="btn-main text-sm bg-gray-200 hover:bg-project-500 text-gray-600 shadow-none hover:text-white border-gray-200 hover:border-project-500 rounded"
                data-method="delete"
                data-confirm="Are you sure?"
              >
                Disconnect
              </a>
            </div>
            <button type="submit" name="commit" value="Save" class="btn-main text-sm rounded ml-2" >
              <svg v-if="isLoading" class="animate-spin h-15 w-5 mr-3 " viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" fill="#EA4F36" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Save
            </button>
          </div>
        </form>
          
        <!-- </div> -->
      </div>
    </Dialog>
  </div>
</template>

<script>
import trelloIcon from "images/trello.png";
import Dialog from "../integration_dialog";

export default {
  props: [
    "trelloConfig",
    "boards",
    "lists",
    "project",
    "trelloOauthPath",
    "authToken",
    "formUrl",
    "fetchListUrl",
    "disconnectTrelloUrl",
    "isDisabled"
  ],
  data: function () {
    return {
      trelloIcon,
      dialogVisible: false,
      boardLists: this.lists,
      msg: '',
      isLoading: false,
    }
  },
  components: {
    trelloIcon,
    Dialog,
  },
  methods: {
    openModal() {
        this.dialogVisible = true;
    },
    resetMessages(){
      this.msg= '';
    },
    getBoardLists: function(board_id) {
      Rails.ajax({
        url: this.fetchListUrl + '?board_id=' + board_id,
        type: "GET",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.boardLists = data.lists;
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    saveform: function(event) {
      this.isLoading= true;
      let data = new FormData(event.target);
      Rails.ajax({
        url: this.formUrl,
        type: "POST",
        dataType: "json",
        data: data,
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.msg = data.message;
          this.isLoading= false
        },
        error: () => {
        },
      });
    },
  },
};
</script>

<style scoped>
.bg-dropdown{
  background: linear-gradient(180deg, #FFFFFF 0%, #EFEFEF 100%);
}
.iconColor:hover svg{
    fill: white;
}
</style>
