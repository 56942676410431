<template>
  <div
    class="flex border-b last:border-b-0 border-neutral-200 hover:bg-neutral-50 transition duration-200 ease-in-out"
  >
    <a
      @click.prevent="castVote()"
      href="#"
      class="text-neutral-600 text-lg flex-shrink-0 border-r hover:no-underline transition duration-200 ease-in-out w-16 flex items-center justify-center py-4"
      :class="{
        'text-project-500': alreadyVoted,
      }"
    >
      <div class="flex flex-col items-center">
        <svg
          class="w-3 h-3 rotate-180 transition-transform duration-200 ease-in-out mb-1.5"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
          :class="{ '-translate-y-0.5': isAnimating }"
        >
          <path
            d="M15,23c-0.256,0-0.512-0.098-0.707-0.293l-10-10c-0.286-0.286-0.372-0.716-0.217-1.09C4.23,11.243,4.596,11,5,11h20 c0.404,0,0.77,0.243,0.924,0.617c0.155,0.374,0.069,0.804-0.217,1.09l-10,10C15.512,22.902,15.256,23,15,23z"
          ></path>
        </svg>
        <div
          id="post-upvotes"
          class="font-bold leading-none text-base"
          v-if="!allowVoteCount || currentProjectAdmin"
        >
          {{ upvotes }}
        </div>
      </div>
    </a>

    <div
      class="flex-1 flex flex-col items-start justify-start p-4 cursor-pointer"
      id="board-post-data"
      @click.prevent="toIdeaPage"
    >
      <span v-if="post.status.name !== 'Not set'" class="mb-2">
        <post-status
          :status="post.status.name"
          :color="post.status.color"
          :status-name="statusName"
        ></post-status>
      </span>
      <h3 class="font-semibold" v-line-clamp="lines">
        <a
          @click.prevent="toIdeaPage"
          :href="post.url"
          class="text-neutral-900 hover:no-underline transition duration-200 ease-in-out force-break-words"
          id="post-title"
          >{{ post.title }}</a
        >
      </h3>
      <p
        class="text-neutral-700 text-sm mt-1"
        style="word-break: normal;"
        v-if="post.content"
        v-line-clamp="lines"
      >
        {{ content() }}
      </p>
      <div
        class="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-3 mt-4 w-full text-neutral-700"
      >
        <div class="flex flex-wrap items-center gap-3">
          <div class="flex items-center gap-1.5 text-xs">
            <template v-if="post.user">
              <avatar
                class="avatar border-1 border-white cursor-pointer"
                :size="20"
                :src="post.user ? post.user.avatar_url : null"
                :username="post.user ? post.user.name : ''"
              ></avatar>
              <span>{{ post.user.name }}</span>
            </template>
            <template v-else>
              <span>Anonymous</span>
            </template>
          </div>

          <div
            class="flex items-center gap-1.5 text-xs"
            id="board-post-comment"
          >
            <span class="mr-2" id="post-board">
              {{ post.board.name }}
            </span>
            <span class="flex items-center">
              <simple-svg
                :filepath="commentsIcon"
                width="14px"
                height="14px"
                class="mr-1"
              />
              {{ commentsCountHuman() }}
            </span>
          </div>
        </div>
        <div class="flex flex-wrap items-center gap-2">
          <span v-if="postTags" class="inline-flex items-center text-xs text-neutral-700 bg-neutral-100 rounded-full px-2 py-1">
            <simple-svg
              :filepath="tagsIcon"
              width="14px"
              height="14px"
              class="mx-1"
            />
            {{ postTags }}
          </span>
          <span
            v-if="currentProjectAdmin && post.is_private"
            class="inline-flex items-center gap-1 text-xs text-neutral-700 rounded-full px-2 py-1 bg-neutral-100"
          >
            <simple-svg :filepath="eyeSlashIcon" width="12px" height="12px" />
            {{ $t("Private") }}
          </span>
          <span
            v-if="currentProjectAdmin && !post.is_private && !post.is_spam"
            class="inline-flex items-center gap-1 text-xs text-neutral-700 rounded-full px-2 py-1 bg-neutral-100"
          >
            <simple-svg :filepath="eyeIcon" width="12px" height="12px" />
            {{ $t("Public") }}
          </span>
          <span
            v-if="post.is_spam && currentProjectAdmin"
            class="inline-flex items-center text-xs gap-1 text-red-700 rounded-full px-2 py-1 bg-red-100"
          >
            <simple-svg :filepath="warningIcon" width="12px" height="12px" />
            {{ $t("Marked as spam") }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import upvoteIcon from "images/upvote_chevron.svg";
import tagsIcon from "images/tags.svg";
import commentsIcon from "images/comments.svg";
import eyeSlashIcon from "images/eye-slash.svg";
import warningIcon from "images/warning.svg";
import EventBus from "event_bus";
import Reactions from "./reactions.vue";

export default {
  props: [
    "original-upvotes",
    "current-user",
    "original-post",
    "current-project-admin",
    "show-board",
    "allow-anonymous",
    "allow-vote-count",
    "status-name",
    "is-board",
  ],
  data: function () {
    return {
      post: this.originalPost,
      upvotes: this.originalPost.total_upvotes,
      lines: 5,
      upvoteIcon,
      tagsIcon,
      commentsIcon,
      eyeSlashIcon,
      warningIcon,
      ip_address: "",
      isVoting: false,
      isAnimating: false,
    };
  },
  components: {
    upvoteIcon,
    tagsIcon,
    commentsIcon,
    eyeSlashIcon,
    warningIcon,
    Reactions,
  },
  methods: {
    commentsCountHuman: function () {
      var message = "";

      if (this.post.comments_count === 0) {
        message = "0";
      } else if (this.post.comments_count === 1) {
        message = "1";
      } else {
        message = this.post.comments_count;
      }

      return message;
    },
    content() {
      var text = this.post.content;
      text = this.post.content.replace(/[\u0022\u201C\u201D]/g, "");
      if (text.length < 2) {
        text = null;
      }
      return text;
    },
    castVote: async function () {
      if (this.isVoting) return;

      // Handle unauthorized users
      if (!this.allowAnonymous && !this.currentUser) {
        EventBus.$emit("openRegModalSignup");
        return;
      }

      // Check for anonymous user cooldown
      if (!this.currentUser) {
        const lastVoteTime = localStorage.getItem('lastVoteTime_' + this.post.id);
        const currentTime = new Date().getTime();
        if (lastVoteTime && currentTime - lastVoteTime < 1000) {
          return;
        }
      }

      this.isVoting = true;

      // Add animation logic
      if (!this.alreadyVoted) {
        this.isAnimating = true;
        setTimeout(() => {
          this.isAnimating = false;
        }, 300);
      }

      // Optimistically update UI
      const previousVoteState = this.post.current_user_voted_for;
      const voteChange = previousVoteState ? -1 : 1;
      this.post.current_user_voted_for = !previousVoteState;
      this.upvotes += voteChange;

      try {
        // Get IP address if needed
        if (!this.ip_address) {
          const ipResponse = await fetch("https://api.ipify.org?format=json");
          const { ip } = await ipResponse.json();
          this.ip_address = ip;
        }

        const vote_post_url = "/boards/" + this.post.board_id + "/posts/" + this.post.id + "/vote";
        const data = new FormData();
        data.append("ip_address", this.ip_address);

        const response = await new Promise((resolve, reject) => {
          Rails.ajax({
            url: vote_post_url,
            type: "POST",
            data: data,
            dataType: "json",
            success: resolve,
            error: reject,
          });
        });

        if (response.status === "unprocessable_entity") {
          // Revert optimistic updates on failure
          this.post.current_user_voted_for = previousVoteState;
          this.upvotes -= voteChange;
          return;
        }

        if (typeof response.votes !== 'undefined') {
          EventBus.$emit("postVotes", {
            id: this.post.id,
            total: response.votes,
          });
          this.upvotes = response.votes;

          if (!this.currentUser) {
            localStorage.setItem('lastVoteTime_' + this.post.id, new Date().getTime());
          }
        }
      } catch (error) {
        // Revert optimistic updates on error
        this.post.current_user_voted_for = previousVoteState;
        this.upvotes -= voteChange;
        console.error('Error voting:', error);
      } finally {
        this.isVoting = false;
      }
    },
    toIdeaPage() {
      window.location.href = this.post.url;
    },
  },

  computed: {
    postTags: function () {
      return this.post.tags.map((tag) => tag.name).join(", ");
    },
    alreadyVoted() {
      return this.post.current_user_voted_for;
    },
  },
};
</script>

<style scoped></style>
