<template>
  <section>
    <h3 class="text-xl font-bold text-neutral-800 relative mr-2 mb-2 flex-1">
      Overview
    </h3>
    <div class="current-plan-box flex flex-col lg:items-start">
      <div class="plan-title font-bold mb-1">CURRENT PLAN</div>
      <div class="plan-name font-bold">Business Plan Lifetime License</div>
      <div :class="{'mt-5': true, 'mb-5': showRedeemField }">
        <p class="font-bold text-sm leading-4 mb-2 color-979797">
          Projects:<span class="color-979797 ml-1 font-normal">{{
            unlimitedPlan.projects
          }}</span>
        </p>
        <p class="font-bold text-sm leading-4 mb-2 color-979797">
          Whitelabel:<span class="color-979797 ml-1 font-normal">{{
            unlimitedPlan.whitelabel
          }}</span>
        </p>
        <p class="font-bold text-sm leading-4 mb-2 color-979797">
          Codes:<span class="color-979797 ml-1 font-normal">{{
            unlimitedPlan.codes
          }}</span>
        </p>
        <p class="font-bold text-sm leading-4 color-979797">
          Codes Redeemed:<span class="color-979797 ml-1 font-normal">{{
            showCodes
          }}</span>
        </p>
      </div>
      <div v-if="showRedeemField">
        <p class="font-normal text-sm leading-6 color-979797 mb-1">
          Redeem new codes
        </p>
        <div class="flex lg:flex-row sm:flex-col">
          <input
            type="text"
            class="
              input
              box-border
              bg-white
              rounded
              border-solid border border-gray-300
              w-60
              h-10
            "
            v-model="code"
            name="code"
          />
          <button
            @click="redeemCode()"
            class="
              lg:ml-2
              w-24
              h-10
              rounded
              btn-main
              font-bold
              text-sm
              leading-6
              flex
              justify-center
            "
          >
            Redeem
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "unlimited-plan",
  props: {
    unlimitedPlan: Object,
  },
  data() {
    return {
      code: "",
    };
  },
  computed: {
    showCodes() {
      return this.unlimitedPlan.codes_redeemed.join(", ");
    },
    showRedeemField(){
      return this.unlimitedPlan.codes < 3
    }
  },
  methods: {
    redeemCode() {
      let data = new FormData();
      data.append("code", this.code);
      Rails.ajax({
        url: "/redeem_code",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {},
        error: (data, status) => {
          this.error = data.message;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.current-plan-box {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px;
  .plan-title {
    color: #b0b0b0;
    font-size: 14px;
    line-height: 19px;
  }
  .plan-name {
    font-size: 24px;
    line-height: 28px;
    color: #525252;
  }
  .plan-name::first-letter {
    text-transform: capitalize;
  }
  .color-979797 {
    color: #979797;
  }
}
</style>
