<template>
  <div class="">

    <Dialog title="Set new password" v-model="password_forgot_dialog_visible">
      <div class="px-4 py-4">
        <transition name="popdown">
          <form
            id="forgot_password"
            action="/users/password"
            @submit.prevent="forgotPassword"
            accept-charset="UTF-8"
            method="post"
            class="form"
            v-if="this.password"
            ref="forgotpasswordform"
          >
            <div class="mt-4">
              <label for="user_email" class="label">New password</label>
              <input
                autocomplete="password"
                required="required"
                type="password"
                value=""
                name="user[password]"
                id="forgot_password"
                class="input"
              />
            </div>
            <input
              type="hidden"
              name="user[email]"
              :value=this.userEmail
            />
            <div class="mt-4 flex justify-center">
              <input
                type="submit"
                name="commit"
                value="Set password"
                class="btn-main"
              />
            </div>
          </form>
        </transition>

      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "./dialog";
import EventBus from "../event_bus";

export default {
  props: [
    "button-class",
    "time-now",
    "email",
  ],
  data() {
    return {
      password: true,
      login_error_msg: "",
      password_forgot_dialog_visible: true,
      userEmail: '',
    };
  },
  mounted() {
    this.userEmail = this.email ? this.email : ''
  },
  components: {
    Dialog,
  },

  methods: {
    forgotPassword(){
      var data = new FormData(this.$refs.forgotpasswordform);
      window.location.pathname
      if (window.location.search.includes("view_popup")){
         window.location.search = ""
      }
      Rails.ajax({
        url: "/users/forgot/password",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.password = false;
        },
        error: (data, status) => {
          console.log(data);
          this.login_error_msg = data.error;
        },
      });
    },
  },
  watch: {
    password_forgot_dialog_visible () {
      if (window.location.search.includes("view_popup")){
        window.location.search = ""
      }
    }
  }
};
</script>

<style scoped>
.btn-soc {
  @apply p-2 shadow-md rounded-md border border-neutral-100 transition duration-150 ease-in-out;
}

.btn-soc:hover {
  @apply shadow-lg;
}
</style>
