<template>
  <span>{{ minutes | two_digits }}:{{ seconds | two_digits }}</span>
</template>

<script>
export default {
  created: function() {
    window.setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },

  props: ["original-date"],

  data: function() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      date: Math.trunc(Date.parse(this.originalDate) / 1000)
    };
  },

  computed: {
    seconds: function() {
      return (this.date - this.now) % 60;
    },
    minutes: function() {
      return Math.trunc((this.date - this.now) / 60) % 60;
    },
    hours: function() {
      return Math.trunc((this.date - this.now) / 60 / 60) % 24;
    },
    days: function() {
      return Math.trunc((this.date - this.now) / 60 / 60 / 24);
    }
  },

  watch: {
    now: function(oldVal, newVal) {
      if (this.date - newVal < 1) {
        this.$emit("countdone");
      }
    }
  },

  filters: {
    two_digits: function(value) {
      if (value.toString().length <= 1) {
        return "0" + value.toString();
      }
      return value.toString();
    }
  }
};
</script>

<style scoped></style>
