<template>
  <div class="flex items-start mt-4 mx-8 mb-8">
    <div class="flex flex-col w-full">
      <p v-if="successMessage" class="alert alert--good mt-0">
        {{ successMessage }}
      </p>
      <p v-if="errorMessage" class="alert alert--bad mt-0">
        {{ errorMessage }}
      </p>

      <transition name="fade">
        <div
          v-if="!this.connectedIntegration"
        >
          <form @submit.prevent="connectToJira">
            <input
              v-model="jiraSiteURL"
              name="integrations_jira_integration[site_url]"
              type="text"
              class="input"
              placeholder="Jira project URL (e.g project.atlassian.net)"
            />

            <input
              v-model="jiraUsername"
              name="integrations_jira_integration[username]"
              type="email"
              class="input mt-4"
              placeholder="Jira user email"
            />

            <p class="text-neutral-700 text-sm mt-1">
              The user must have admin permissions to the project
            </p>

            <input
              v-model="jiraAPIToken"
              name="integrations_jira_integration[api_token]"
              type="text"
              class="input mt-4"
              placeholder="Jira API Token"
            />

            <p class="text-neutral-700 text-sm mt-1">
              <a
                class="underline"
                href="https://id.atlassian.com/manage-profile/security/api-tokens"
                target="_blank"
                >Click here</a
              >
              to generate your Jira API Token
            </p>

            <div class="mt-4 h-10">
              <button
                class="btn-main rounded"
                type="submit"
                :disabled="areFieldsEmpty"
              >
                <span :class="loaderState.connectingToJira && 'mr-3'"
                  >Connect to Jira</span
                >
                <loader
                  v-if="loaderState.connectingToJira"
                  border-color="#fff"
                />
              </button>
            </div>
          </form>
        </div>

        <div v-else>
          <form @submit.prevent="saveConfiguration">
            <p class="text-sm leading-5 text-gray-500 mb-2">
              Project
            </p>
            <div class="flex">
              <div class="relative lg:w-1/2 xl:w-1/2">
                <select
                  name="integrations_jira_integration[default_project]"
                  class="input select"
                  v-model="selectedProject"
                  @change="getIssueTypesofSelectedProject()"
                >
                  <option disabled value="" selected>Select project</option>
                  <option
                    v-for="option in connectedIntegration.projects_with_ids"
                    :key="option[0]"
                    :value="option[1]"
                    :selected="
                      option[1] == connectedIntegration.default_project
                    "
                  >
                    {{ option[0] }}
                  </option>
                </select>
                <svg
                  class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <loader
                class="ml-3"
                v-if="loaderState.loadingIssueTypes"
                border-color="#6b7280"
              />
            </div>

            <p class="text-sm leading-5 text-gray-500 mt-3 mb-2">
              Issue type
            </p>

            <div class="flex">
              <div class="relative lg:w-1/2 xl:w-1/2">
                <select
                  name="integrations_jira_integration[default_issue_type]"
                  class="input select"
                >
                  <option disabled value="" :selected="isDefault"
                    >Select issue type</option
                  >
                  <option
                    v-for="option in projectIssueTypes"
                    :key="option[0]"
                    :value="option[1]"
                    :selected="
                      option[1] === connectedIntegration.default_issue_type
                    "
                  >
                    {{ option[0] }}
                  </option>
                </select>
                <svg
                  class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <loader
                class="ml-3"
                v-if="loaderState.issuesLoader"
                border-color="#6b7280"
              />
            </div>

            <div class="flex mt-4 h-10">
              <button
                class="py-3 px-8 btn-main text-sm bg-gray-200 hover:bg-project-500 text-gray-600 shadow-none hover:text-white border-gray-200 hover:border-project-500 rounded"
                @click.prevent="disconnect()"
              >
                <span>Disconnect</span>
              </button>

              <button
                class="btn-main ml-2 text-sm w-1/6 flex justify-center rounded"
                type="submit"
              >
                <span :class="loaderState.savingConfig && 'mr-3'">Save</span>
                <loader v-if="loaderState.savingConfig" border-color="#fff" />
              </button>
            </div>
          </form>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import loader from "../spinner";
import axios from "axios";

export default {
  name: "jira-integration",
  props: ["resource-url", "project-id", "integration"],
  data() {
    return {
      connectedIntegration: this.integration,
      jiraSiteURL: "",
      jiraUsername: "",
      jiraAPIToken: "",
      selectedProject: "",
      selectedIssueType: "",
      successMessage: "",
      errorMessage: "",
      projectIssueTypes: [],
      loaderState: {
        connectingToJira: false,
        savingConfig: false,
        loadingIssueTypes: false,
        issuesLoader: false,
      },
    };
  },
  components: { loader },
  created() {
    this.initializeState();
  },
  mounted() {
    this.initializeState();
  },
  computed: {
    areFieldsEmpty() {
      return !this.jiraSiteURL || !this.jiraUsername || !this.jiraAPIToken;
    },
    isDefault() {
      return !this.projectIssueTypes
        ?.flat()
        .includes(this.connectedIntegration.default_issue_type);
    },
  },
  methods: {
    connectToJira(event) {
      this.resetMessages();
      this.loaderState.connectingToJira = true;
      let formData = new FormData(event.target);

      let projectUrl = formData.get("integrations_jira_integration[site_url]");
      if (this.urlDoesNotContainProtocol(projectUrl)) {
        formData.set(
          "integrations_jira_integration[site_url]",
          "https://" + projectUrl
        );
      }

      formData.append(
        "integrations_jira_integration[project_id]",
        this.projectId
      );

      Rails.ajax({
        url: this.resourceUrl,
        type: "POST",
        dataType: "json",
        data: formData,
        success: (data) => {
          this.loaderState.connectingToJira = false;
          this.successMessage = "Account connected successfully";
          this.connectedIntegration = data;
          this.setDefaultState();
          this.$emit("updateIntegration", this.connectedIntegration);
        },
        error: (response, status) => {
          console.log(response);
          this.loaderState.connectingToJira = false;
          this.errorMessage = response.error || status;
        },
      });
    },
    async getIssueTypesofSelectedProject() {
      this.loaderState.loadingIssueTypes = true;
      this.resetMessages();
      this.fetchIssueTypes()
        .then((data) => {
          this.projectIssueTypes = data.data.issue_types;
          this.loaderState.loadingIssueTypes = false;
        })
        .catch((error) => {
          console.log(error);
          this.loaderState.loadingIssueTypes = false;
          this.errorMessage = error.data.error || error;
          this.loaderState.loadingIssueTypes = false;
        });
    },
    saveConfiguration(event) {
      this.resetMessages();
      this.loaderState.savingConfig = true;
      let formData = new FormData(event.target);

      Rails.ajax({
        url: this.resourceUrl + `/${this.connectedIntegration.id}`,
        type: "PUT",
        dataType: "json",
        data: formData,
        success: (data) => {
          this.loaderState.savingConfig = false;
          this.successMessage = "Configuration saved successfully";
        },
        error: (response, status) => {
          console.log(response);
          this.loaderState.savingConfig = false;
          this.errorMessage = response.error || status;
        },
      });
    },
    disconnect() {
      this.resetMessages();
      Rails.ajax({
        url: this.resourceUrl + `/${this.connectedIntegration.id}`,
        type: "DELETE",
        success: (data) => {
          this.connectedIntegration = null;
          this.resetFields();
          this.$emit("updateIntegration", null);
        },
        error: (response, status) => {
          console.log(response);
          this.errorMessage = response.error || status;
        },
      });
    },
    fetchIssueTypes() {
      return axios({
        method: "GET",
        url:
          this.resourceUrl +
          `/${this.connectedIntegration.id}/issue_types?project=${this.selectedProject}`,
        headers: {
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]")
            .content,
        },
      });
    },
    resetMessages() {
      this.successMessage = "";
      this.errorMessage = "";
    },
    resetFields() {
      this.jiraSiteURL = "";
      this.jiraUsername = "";
      this.jiraAPIToken = "";
      this.selectedProject = "";
      this.selectedIssueType = "";
    },
    initializeState() {
      if (this.connectedIntegration?.default_project) {
        this.selectedProject = this.connectedIntegration.default_project;
        this.loaderState.issuesLoader = true;
        this.fetchIssueTypes()
          .then((data) => {
            this.projectIssueTypes = data.data.issue_types;
            this.loaderState.issuesLoader = false;
          })
          .catch((error) => {
            console.log(error);
            this.loaderState.issuesLoader = false;
          });
      }
    },
    urlDoesNotContainProtocol(url) {
      return !url.match(
        new RegExp(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
        )
      );
    },
    setDefaultState() {
      this.selectedProject = this.connectedIntegration.default_project;
      this.getIssueTypesofSelectedProject();
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
