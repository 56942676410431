<template>
  <div>
		<input type="text" v-if="currentProject.ic_api_key !== null" :value="currentProject.ic_api_key" class="input--key-disabled" disabled>
		<a href="#" v-else class="button" @click.prevent="generateKey">Generate</a>
    <a href="https://app.intercom.io/a/apps/_/appstore?app_package_code=feedbear-v2" class="add-to-intercom" v-if="currentProject.id === 11" target="_blank">Add FeedBear to your Intercom</a>
  </div>
</template>

<script>
export default {
  props: ["project", "url"],
  data: function() {
    return {
      currentProject: this.project
    };
  },
  components: {},
  methods: {
    generateKey: function() {
      Rails.ajax({
        url: this.url,
        type: "GET",
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          this.currentProject.ic_api_key = data;
        }
      });
    }
  }
};
</script>

<style scoped>
.add-to-intercom {
  display: inline-block;
  padding-top: 0.5rem;
}
</style>
