<template>
  <div class="">
    <!-- Dialog -->
    <ChangelogDialog title="Write Changelog entries with AI" v-model="this.showCompanyDetails">
      <form @submit.prevent="saveCompanyDetails()">
        <div class="flex flex-col justify-center m-8 -mt-1">
          <p class="text-gray-500">Describe what {{this.project.name}} is about. FeedBear will use this information to generate a changelog entry draft for you, which you can then edit and publish. You can always change this description under Project Settings.
            <div class="flex items-center flex-wrap mt-4 mb-2">
              <textarea
                id="company_details"
                type="text"
                name="project[company_details]"
                class="input"
                v-model="companyDetails"
                maxlength="500"
                :placeholder="`${this.project.name} is the solution for [target audience] who struggle with [main problem or pain point]. Our product provides [list of benefits] that help you [jobs to be done] so you can achieve [expected results].`"
                rows="7"
              />
            </div>
          <div class="flex justify-between">
            <a href="/edit?view=ai_settings" class="text-sm text-gray-600 mt-6">
              Edit AI Settings
            </a>
            <div class="flex flex-row">
              <a href="#" class="text-sm text-gray-600 mt-6" @click.prevent="disableChangelogSuggestions()">
                Skip
              </a>
              <button type="submit" class="mt-4 btn-main text-sm rounded ml-4">
                Save & Continue
              </button>
            </div>
          </div>
        </div>
      </form>
    </ChangelogDialog>
  </div>
</template>

<script>
import ChangelogDialog from "./changelog_dialog";

export default {
  props: ["post", "board", "project", 'show-company-details', 'token', 'companyDetails'],
  data() {
    return {
      changelogTitle: "Please wait",
      changelogBody: "Generating Changelog title and body with AI...",
      isLoading: false,
      selectedValue: this.selectedValue,
      companyDetails: this.project.company_details,
    };
  },
  components: {
    ChangelogDialog,
  },
  computed:{
  },
  mounted: {
  },
  methods: {
    saveCompanyDetails() {
      let data = new FormData();
      data.append("authenticity_token", this.token);
      data.append("project[company_details]", this.companyDetails);
      Rails.ajax({
        url: `/projects/${this.project.id}/update_company_details`,
        type: "PUT",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.$emit("company-details-updated", data);
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    disableChangelogSuggestions() {
      window.location.reload();
    },
  }
}
</script>
<style scoped>
.iconColor:hover svg{
  fill: white;
}
</style>
