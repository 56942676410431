/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/activestorage").start();

import i18n from "../i18n";
import Avatar from "vue-avatar";
import lineClamp from "vue-line-clamp";
import linkify from "vue-linkify";
import VueSimpleSVG from "vue-simple-svg";
import VueTippy, { TippyComponent } from "vue-tippy";
import TurbolinksAdapter from "vue-turbolinks";
import Vue from "vue/dist/vue.esm";
import BoardDeleteConfirmation from "../boards/delete_confirmation";
import BoardForm from "../boards/form";
import NewPost from "../boards/new_post";
import ImportPosts from "../boards/import_posts";
import PostSuggestions from "../boards/post_suggestions";
import ChangelogDot from "../changelog/badge";
import ChangelogDotUpdater from "../changelog/dot_updater";
import ChangelogEditor from "../changelog/editor";
import Labels from "../changelog/labels/form_part";
import DateTooltip from "../changelog/meta_date";
import ChangelogPicker from "../changelog/picker";
import ColorPicker from "../color-picker";
import Comments from "../comments.vue";
import CommentAiBanner from "../comment_ai_banner.vue";
import ChangelogAiBanner from "../changelog/changelog_ai_banner.vue";
import CustomersTable from "../customers/customers_table.vue";
import CustomersSearch from "../customers/customers_search.vue";
import CustomerPosts from "../customers/customer_posts.vue";
import IntercomPosts from "../intercom/posts";
import IntercomKey from "../intercom_key";
import Posts from "../posts.vue";
import PostEditBtn from "../posts/edit_button";
import MergedPosts from "../posts/merged_posts";
import PostActions from "../posts/post_actions";
import JiraActions from "../posts/jira_actions";
import TrelloCard from "../posts/trello_card";
import PostStatus from "../posts/post_status";
import PostTags from "../posts/post_tags";
import SearchBar from "../posts/search.vue";
import CancelSubscription from "../projects/cancel_subscription";
import Settings from "../projects/settings";
import DeleteProject from "../projects/settings/delete_project";
import TrelloIntegration from "../projects/settings/trello_integration";
import DeleteAccount from "../users/delete_account";
import RoadmapColumn from "../roadmap/column";
import RoadmapPost from "../roadmap/post";
import Roadmap from "../roadmap/roadmap";
import Upvote from "../upvote.vue";
import { linkifyOpts } from "../variables";
import Dropdown from "../components/dropdown";
import UserDropdown from "../components/user_dropdown";
import BoardsDropdown from "../boards/boards_dropdown.vue";
import BoardsMenuCollapse from "../boards/boards_menu_collapse.vue";
import ProjectsDropdown from "../components/projects_dropdown.vue";
import FileUpload from "../components/file_upload";
import "stylesheets/application";
import VCalendar from "v-calendar";
import AuthDialog from "../components/auth_dialog";
import EmailDialog from "../components/email_dialog";
import ViewForgotPopup from "../components/view_forgot_popup";
import BrowserProject from "../onboarding/browser_project";
import Vote from "../components/vote"
import AnonymousVote from "../components/anonymous_vote"
import BehalfVoteAdd from "../components/behalf_vote_add"
import ImageUploader from "../components/image_uploader";
import SearchFilter from "../components/search_filter";
import Notifications from 'vue-notification'
import CustomizeStatus from '../components/customize_status'
import CustomizeRoadMap from '../components/customize_roadmap'
import AvatarTippy from '../components/avatar_tippy'
import ToolTipTippy from '../components/tooltip_tippy'
import "tippy.js/themes/light.css";
import { PrismEditor } from 'vue-prism-editor';
import VueHighlightJS from 'vue-highlightjs';
import DialogModel from "../components/dialog_model";
import store from "../projects/store";

import ProjectNotFound from "../errors/project_not_found.vue";
import IdeaNotFound from "../errors/idea_not_found.vue";
import ServerError from "../errors/server_error.vue";
import PageNotFound from "../errors/page_not_found.vue";
import vClickOutside from 'v-click-outside'
import PostVoterVote from "../components/post_voter_vote"
import ExtendTrial from "../projects/extend_trial";
import CustomPlanSelect from "../projects/custom_plan_select";
import CustomCheckout from  "../projects/custom_plan_checkout";
import JiraIntegration from "../projects/settings/jira_integration";
import Post from "../post.vue";
import IntegrationPage from "../projects/integration_page.vue";
import ZapierIntegration from "../projects/settings/zapier_integration";
import IntercomIntegration from "../projects/settings/intercom_integration";
import SlackIntegration from "../projects/settings/slack_integration";
import CopyUrlTippy from "../components/copy_url_tippy";
import InternalNotes from "../internal_notes.vue";
import Reactions from "../reactions.vue";
import ChangelogAi from "../components/changelog_ai"
import ChangelogModal from "../components/changelog_modal"
import CompanyDetails from "../components/company_details"
import ChangelogDialog from "../components/changelog_dialog"
import ChangelogSuggestModal from "../changelog/changelog_suggest_modal";
import AiSettings from "../projects/settings/ai_settings";
import SpamSettings from "../projects/settings/spam_settings";
import EmojiPicker from "../components/emoji_picker";

Vue.use(vClickOutside)
Vue.use(VCalendar);
Vue.use(VueHighlightJS);
Vue.component("copy-url-tippy", CopyUrlTippy);

Vue.component("post",Post);
Vue.component("integration-page", IntegrationPage);
Vue.component("zapier-integration", ZapierIntegration);
Vue.component("intercom-integration", IntercomIntegration);
Vue.component("slack-integration", SlackIntegration);

Vue.component("integration-page", IntegrationPage);
Vue.component("zapier-integration", ZapierIntegration);
Vue.component("intercom-integration", IntercomIntegration);
Vue.component("slack-integration", SlackIntegration);

Vue.component("project-not-found", ProjectNotFound);
Vue.component("idea-not-found", IdeaNotFound);
Vue.component("server-error", ServerError);
Vue.component("page-not-found", PageNotFound);
Vue.component("custom-checkout", CustomCheckout);
Vue.component("custom-plan-select", CustomPlanSelect);
Vue.component("extend-trial", ExtendTrial);
Vue.component("intercom-posts", IntercomPosts);
Vue.use(Notifications);
Vue.use(VueTippy);
Vue.component("post-voter-vote" , PostVoterVote);
Vue.use(TurbolinksAdapter);
Vue.component('PrismEditor', PrismEditor);
Vue.use(VueSimpleSVG);
Vue.component("tippy", TippyComponent);
Vue.component("customize-status", CustomizeStatus);
Vue.component("customize-roadmap", CustomizeRoadMap);
Vue.component("avatar-tippy", AvatarTippy);
Vue.component("tooltip-tippy", ToolTipTippy);
Vue.component("dropdown", Dropdown);
Vue.component("user-dropdown", UserDropdown);
Vue.component("boards-dropdown", BoardsDropdown);
Vue.component("boards-menu-collapse", BoardsMenuCollapse);
Vue.component("projects-dropdown", ProjectsDropdown);
Vue.component("file-upload", FileUpload);
Vue.component("auth-dialog", AuthDialog);
Vue.component("email-dialog", EmailDialog);
Vue.component("view-forgot-popup", ViewForgotPopup);
Vue.component("vote", Vote);
Vue.component("anonymous-vote", AnonymousVote);
Vue.component("behalf-vote-add", BehalfVoteAdd);
Vue.component("dialog-model", DialogModel);

Vue.component("email-dialog", EmailDialog);
Vue.component("view-forgot-popup", ViewForgotPopup);
Vue.component("image-uploader", ImageUploader);
Vue.component("search-filter", SearchFilter);

Vue.component("post-edit-btn", PostEditBtn);
Vue.component("browser-project", BrowserProject);

Vue.component("comments", Comments);
Vue.component("comment-ai-banner", CommentAiBanner);
Vue.component("changelog-ai-banner", ChangelogAiBanner);
Vue.component("posts", Posts);
Vue.component("upvote", Upvote);
Vue.component("internal-notes", InternalNotes);
Vue.component("reactions", Reactions);
Vue.component("intercom-key", IntercomKey);
Vue.component("color-picker", ColorPicker);

Vue.component("avatar", Avatar);

Vue.component("customers-table", CustomersTable);
Vue.component("customers-search", CustomersSearch);
Vue.component("customer-posts", CustomerPosts);

Vue.component("roadmap", Roadmap);
Vue.component("roadmap-column", RoadmapColumn);
Vue.component("roadmap-post", RoadmapPost);

Vue.component("board-form", BoardForm);

Vue.component("new-post", NewPost);
Vue.component("import-posts", ImportPosts);
Vue.component("post-suggestions", PostSuggestions);
Vue.component("post-tags", PostTags);
Vue.component("post-status", PostStatus);

Vue.component("delete-board-dialog", BoardDeleteConfirmation);

Vue.component("post-actions", PostActions);
Vue.component("jira-actions", JiraActions)
Vue.component("merged-posts", MergedPosts);
Vue.component("trello-card", TrelloCard);

Vue.component("project-settings", Settings);
Vue.component("cancel-subscription", CancelSubscription);
Vue.component("trello-integration", TrelloIntegration);

Vue.component("delete-project", DeleteProject);
Vue.component("delete-account", DeleteAccount);

Vue.component("searchbar", SearchBar);

Vue.component("changelog-editor", ChangelogEditor);
Vue.component("labels-form", Labels);
Vue.component("changelog-picker", ChangelogPicker);
Vue.component("date-tooltip", DateTooltip);
Vue.component("changelog-dot", ChangelogDot);
Vue.component("changelog-dot-updater", ChangelogDotUpdater);
Vue.component("changelog-ai", ChangelogAi);
Vue.component("changelog-modal", ChangelogModal);
Vue.component("company-details", CompanyDetails);
Vue.component("changelog-dialog", ChangelogDialog);
Vue.component("changelog-suggest-modal", ChangelogSuggestModal);
Vue.component("ai-settings", AiSettings);
Vue.component("spam-settings", SpamSettings);
Vue.component("jira-integration", JiraIntegration);
Vue.component("emoji-picker", EmojiPicker);

Vue.directive("linkified", linkify);

document.addEventListener("turbolinks:load", () => {

  Vue.use(lineClamp, {
    importCss: true,
  });

  const app = new Vue({
    i18n,
    el: '[data-behavior="vue"]',
    store,
    delimiters: ['${', '}'],
    beforeCreate(){
      Rails.ajax({
        url: "/locale_settings",
        type: "GET",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          i18n.locale = data.current_locale;
        },
        error: (data, status) => {
          this.error = data;
        },
      });
    },
    created: function () {
      this.linkifyOpts = linkifyOpts;
    },
  });
});
