<template>
  <span
    v-if="this.status !== this.statusName.not_set"
    class="status text-xs font-medium rounded-md tracking-wide inline-block px-2 py-1"
    :style="{
      'color': color,
      'background-color': hexToRgba(color, 0.1)
    }"
    >{{ this.status }}</span
  >
</template>

<script>
export default {
  props: ["status", "color", "status-name"],
  data: function () {
    return {};
  },

  filters: {
    humanize: function (str) {
      if (str) {
        return str.replace(/_/g, " ");
      } else {
        return;
      }
    },
  },

  methods: {
    hexToRgba(hex, alpha = 1) {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
  },
};
</script>

<style scoped>

</style>
