<template>
  <section>
    <a
      class="whitespace-nowrap text-sm text-gray-500 sticky bg-white right-0 cursor-pointer"
      @click="dialogVisible = true"
    >
      Custom Plan
    </a>

    <Dialog title="Custom Corporate Plan" v-model="dialogVisible">
      <div class="px-4">
        <form method="post" :action="submitUrl">
          <label for="custom_plans" class="inline-block my-4"
            >Custom Plans
          </label>
          <select
            id="custom_plans"
            name="project[custom_plan_id]"
            class="input select"
          >
            <option selected disabled hidden value=""
              >Click here to select
            </option>
            <option
              v-for="option in planItems"
              :key="option[0]"
              :value="option[1]"
            >
              {{ `name: ${option[0]}, price: $ ${option[2]}` }}
            </option>
          </select>
          <div class="flex my-1">
            <label for="paid-checkbox" class="inline-block my-4"
              >Mark as paid
            </label>
            <input
              v-model="markAsPaid"
              type="checkbox"
              name="mark_as_paid"
              id="paid-checkbox"
              class="cursor-pointer ml-3"
            />
          </div>
          <div class="flex flex-col mb-1" v-if="markAsPaid">
            <label for="subscription-valid-time" class="inline-block mb-4"
              >Enter the date for subscription end</label
            >
            <input
              id="subscription-valid-time"
              class="input min-w-0 lg:text-lg rounded-lg px-4"
              name="project[sub_valid_until]"
              type="date"
              required
            />
          </div>
          <div class="flex w-full">
            <input
              type="submit"
              class="button button--primary px-5 rounded-lg my-3 mx-auto"
              value="Save settings"
            />
          </div>
        </form>
      </div>
    </Dialog>
  </section>
</template>

<script>
import Dialog from "../components/dialog";

export default {
  props: ["custom-plans", "submit-url", "project-id"],
  data() {
    return {
      dialogVisible: false,
      custom_plan_id: "",
      markAsPaid: false,
    };
  },
  components: {
    Dialog,
  },
  computed: {
    planItems() {
      return JSON.parse(this.customPlans).map((plan) => [
        plan.name,
        plan.id,
        plan.price_amount,
      ]);
    },
  },
};
</script>

<style scoped></style>
