<template>
  <div>
    <div class="flex justify-between mb-3">
      <h4 class="text-neutral-800 font-semibold">Jira</h4>
      <img
        v-if="expandJiraActions"
        :src="crossIconUrl"
        alt="cross-icon"
        id="cross-icon"
        width="20"
        height="20"
        @click.prevent="expandJiraActions = false"
      />
    </div>

    <p v-if="errorMessage" class="alert alert--bad mt-0">
      {{ errorMessage }}
    </p>

    <transition name="fade" appear mode="out-in">
      <form @submit.prevent="createJiraIssue" v-if="expandJiraActions">
        <div class="flex mb-2">
          <div class="relative flex-1">
            <select
              v-model="selectedProject"
              name="jira_issue[project_id]"
              class="select"
              @change="getIssueTypesOfProject()"
            >
              <option value="" disabled>Project name</option>
              <option
                v-for="option in integration.projects_with_ids"
                :key="option[1]"
                :value="option[1]"
                :selected="option[1] == integration.default_project"
                >{{ option[0] }}</option
              >
            </select>
            <svg
              class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>

          <svg
            v-if="loadingIssueTypes"
            class="animate-spin h-8 w-5 ml-3"
            viewBox="0 0 26 26"
          >
            <circle
              class="opacity-0"
              cx="12"
              cy="12"
              r="10"
              fill="#EA4F36"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>

        <div class="relative flex-1 mb-2">
          <select
            v-model="selectedIssueType"
            name="jira_issue[issue_type_id]"
            class="select"
          >
            <option value="" disabled selected>Issue type</option>
            <option
              v-for="option in projectIssueTypes"
              :key="option[1]"
              :value="option[1]"
              :selected="option[1] == integration.default_issue_type"
              >{{ option[0] }}</option
            >
          </select>
          <svg
            class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>

        <input
          class="input mb-2"
          type="text"
          name="jira_issue[summary]"
          v-model="ideaTitle"
        />

        <textarea
          class="input mb-2"
          name="jira_issue[description]"
          rows="3"
          v-model="ideaDescription"
        ></textarea>

        <button
          class="btn-flat w-full"
          type="submit"
          :disabled="areFieldsEmpty"
        >
          <span>
            Send to Jira
          </span>
          <svg
            v-if="isCreating"
            class="animate-spin h-8 w-5 ml-3"
            viewBox="0 0 26 26"
          >
            <circle
              class="opacity-0"
              cx="12"
              cy="12"
              r="10"
              fill="#EA4F36"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </button>
      </form>
    </transition>
    <button
      v-if="!expandJiraActions"
      class="btn-flat w-full flex items-center"
      type="button"
      @click.prevent="expandJiraActions = true"
    >
      <img :src="sendButtonIcon" alt="send-button" width="12" height="16" />
      <span class="ml-3">
        Send to Jira
      </span>
    </button>
    <div class="mt-4 flex flex-col gap-2 max-w-32 overflow-y-auto">
      <a
        class="font-normal text-xs leading-4 text-neutral-800"
        v-for="issue in jiraIssues"
        :key="issue.id"
        :href="issue.link"
        target="_blank"
      >
        Sent to <b>{{ issue.project_name }}</b> ({{ issue.issue_type_name }}) on
        {{ format(new Date(issue.created_at), "dd/MM/yy") }}
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { format } from "date-fns";

export default {
  props: [
    "cross-icon-url",
    "jira-integration",
    "idea",
    "resource-url",
    "issue-types-url",
    "send-button-icon",
    "issues",
  ],
  data: function () {
    return {
      selectedProject: "",
      selectedIssueType: "",
      projectIssueTypes: [],
      ideaTitle: "",
      ideaDescription: "",
      feedbearIdea: JSON.parse(this.idea),
      integration: JSON.parse(this.jiraIntegration),
      expandJiraActions: false,
      isCreating: false,
      loadingIssueTypes: false,
      jiraIssues: JSON.parse(this.issues),
      errorMessage: "",
      format,
    };
  },
  created() {
    this.ideaTitle = this.feedbearIdea.title;
    this.ideaDescription = this.feedbearIdea.content;
    if (this.integration.default_project) {
      this.selectedProject = this.integration.default_project;
      this.fetchIssueTypes()
        .then((response) => {
          this.projectIssueTypes = response.data.issue_types;
          this.selectedIssueType = this.integration.default_issue_type;
        })
        .catch((response) => {
          console.log(response)
          this.errorMessage = response.error;
        });
    }
  },
  computed: {
    areFieldsEmpty() {
      return (
        !this.selectedProject ||
        !this.selectedIssueType ||
        !this.ideaTitle ||
        !this.ideaDescription
      );
    },
  },
  methods: {
    createJiraIssue(event) {
      let formData = new FormData(event.target);
      formData.append("jira_issue[post_id]", this.feedbearIdea.id);
      formData.append(
        "jira_issue[project_name]",
        this.getSelectedProjectName()
      );
      formData.append(
        "jira_issue[issue_type_name]",
        this.getSelectedIssueTypeName()
      );

      this.isCreating = true;

      Rails.ajax({
        url: this.resourceUrl,
        type: "POST",
        dataType: "json",
        data: formData,
        success: (response) => {
          console.log(response);
          this.isCreating = false;
          this.jiraIssues.unshift(response.jira_issue);
          this.expandJiraActions = false;
        },
        error: (response) => {
          console.log(response);
          this.errorMessage = response.error;
          this.isCreating = false;
        },
      });
    },
    getIssueTypesOfProject() {
      this.loadingIssueTypes = true;
      this.fetchIssueTypes()
        .then((response) => {
          this.projectIssueTypes = response.data.issue_types;
          this.loadingIssueTypes = false;
        })
        .catch((response) => {
          console.log(response);
          this.errorMessage = response.error;
        });
    },
    fetchIssueTypes() {
      return axios({
        method: "GET",
        url: this.issueTypesUrl + `?project=${this.selectedProject}`,
        headers: {
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]")
            .content,
        },
      });
    },

    getSelectedProjectName() {
      return this.integration.projects_with_ids.find(
        (project) => project[1] === this.selectedProject
      )[0];
    },

    getSelectedIssueTypeName() {
      return this.projectIssueTypes.find(
        (issueType) => issueType[1] === this.selectedIssueType
      )[0];
    },
  },
};
</script>

<style scoped>
img[alt="cross-icon"] {
  cursor: pointer;
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
