<template>
  <li class="">
    <comment-body
      v-if="!comment.is_spam || currentProjectAdmin || comment.user.id === currentUser.id"
      :original-comment="comment"
      :discussion-users="discussionUsers"
      :post-id="postId"
      :board-slug="boardSlug"
      :parent-comment-id="0"
      :current-user="currentUser"
      :current-project-admin="currentProjectAdmin"
      :showPin="showPin"
      @updateComments="updateComment"
      :id="'parent-comment-' + comment.id"
      :class="'parent-comment-' + comment.id"
      :projectName="projectName"
    ></comment-body>

    <div
      class="offset pl-4"
      v-if="!comment.is_spam || currentProjectAdmin || comment.user.id === currentUser.id"
    >
      <div class="border-l-4 border-neutral-200">
        <comment-body
          v-for="nested_comment in nestedCommentsPinned"
          :key="nested_comment.id"
          :original-comment="nested_comment"
          :parent-comment-id="comment.id"
          :discussion-users="discussionUsers"
          :post-id="postId"
          :current-user="currentUser"
          :current-project-admin="currentProjectAdmin"
          :nestedshowPin="nestedshowPin"
          @updateComments="updateComment"
          :id="'child-comment-' + nested_comment.id"
          :class="'child-comment-' + nested_comment.id"
          :projectName="projectName"
        ></comment-body>
      </div>
    </div>
  </li>
</template>

<script>
import Avatar from "vue-avatar";
import CommentInput from "./comment_input.vue";
import CommentBody from "./comment_body.vue";

import EventBus from "event_bus";

import replyIcon from "images/reply.svg";

export default {
  props: [
    "post-id",
    "original-comment",
    "current-user",
    "current-project-admin",
    "discussion-users",
    "board-slug",
    "showPin",
    "projectName",
  ],
  data: function () {
    return {
      inputBoxShown: false,
      comment: this.originalComment,
      replyIcon,
    };
  },
  components: {
    CommentInput,
    CommentBody,
    Avatar,
    replyIcon,
  },
  computed: {
    nestedshowPin() {
      return !(
        this.comment.comments.filter((comment) => comment.pin == true).length >
        0
      );
    },
    nestedCommentsPinned(data) {
      let comment = this.comment.comments ? this.comment.comments : [];
      return comment.sort((a, b) => b.pin - a.pin);
    },
  },
  methods: {
    showInputBox: function () {
      this.inputBoxShown = true;
      this.$nextTick(() => {
        this.$refs.messageArea.$refs.messageAreaInput.focus();
        this.$refs.messageArea.$refs.messageAreaInput.value =
          "@" + this.comment.user.name + " ";
      });
    },
    updateComment: function (data) {
      this.$emit("updatesComment", data);
    },
  },
  mounted: function () {
    var this_comment = this;
    EventBus.$on("newNestedComment", function (new_nested_comment) {
      if (this_comment.comment.id === new_nested_comment.parent_comment_id) {
        if (this_comment.comment.comments) {
          this_comment.comment.comments.push(new_nested_comment);
        } else {
          this_comment.comment.comments = [{ ...new_nested_comment }];
          this_comment.comment = { ...this_comment.comment };
        }
        this_comment.inputBoxShown = false;
      }
    });
  },
};
</script>

<style scoped>
.offset {
  margin-left: 0px;
}
@media only screen and (max-width: 768px) and (min-width: 320px) {
  .offset {
    padding-left: 0px;
  }
}
</style>
