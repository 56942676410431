<template>
  <form @submit.prevent="updateNote();">
    <div>
      <div>
        <div class="changelog-editor">
          <input id="internal-note-input" placeholder="Add a note for your team..." type="text" name="internal-note-input" class="input" v-if="!editorContent && showContent && !showEditor" @click.prevent="showEditor = true"/>
          <div class="internal-notes-updated-at" v-show="editorContent && showContent && !showEditor"><p class="text-sm text-neutral-600">Last edited <span id="internal-notes-updated-at"></span></p><button class="text-red px-3 edit-button" @click.prevent="showEditor = true">Edit</button></div>
          <div class="rounded-lg trix-content bg-neutral-100 p-4 comment-input-container" v-html="editorContent" v-if="editorContent && showContent && !showEditor"></div>
          <VueTrix inputId="internal_notes_text_entry[body]" inputName="internal_notes_text_entry[body]" v-model="editorContent"
                   class="input px-0 focus-within:ring" placeholder="Add a note for your team..." v-if="showEditor" />
        </div>
        <button type="submit" id="internal-notes-btn" class="btn-main ml-auto show_button_below mt-4" v-if="showEditor">
          Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import VueTrix from "vue-trix";
import moment from "moment";

export default {
  props: ["initial-content", "post-id", "board-id", "project-id", "updated-at"],
  data: function () {
    return {
      editorContent: this.initialContent || "",
      showContent: true,
      showEditor: false,
    };
  },
  components: { VueTrix, moment },
  created() {

  },
  mounted() {
    const dateToFormat = document.getElementById("internal-notes-updated-at");
    if(dateToFormat){
      dateToFormat.innerHTML = moment(this.updatedAt).fromNow();
    }
  },
  methods: {
    contentChanged(value) {
      console.log('Content has changed...');
    },
    async updateNote() {
      let data = new FormData();
      data.append("project_id", this.projectId);
      data.append("board_id", this.boardId);
      data.append("post_id", this.postId);
      data.append("internal_notes", this.editorContent);
      Rails.ajax({
        url: `/boards/${this.boardId}/posts/${this.postId}/internal_notes`,
        type: "PUT",
        data: data,
        dataType: "json",
        processData: true,
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.editorContent = data.internal_notes;
          this.updatedAt = data.updated_at;
          this.showEditor=false;
          this.showContent=true;

          document.getElementById("internal-notes-updated-at").innerHTML = moment(this.updatedAt).fromNow();
        },
        error: (data, status) => {
          console.log('Note was not updated...');
        },
      });
    },
  },
  watch: {
    editorContent: {
      handler: "contentChanged",
    },
  },
};
</script>

<style>
trix-editor:empty:not(:focus)::before {
  color: #a9a9a9;
}

.edit-button {
  color: red;
}

.internal-notes-updated-at {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}

</style>
