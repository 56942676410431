<template>
  <div>
    <div v-if="type=='edit'" @click="login_dialog_visible = true"  class="text-dblue-400 hover:text-project-500 pl-2 transition duration-150 ease-in-out" :style="{cursor: 'pointer'}">
        <simple-svg :filepath="editIcon" :width="'18px'" :height="'18px'" />
    </div>
    <div v-if="type=='new'" @click="login_dialog_visible = true" class="dropdown-item rounded-md cursor_point">
        <svg class="w-6 h-6 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
      </svg>
      <span>New board</span>
    </div>
    <div v-if="type=='project-setting'" @click="login_dialog_visible = true"  class="dropdown-item cursor_point">
      <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path>
        </svg>
        <span>Project Settings</span>
    </div>
    <div v-if="type=='new-project'" @click="login_dialog_visible = true" class="flex justify-between dropdown-item cursor_point">
        <span>New project</span>
        <svg class="w-6 h-6 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
        </svg>
    </div>
    <div v-if="type=='my-account'" @click="login_dialog_visible = true" class="dropdown-item cursor_point">
        <svg class="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path></svg>
        <span>My Account</span>
    </div>

    <Dialog title="Log in" v-model="login_dialog_visible">
      <div class="px-4 py-4 pb-6">
        <div class="flex items-center justify-center mt-2 space-x-2">
          <a class="btn-soc" @click.prevent="showLoginWithSendPassword">
            <svg
              class="w-6 h-6 text-neutral-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              ></path>
            </svg>
          </a>

          <a :href="this.fbHref" class="btn-soc">
            <svg
              role="img"
              class="w-6 h-6"
              style="color: #1877f2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z"
              />
            </svg>
          </a>

          <a :href="this.googleHref" class="btn-soc">
            <svg
              role="img"
              class="w-6 h-6"
              style="color: #4285f4"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z"
              />
            </svg>
          </a>

          <a :href="this.twitterHref" class="btn-soc">
            <svg
              role="img"
              class="w-6 h-6"
              style="color: #1da1f2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="currentColor"
                d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"
              />
            </svg>
          </a>
        </div>

        <transition name="popdown" v-if="this.login_togle">
          <div v-if="this.isSendLink">
            <form
              id="new_user"
              action="/users/sign_in"
              @submit.prevent="sendLink"
              accept-charset="UTF-8"
              method="post"
              class="form p-3"
              ref="loginform"
            >
              <input
                type="hidden"
                name="authenticity_token"
                :value="this.authToken"
              />
              <input
                type="hidden"
                name="user_intent"
                :value="this.userIntent"
              />
              <div class="mt-4">
                <label for="user_email" class="label">{{ $t("Email") }}</label>
                <input
                  autocomplete="email"
                  required="required"
                  type="email"
                  value=""
                  name="user[email]"
                  id="user_email"
                  class="input"
                />
              </div>
              <div class="p-4 text-center">{{ $t("A verification link will sent to your email") }}.</div>
              <div class="mt-4 flex justify-center">
                <input
                  type="submit"
                  value="Send me the Link"
                  class="btn-main"
                />
                <input
                  type="submit"
                  name="commit"
                  value="Log in with password"
                  class="btn-spacing text-neutral-800"
                  @click.prevent = "toggleLoginBtn"
                />
              </div>
            </form>
          </div>
          <div class="container" v-if="!this.isSendLink && !this.email_does_not_exists"><p class="alert alert--good">{{ $t("A verification link has been sent to your email") }}</p></div>
          <div class="container" v-if="!this.isSendLink && this.email_does_not_exists"><p class="alert alert--good">{{ $t("If you have an account with us, we've sent an email to") }} {{this.email_user}} {{ $t("with a link to login") }}</p></div>
        </transition>
         <transition name="popdown" v-if="!this.login_togle">
          <div v-if="this.isSendLink">
            <form
              id="new_user"
              action="/users/sign_in"
              @submit.prevent="signInWithPassword"
              accept-charset="UTF-8"
              method="post"
              class="form"
              ref="loginform"
            >
              <div
                class="bg-red-100 rounded border border-red-300 text-red-900 px-4 py-3 mt-4"
                v-if="login_error_msg"
              >
                <p>{{ login_error_msg }}</p>
                <p class="mt-1">
                  {{ $t("Forgot your password?") }}
                  <a
                    href="#"
                    @click.prevent="passwordResetToggle()"
                    class="text-red-900 underline hover:text-red-800 hover:no-underline"
                    >{{ $t("Click here") }}</a
                  >
                </p>
              </div>
              <input
                type="hidden"
                name="authenticity_token"
                :value="this.authToken"
              />
              <div class="mt-4">
                <label for="user_email" class="label">{{ $t("Email") }}</label>
                <input
                  autocomplete="email"
                  required="required"
                  type="email"
                  :value="current_user_email"
                  name="user[email]"
                  id="user_email"
                  class="input"
                />
              </div>
              <div class="mt-4">
                <label for="user_password" class="label">{{ $t("Password") }}</label>
                <input
                  autocomplete="off"
                  required="required"
                  type="password"
                  name="user[password]"
                  id="user_password"
                  class="input"
                />
              </div>
              <div class="mt-4 flex justify-center">
                <input
                  type="submit"
                  name="commit"
                  value="Log in"
                  class="btn-main"
                />
                <input
                  type="submit"
                  name="commit"
                  value="Log in with email"
                  class="btn-spacing text-neutral-800"
                  @click.prevent = "toggleLoginBtn"
                />
              </div>
              <div class="p-4 text-center"><a href="#" @click.prevent="passwordResetToggle()">{{ $t("Set a new password") }}</a></div>
            </form>
          </div>
        </transition>
      </div>
    </Dialog>
    <Dialog title="Set a new password" v-model="password_forgot_dialog_visible">
      <transition name="popdown">
        <div class="container max-w-xl mx-auto mt-8 pb-4" v-if = "this.message && !this.forgot_email_does_not_exist">
          <p class="px-6 py-4 bg-green-100 text-green-800 border border-green-300 rounded-md">{{ $t("You will receive an email with instructions on how to reset your password in a few minutes") }}.</p>
        </div>
        <div class="container max-w-xl mx-auto mt-8 pb-4" v-if = "this.message && this.forgot_email_does_not_exist">
          <p class="px-6 py-4 bg-green-100 text-green-800 border border-green-300 rounded-md">{{ $t("If you have an account with us, we've sent an email to") }} {{this.email_user}} {{ $t("with a link that you can use to reset your password") }}</p>
        </div>
        <div class="px-4 pb-8 pt-4" v-if = "!this.message">
          <form
            id="forgot_password"
            action="/users/password"
            @submit.prevent="forgotPassword"
            accept-charset="UTF-8"
            method="post"
            class="form"
            v-if="this.isSendLink"
            ref="forgotloginform"
          >
            <input
              type="hidden"
              name="authenticity_token"
              :value="this.authToken"
            />
            <input
              type="hidden"
              name="user_intent"
              :value="currentPath"
            />
            <div class="mt-2">
              <label for="user_email" class="label">{{ $t("Email") }}</label>
              <input
                autocomplete="email"
                required="required"
                type="email"
                value=""
                name="user[email]"
                id="forgot_password"
                class="input"
              />
            </div>
            <div class="mt-4 flex justify-center">
              <input
                type="submit"
                name="commit"
                value="Send email"
                class="btn-main"
              />
              <input
                type="submit"
                name="commit"
                value="Click here to login"
                class="btn-spacing text-neutral-800"
                @click.prevent = "toggleLoginBtn1"
              />
            </div>
          </form>
        </div>
      </transition>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "./dialog";
import editIcon from  "../../assets/images/icons/edit.svg"


export default {
  props: [
    'type',
    'auth-token',
    'popup',
    'user_intent',
    "google-href",
    "twitter-href",
    "fb-href",
    "current_user_email"
  ],
  data() {
    return {
      login_dialog_visible: false,
      signup_dialog_visible: false,
      isSendLink: true,
      login_error_msg: "",
      reg_error_msgs: [],
      editIcon,
      userIntent: '',
      login_togle: true,
      password_forgot_dialog_visible: false,
      message: false,
      email_does_not_exists: false,
      forgot_email_does_not_exist: false,
      email_user: "",
    };
  },
  computed: {
    currentPath () {
      return window.location.pathname
    }
  },
  components: {
    Dialog,
    editIcon
  },
  mounted () {
    this.login_dialog_visible = this.popup=="true" ? true : false
    this.userIntent = this.user_intent ? this.user_intent : ''
    this.isSendLink = true;
    this.login_togle = true;

  },
  methods: {
    toggleLoginBtn() {
      this.login_togle = !this.login_togle;
    },
    passwordResetToggle(){
      this.login_dialog_visible = false;
      this.password_forgot_dialog_visible = true;
    },
    toggleLoginBtn1(){
      this.password_forgot_dialog_visible = false;
      this.login_dialog_visible = true;
    },
    signInWithPassword(){
      var data = new FormData(this.$refs.loginform);
      Rails.ajax({
        url: "/users/sign_in",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          let params = (new URL(document.location)).searchParams;
          let update_payment = params.get("update_payment");
          if (update_payment == 'true') {
            window.location.href = '/edit?view=billing'
          } else{
            window.location.href = document.location.split("?")[0];
            //reloadWithTurbolinks();
          }
        },
        error: (data, status) => {
          console.log(data);
          this.login_dialog_visible = true;
          this.login_error_msg = data.error;
        },
      });
    },

    forgotPassword(){
      var data = new FormData(this.$refs.forgotloginform);
      this.email_user = data.get('user[email]')
      Rails.ajax({
        url: "/users/forgot/link",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          if (data == "notExist")
          {
            this.forgot_email_does_not_exist = true;
            this.password_forgot_dialog_visible = true;
            this.message = true;
          }
          else{
            this.forgot_email_does_not_exist = false;
            this.password_forgot_dialog_visible = true;
            this.message = true;
          }
        },
        error: (data, status) => {
          console.log(data);
          this.login_error_msg = data.error;
        },
      });
    },

    sendLink() {
      // this.login_dialog_visible = false
      var data = new FormData(this.$refs.loginform);
      this.email_user = data.get('user[email]')

      let params = (new URL(document.location)).searchParams;
      if ('true' == params.get("update_payment")) {
        data.set('update_payment', true);
      }

      Rails.ajax({
        url: "/users/send/link",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          // console.log('helow')
          if (data == "notExist")
          {
            this.email_does_not_exists = true;
            this.isSendLink = false;
          }
          else
          {
            this.email_does_not_exists = false;
            this.isSendLink = false;
          }
        },
        error: (data, status) => {
          console.log(data);
          this.login_error_msg = data.error;
        },
      });
    },
    signIn() {
      var data = new FormData(this.$refs.loginform);

      Rails.ajax({
        url: "/users/sign_in",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          reloadWithTurbolinks();
        },
        error: (data, status) => {
          console.log(data);
          this.login_error_msg = data.error;
        },
      });
    },
    showLoginWithSendPassword() {
      this.isSendLink = !this.isSendLink
    }
  },
};
</script>

<style scoped>
.btn-soc {
  @apply p-2 shadow-md rounded-md border border-neutral-100 transition duration-150 ease-in-out;
}

.btn-soc:hover {
  @apply shadow-lg;
}
.btn-spacing{
  margin-left: 25px;
  background-color: white;
  cursor: pointer;
}
.cursor_point{
  cursor: pointer;
}
</style>
