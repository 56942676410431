<template>
  <div>
    <div v-if="isCancelling">
      <p class="font-semibold">
        Do you really want to cancel your subscription for this project?
      </p>
      <p class="mt-1">
        Your project will remain active until the end of the current billing
        period. No further charges will be made.
      </p>
      <a style="color: #f84339;" href="#" class="hover:text-project-500" @click.prevent="handleCancelSubscription()"
        >I really want to cancel my subscription</a
      >
    </div>
    <a v-else class="hover:text-project-500" style="color: #f84339;" href="#" @click.prevent="isCancelling = true"
      >Cancel subscription</a
    >
    <div
      v-if="error"
      class="error_show mt-2"
      >{{this.error}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cancelUrl: String,
    isCancelling: false,
    error: '',
  },

  data: function () {
    return {};
  },
  methods: {
    handleCancelSubscription: function () {
      Rails.ajax({
        url: this.cancelUrl,
        type: "DELETE",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          location.reload();
        },
        error: (data, status) => {
          this.error = data.message
        },
      });
    },
  },
};
</script>
