<template>
  <ul :class="user_admin ? 'mt-1' : 'flex items-center flex-wrap'">
    <li v-for="(voter, index) in voters" :key="voter.id">
      <div class="flex flex-row items-center justify-between mb-1">
        <div>
          <li
            :class="
              user_admin
                ? 'flex items-center rounded-md hover:bg-brand-50'
                : 'mt-2 flex-row flex'
            "
            class="pr-1"
          >
            <tippy
              interactive
              :animate-fill="false"
              placement="bottom-start"
              theme="light"
              animation="fade"
              trigger="mouseenter"
              v-if="user_admin && voter.handle"
            >
              <template v-slot:trigger>
                <div class="flex flex-row items-center">
                  <avatar
                    class="avatar shadow-md mr-2 border-2 border-white cursor-pointer"
                    :class="{ 'comment__avatar--admin': isAdmin(voter.id) }"
                    :size="32"
                    :src="voter.avatar_url"
                    :username="voter.name"
                  >
                  </avatar>
                  <div>
                    <a
                      :href="'/users/' + voter.handle"
                      class="text-neutral-800 hover:text-project-500 hover:no-underline py-2 flex-1"
                      >{{ voter.name }}</a
                    >
                  </div>
                </div>
              </template>
              <tooltip-tippy :user="voter"></tooltip-tippy>
            </tippy>
            <div v-else-if="user_admin" class="flex flex-row items-center">
              <avatar
                class="avatar shadow-md mr-2 border-2 border-white"
                :class="{ 'comment__avatar--admin': isAdmin(voter.id) }"
                :size="32"
                :src="voter.avatar_url"
                :username="voter.name"
              >
              </avatar>
              <div
                class="text-neutral-800 hover:text-project-500 hover:no-underline flex-1"
              >
                {{ voter.name }}
              </div>
            </div>
            <div v-else class="flex flex-row items-center">
              <avatar
                class="avatar shadow-md mr-2 border-2 border-white"
                :class="{ 'comment__avatar--admin': isAdmin(voter.id) }"
                :size="32"
                :src="voter.avatar_url"
                :username="voter.name"
              >
              </avatar>
            </div>
          </li>
        </div>

        <div v-if="user_admin">
          <div v-if="showIcon(voter)">
            <div
              @click="behalfUserDelete(voter, voter.id, voter.email, index)"
              class=""
            >
              <svg
                class="w-5 h-5 icon"
                fill="rgba(199, 199, 199)"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                title="Remove this vote"
                v-tippy="{ placement: 'top' }"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import avatar from "vue-avatar";
import EventBus from "event_bus";

export default {
  props: [
    "all_voters",
    "post",
    "user_admin",
    "id",
    "current_user",
    "admin_ids",
  ],
  data: function () {
    return {
      voters: this.all_voters || [],
      voteIndex: "",
    };
  },
  mounted() {
    this.id == "anonymousVoter" &&
      EventBus.$on("anonymousVoter", (vote) => {
        this.voters.push(vote);
      });
    this.id == "userVote" &&
      EventBus.$on("userVoter", (vote) => {
        this.voters.push(vote);
      });
  },

  methods: {
    behalfUserDelete: function (voter, id, email, indexValue) {
      var behalf_user =
        "/boards/" +
        this.post.board_id +
        "/posts/" +
        this.post.id +
        "/vote_behalf_user";
      let data = new FormData();
      data.append("voterid", id);
      if (email) {
        if (voter.handle && !voter.behalf_user) {
          data.append("voting_mode", "delete_normal_user_vote");
        } else {
          data.append("voting_mode", "delete_user_vote");
        }
      } else {
        data.append("voting_mode", "delete_anonymous_vote");
      }
      this.voteIndex = indexValue;
      Rails.ajax({
        url: behalf_user,
        type: "POST",
        data: data,
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.voters.splice(this.voteIndex, 1);
          data.post && EventBus.$emit("updateUpvotes", data.post);
        },
      });
    },
    showIcon(voter) {
      if (voter.email) {
        return this.current_user.email != voter.email;
      } else {
        return true;
      }
    },
    isAdmin(id) {
      return this.admin_ids.includes(id);
    },
  },
  computed: {},
  components: {
    avatar,
  },
};
</script>
<style scoped>
.icon:hover {
  fill: #da4567;
  cursor: pointer;
  @apply outline-none;
}
</style>
