<template>
  <transition-group
    name="list"
    tag="div"
    v-infinite-scroll="loadMore"
    infinite-scroll-distance="10"
    appear
  >
    <div
      class="post"
      is="post"
      v-for="post in paginatedPosts"
      :post="post"
      :key="post.id"
    ></div>
  </transition-group>
</template>

<script>
var infiniteScroll = require("vue-infinite-scroll");
import Post from "./post.vue";

export default {
  props: ["posts"],

  data: function() {
    return {
      page: 1
    };
  },

  directives: { infiniteScroll },

  components: {
    Post
  },

  computed: {
    paginatedPosts: function() {
      let perPage = 10;
      return this.posts.slice(0, this.page * perPage);
    }
  },

  methods: {
    loadMore: function() {
      this.page++;
    }
  }
};
</script>

<style scoped>
/* Animations */
.list-enter-active,
.list-leave-active {
  transition: transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translate(0, -10px);
}

.list-move {
  transition: transform 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
</style>
