<template>
  <span
    v-tippy="{ placement: 'top-end' }"
    :title="format(userdate, `MMM do yyyy \'${$t('at')}\' HH:mm`, {locale: datefnLocales[getLocale]})"
  >
    <slot></slot>
  </span>
</template>

<script>
import { format } from "date-fns";
import datefnLocales from "../locales/datefnlocales"
export default {
  props: ["utcdate"],
  data: function () {
    return {
      format,
      datefnLocales,
    };
  },

  computed: {
    userdate() {
      return new Date(this.utcdate);
    },
    getLocale(){
      return (this.$root.$i18n.locale == "zh-CN")? "zhCN": this.$root.$i18n.locale
    },
  },
};
</script>

<style></style>
