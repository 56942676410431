<template>
  <div>
    <span>
      <div class="flex flex-row">
        <div>
          <avatar
            class="avatar mr-4 border-2 border-white avatr_size"
            :size="36"
            :src="avatar_url"
            :username="user.name"
          ></avatar>
        </div>
        <div class="text-left self-center">
          <h5 class="text-neutral-800 font-semibold text-sm leading-snug">
            {{ user.name }}
          </h5>
          <span v-if="this.user.name != 'Deleted User'" class="text-sm text-neutral-600 leading-snug">{{ user.email }}</span>
        </div>
      </div>

      <div v-if="this.user.name != 'Deleted User'">
        <div class="mt-2 mb-2 lineToltip"></div>
        <div class="flex flex-row">
          <div>
            <a :href="'mailto:'+user.email" class="sendEmailDecoration">
              <button class="btn-flat" :class="screenWidth < 370? 'btnAdjustment' : ''">
                <simple-svg
                  :filepath="mailIcon"
                  :width="'20px'"
                  :height="'20px'"
                />
              <span class="ml-1">Send email</span>
              </button>
            </a>
          </div>
          <div class="ml-3">
            <button class="btn-flat" :class="screenWidth < 370? 'btnAdjustment' : ''" @click="copyEmail">
              <simple-svg
                :filepath="copyTextIcon"
                :width="'20px'"
                :height="'20px'"
              />
              <span class="ml-1">Copy email</span>
            </button>
          </div>
        </div>
      </div>  
    </span>
  </div>
</template>

<script>
import mailIcon from "../images/icons/email.svg";
import copyTextIcon from "../images/icons/copy-text.svg";
  export default {
    props: [
      "user",
      "avatar-url"
    ],
    data: function () {
      return {
        screenWidth: null,
        mailIcon,
        copyTextIcon
      };
    },
    computed:{
      avatar_url() {
        if(this.user.avatar_url) {
          return this.user.avatar_url
        }
        else if (this.avatarUrl){
          return this.avatarUrl
        }
        else {
          return this.user.avatar
        }
      }
    },
    mounted() {
      this.screenWidth = window.innerWidth;
    },
    methods: {
      copyEmail () {
        navigator.clipboard.writeText(this.user.email);
      },
    }
  }

</script>
<style scopped>
  .lineToltip {
    width: 106.4%;
    margin-left: -9px;
    height: 1px;
    background: #d1d5db;
  }

  .btnAdjustment {
    padding: 4px !important;
  }

  .btn-flat {
    outline: none;
  }
  .sendEmailDecoration:hover {
    text-decoration: none;
  }

  .avatr_size {
    width: 37px !important;
    height: 38px !important;
  }
</style>
