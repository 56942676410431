<template>
  <div class="flex items-center flex-1">
    <label
      id="listbox-label"
      class="block text-sm leading-5 font-medium text-gray-700 pr-2"
    >
      Status:
    </label>
    <div class="relative flex-1">
      <span class="inline-block w-full rounded-md shadow-sm">
        <button
          type="button"
          aria-haspopup="listbox"
          :aria-expanded="isOpen"
          aria-labelledby="listbox-label"
          class="cursor-default relative w-full rounded-md border border-gray-300 bg-white pl-3 pr-10 py-1 text-left focus:outline-none focus:ring focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          @click="isOpen = !isOpen"
        >
          <div class="flex items-center">
            <span class="block truncate">
              {{ currentOptionText }}
            </span>
          </div>
          <span
            class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
          >
            <svg
              class="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor"
            >
              <path
                d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </button>
      </span>

      <!-- Select popover, show/hide based on select state. -->
      <div
        class="absolute mt-1 w-full rounded-md bg-white shadow-lg status-popover"
        v-if="isOpen"
      >
        <ul
          tabindex="-1"
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-0"
          class="max-h-56 rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5"
        >
          <!--
            Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

            Highlighted: "text-white bg-indigo-600", Not Highlighted: "text-gray-900"
          -->
          <li
            :id="'listbox-item-' + option.id"
            role="option"
            class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-dblue-100 focus:bg-dblue-100"
            v-for="(option) in listOptions"
            :key="option.id"
            @click="handleChange(option)"
          >
            <div class="flex items-center">
              <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
              <span
                class="block truncate"
                :class="{
                  'font-normal': option.id !== currentValue,
                  'font-semibold': option.id === currentValue,
                }"
              >
                {{ option.name }}
              </span>
            </div>

            <!--
              Checkmark, only display for selected option.

              Highlighted: "text-white", Not Highlighted: "text-indigo-600"
            -->
            <span
              class="absolute inset-y-0 right-0 flex items-center pr-4"
              v-if="option.name === currentValue"
            >
              <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "statuses"],
  data() {
    let statuses_array = [{id: null, name: "All"}];
    this.statuses.forEach((temp_status) => {
      statuses_array.push({id:temp_status.id, name:temp_status.name})
    });
    return {
      isOpen: false,
      listOptions: statuses_array,
    };
  },

  methods: {
    handleChange(e) {
      this.isOpen = false;
      this.$emit("input", e.id);
    },
  },

  computed: {
    currentOptionText() {
      if(this.value){
        return this.listOptions.find((option) => option.id === this.value).name;
      } else {
        return this.listOptions[0].name;
      }
    },

    currentValue() {
      return this.id;
    },
  },
};
</script>

<style>
.project__content__posts__header--bottom .status-popover {
  bottom: 40px;
}
</style>
