<!-- <template>
  <div class="" ref="form">
    <div v-if="this.currentProjectAdmin && this.showAiMessage == 'true' && this.subscriptionValid == 'true' && !accept && !reject && !elaborate && !describe && this.commentsSuggestions == 'true'" id="ai-message" class="relative flex flex-col lg:flex-row justify-between xl:justify-around items-justify lg:items-center rounded-lg border-2 p-4 pt-6 md:p-6 lg:p-4 xl:px-12 gap-4 mb-4 bg-white mt-4">
      <div class="flex flex-col gap-1 xl:-ml-8 2xl:-ml-36" style="max-width: 32rem;">
        <p class="font-bold text-xl text-gray-700">FeedBear can help you reply!</p>
        <p class="text-gray-500">Use FeedBear's AI tools to generate replies for new ideas. Demonstrate to your users that you are listening to their feedback.</p>
      </div>
      <button class="btn-main text-md flex justify-center items-justify rounded-lg whitespace-nowrap px-2 xl:px-14 2xl:-mr-36" @click.prevent="suggestComment()">
        Try it now!
      </button>
      <button class="absolute top-0 right-0" style="height: 35px; width: 35px; padding-top: 4px; padding-left: 4px;" @click.prevent="closeAIMessage()">
        <svg fill="grey" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
        </svg>
      </button>
    </div>
  </div>
</template> -->

<script>
import Avatar from "vue-avatar";
import VueTribute from "vue-tribute";
import FileUpload from "./components/file_upload";
import Dialog from "./components/dialog";
import Spinner from "./projects/spinner_big";

var autosize = require("autosize");

export default {
  props: [
    "post-id",
    "comment-id",
    "current-user",
    "discussion-users",
    "parent-comment-id",
    "time-now",
    "edit-comment",
    "comment-data",
    "id",
    "added-new-comment",
    "project-sso-only",
    "custom-website-href",
    "board-slug",
    "current-project-admin",
    "show-ai-message",
    "subscription-valid",
    "comments-suggestions",
  ],
  data: function () {
    return {
      message: this.editComment? this.commentData.body : "",
      name:"",
      email:"",
      error: "",
      emailError: "",
      user_ip_address: "",
      remark: "",
      screenWidth: null,
      agreed_with_privacy_policy: false,
      uploadImage: false,
      ssoLoginPopup: false,
      tributeOptions: {
        values: this.discussionUsers || [],
        // function called on select that returns the content to insert
        selectTemplate: (item) => {
          const mention_user =  (this.discussionUsers.map(a => a.name).filter(x => x == item.original.name).length > 1) ? item.original.handle : item.original.name
          return '<span contenteditable="false" class="mention">@' +  mention_user + '</span>';

        },

        // template for displaying item in menu
        menuItemTemplate: function (item) {
          return (this.collection[0].values.map(a => a.name).filter(x => x == item.original.name).length > 1) ? "@" + item.original.handle : "@" + item.original.name
        },

        // what to look for when typing
        lookup: function (item) {
          return item.name + item.handle;
        },
        menuItemLimit: 5,
      },
      accept: this.accept,
      reject: this.reject,
      elaborate: this.elaborate,
      describe: this.describe,
      isLoading: false,
      isError: false,
    };
  },
  components: {
    Avatar,
    VueTribute,
    FileUpload,
    Dialog,
    Spinner,
  },

  mounted() {

  },
  methods: {
    closeAIMessage(){
      Rails.ajax({
        url: `/comments/remove_ai_message`,
        type: "POST",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          document.getElementById('ai-message').remove();
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    suggestComment(){
      document.getElementById('suggest-comment').click();
      document.getElementById('ai-message').remove();
    },
  },
};
</script>

<style scoped>
.offset {
  margin-left: calc(36px + 1rem);
}

@media only screen and (max-width: 500px) and (min-width: 320px){
  #draft-btn {
    width: 100%;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  .show_button_below{
    width: 100%;
    text-align: center !important;
    display: block;
  }
  .comment_button{
    display: block;
  }
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: #808080;
  background-color: transparent;
}
</style>

<style>
.mention {
  color: #009be5 !important;
  background-color: #dbf2ff !important;
  padding: 0 4px;
}
</style>
