<template>
  <div>
    <div class="flex flex-row mt-4" v-if="isPost" id="selected-status">
      <div class="relative mr-2 flex-1" id="edit-status-name">
        <select
          id="status"
          name="selectedValue"
          v-model="selectedValue"
          class="select capitalize"
        >
          <option
            v-for="option in showAllStatuses"
            :key="option.id"
            :value="option.id"
            class="capitalize"
          >
            {{ option.name }}
          </option>
        </select>
        <svg
          class="w-5 h-5 absolute right-0 top-0 mt-3 mr-2 pointer-events-none"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
      <div class="text-center" id="edit-change-status-btn">
        <button
          type="button"
          class="btn-main"
          @click.prevent="changepostStatus"
        >
          Save
        </button>
      </div>
    </div>
    <div
      class="text-sm mt-2 px-2 py-1 bg-yellow-50 rounded-md border border-yellow-200 text-yellow-900"
      v-if="selectedStatusValue.name === 'Not now'"
    >
      <p>When you set this status, users will not be notified of the change.</p>
      <p class="mt-1">
        Ideas marked as “Not Now” won’t show in the default view for idea
        boards.
      </p>
    </div>
    <div class="relative" id="edit-status-label">
      <transition name="fadeIn">
        <div
          class="drawer-backdrop"
          v-if="labelsOpen"
          @click.self="labelsOpen = false"
        ></div>
      </transition>
      <transition name="slideFromRight">
        <div
          class="drawer overflow-y-auto"
          v-if="labelsOpen"
          id="edit-status-slide"
        >
          <div
            :class="screenWidth < 440 ? 'drawerContent' : 'drawer__content'"
            id="edit-status-header"
          >
            <div class="flex flex-row items-end justify-between">
              <div id="edit-status-title">
                <h3 class="mt-2 font-bold text-xl text-neutral-800">
                  Edit Statuses
                </h3>
              </div>
              <div id="edit-status-close-icon">
                <a
                  href="#"
                  @click.prevent="labelsOpen = false"
                  title="Close drawer"
                  class="close_color"
                >
                  <simple-svg
                    :filepath="closeIcon"
                    :width="'20px'"
                    :height="'20px'"
                  />
                </a>
              </div>
            </div>
            <p class="mt-1 text-neutral-800">
              Edit existing statuses, reorder them and create new ones.
            </p>

            <div class="labels mt-8" id="all-status-edit">
              <draggable
                @change="sortingStatus"
                :list="filteredStatus"
                :animation="200"
                ghost-class="moving-card"
                filter=".action-button"
                class="w-full max-w-md"
                @start="drag = true"
                @end="drag = false"
                :options="{ handle: '.handle' }"
                id="draggable-edit-status"
              >
                <transition-group>
                  <template v-for="label in filteredStatus">
                    <StatusLabel
                      :original-label="label"
                      :key="label.id"
                      @destroyedLabel="removeLabel($event)"
                      @updatedLabel="updateLabel($event)"
                      :project-id="projectId"
                      :input-hide="hideInputs[label.id]"
                      :hidekey-del="hideDel[label.id]"
                      @changeHide="changeHide($event)"
                      @delHide="delHide($event)"
                      :status-name="statusName"
                      :class="'edit-status-' + label.id"
                      :id="'edit-status-' + label.id"
                    ></StatusLabel>
                  </template>
                </transition-group>
              </draggable>

              <NewStatusLabel
                :project-id="projectId"
                @newLabel="addLabel($event)"
                @delHide="delHide($event)"
                :status-name="statusName"
                id="new-status-container"
              ></NewStatusLabel>
            </div>
          </div>
        </div>
      </transition>
      <button
        :class="
          button_status
            ? 'btn-size focus:outline-none text-sm border border-neutral-300 rounded-md bg-gradient-to-b from-white to-neutral-100 shadow-sm px-3 py-1 text-dblue-500 flex items-center focus:to-white focus:border-neutral-400'
            : 'focus:outline-none btn-flat w-full mt-2'
        "
        @click.prevent="getStatuses"
        id="edit-status-btn"
      >
        <svg
          :width="'16px'"
          :height="'16px'"
          class="mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          v-if="!button_status"
        >
          <path
            fill="currentColor"
            d="M7,6H6V3A1,1,0,0,0,4,3V6H3A1,1,0,0,0,3,8H7A1,1,0,0,0,7,6ZM5,10a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V11A1,1,0,0,0,5,10Zm7,8a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,12,18Zm9-8H20V3a1,1,0,0,0-2,0v7H17a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Zm-2,4a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V15A1,1,0,0,0,19,14Zm-5,0H13V3a1,1,0,0,0-2,0V14H10a1,1,0,0,0,0,2h4a1,1,0,0,0,0-2Z"
          />
        </svg>
        <span>Edit Statuses</span>
      </button>
    </div>
    <ChangelogAi
      :show-company-details="this.showCompanyDetails"
      :show-changelog-post-suggestion="this.showChangelogPostSuggestion"
      :post="this.post"
      :board="this.board"
      :project="this.project"
      :token="this.token"
    />
  </div>
</template>

<script>
import closeIcon from "../images/icons/x.svg";
import StatusLabel from "./status_label";
import NewStatusLabel from "./new_status_label";
import EventBus from "event_bus";
import draggable from "vuedraggable";
import ChangelogModal from "./changelog_modal";
import ChangelogAi from "./changelog_ai";

export default {
  props: [
    "labels",
    "project-id",
    "button_status",
    "selected-status",
    "post",
    "board",
    "status-ids",
    "status-name",
    "allow-priority-change",
    "is-post",
    "changelog-suggestions",
    "project",
    "token",
    "subscription_valid",
  ],

  data: function () {
    return {
      allStatuses: this.labels,
      labelsOpen: false,
      closeIcon,
      selectedValue: this.selectedStatus.id,
      filteredStatus: [],
      hideInputs: {},
      hideDel: {},
      statusId: this.statusIds,
      screenWidth: null,
      showChangelogPostSuggestion: false,
      showCompanyDetails: false,
    };
  },
  mounted: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.labelsOpen = false;
      }
    });
    this.selectedValuetSet = this.selectedValuetSet();
    this.filteredStatus = this.filterByNotSet();
    EventBus.$on("status_label", (status) => {
      this.allStatuses = status;
    });
    this.screenWidth = window.innerWidth;
  },

  components: {
    closeIcon,
    StatusLabel,
    NewStatusLabel,
    draggable,
    ChangelogModal,
    ChangelogAi,
  },

  computed: {
    showAllStatuses() {
      const filterAllStatus = this.statusId.map((id) => {
        return this.allStatuses.find((status) => status.id === id);
      });
      const notSet = this.allStatuses.filter(
        (status) => status.name === this.statusName.not_set
      );
      filterAllStatus.push(notSet[0]);
      return filterAllStatus;
    },

    selectedStatusValue() {
      return this.showAllStatuses.find(
        (status) => status.id === this.selectedValue
      );
    },
  },

  methods: {
    changeHide(ev) {
      const hideKeys = Object.keys(this.hideInputs);
      for (var i = 0; i < hideKeys.length; i++) {
        if (hideKeys[i] == ev) {
          this.hideInputs[ev] = true;
        } else {
          this.hideInputs[hideKeys[i]] = false;
        }
      }
      EventBus.$emit("hideNewInput", false);
      this.filteredStatus = [...this.filteredStatus];
    },
    getStatuses() {
      Rails.ajax({
        url: "/statuses" + "?project_id=" + this.projectId,
        type: "GET",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.allStatuses = data.status_label;
          this.statusId = data.status_ids;
        },
        error: (data, status) => {},
      });
      this.labelsOpen = !this.labelsOpen;
    },
    delHide(ev) {
      const hideDelKeys = Object.keys(this.hideDel);
      for (var i = 0; i < hideDelKeys.length; i++) {
        if (hideDelKeys[i] == ev) {
          this.hideDel[ev] = true;
          EventBus.$emit("focusremove", false);
        } else {
          this.hideDel[hideDelKeys[i]] = false;
        }
      }

      this.filteredStatus = [...this.filteredStatus];
    },
    filterByNotSet() {
      const result = this.allStatuses.filter(
        (status) => status.name !== this.statusName.not_set
      );
      const filterStatus = this.statusId.map((id) => {
        return result.find((status) => status.id === id);
      });
      filterStatus.forEach((status) => {
        this.hideInputs[status.id] = false;
        this.hideDel[status.id] = false;
      });
      return filterStatus;
    },
    selectedValuetSet() {
      let result = this.allStatuses.filter(
        (status) => status.id === (this.selectedStatus?.id || "")
      );
      if (result.length) return this.selectedStatus.id;
      result = this.allStatuses.filter(
        (status) => status.name === this.statusName.not_set
      );
      return result[0].id;
    },
    changepostStatus() {
      let params = new FormData();
      if (this.subscription_valid == "true") {
        this.showAllStatuses.forEach((status) => {
          if (status.id === this.selectedValue) {
            this.savedStatus = status;
          }
        });
        if ("Done" === this.savedStatus.name && this.changelogSuggestions) {
          if (!this.project.company_details) {
            this.showCompanyDetails = true;
            this.showChangelogPostSuggestion = false;
          } else {
            this.showCompanyDetails = false;
            this.showChangelogPostSuggestion = true;
          }
          params.append("check_json", true);
        }
      }

      params.append("post[status_id]", this.selectedValue);

      const compDetail = this.showCompanyDetails;
      const showPostSugg = this.showChangelogPostSuggestion;
      Rails.ajax({
        url: "/boards/" + this.board.slug + "/posts/" + this.post.slug,
        type: "PATCH",
        data: params,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {},
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    addLabel(label) {
      // this.$emit("createdLabel", label);
      this.allStatuses = label.status_label;
      this.statusId = label.status_ids;
      this.selectedValue = this.selectedValuetSet();
      EventBus.$emit("status_label", label.status_label);
      EventBus.$emit("status_ids", label.status_ids);
    },

    removeLabel(label) {
      // this.$emit("destroyedLabel", label);
      this.allStatuses = label.status_label;
      this.statusId = label.status_ids;
      this.selectedValue = this.selectedValuetSet();
      EventBus.$emit("status_label", label.status_label);
      EventBus.$emit("status_ids", label.status_ids);
    },

    updateLabel(label) {
      // this.$emit("updatedLabel", label);
      this.selectedValue = this.selectedValuetSet();
      EventBus.$emit("status_label", label);
      EventBus.$emit("success_message", true);
    },

    sortingStatus() {
      const statusIds = this.filteredStatus.map((status) => {
        return status.id;
      });
      let params = new FormData();

      params.append("status[project_id]", this.projectId);
      params.append("status[status_ids]", statusIds);
      Rails.ajax({
        url: "/statuses/filter_status",
        type: "POST",
        data: params,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.statusId = data.status_id;
        },
        error: (data, status) => {
          console.log(data);
          this.isLoading = false;
        },
      });
    },
  },
  watch: {
    allStatuses: function () {
      this.filteredStatus = this.filterByNotSet();
    },
  },
};
</script>
<style scoped>
.btn-size {
  width: 100%;
}
.close_color {
  color: #525252;
}
</style>
