<template>
  <div class="mt-2">
    <table class="table-auto mb-1" v-if="allowedEmails.length > 0">
      <thead>
        <tr>
          <th class="pr-2 py-2 text-left">Email address</th>
          <th class="px-2 py-2 text-left">Date added</th>
          <th class="px-2 py-2 text-left">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="email in allowedEmails" :key="email.id">
          <td class="pr-2 py-1">{{ email.email_address }}</td>
          <td class="px-2 py-1">{{ email.created_at | humanDate }}</td>
          <td class="px-2 py-1">
            <a href="#" @click.prevent="destroyEmail(email)">Remove</a>
          </td>
        </tr>
      </tbody>
      <tbody></tbody>
    </table>

    <transition name="fromLeft" mode="out-in" appear>
      <p
        class="mb-1 text-neutral-700"
        v-if="allowedEmails.length === 0 && !isEmailAdding"
      >
        No allowed email addresses yet
      </p>

      <p class="mb-1 text-neutral-700" v-if="isEmailRemoving || isEmailAdding">
        Working …
      </p>
    </transition>

    <form action class="mt-2">
      <div class="flex items-center">
        <input
          type="text"
          class="input mr-2"
          placeholder="Example: jenny@company.com"
          v-model="newEmail"
          :disabled="isEmailAdding"
        />
        <button
          type="submit"
          class="btn flex-shrink-0"
          @click.prevent="addEmail()"
          :disabled="isEmailAdding"
        >
          Add allowed email address
        </button>
      </div>
      <p v-if="emailError.length > 1" class="text-red-500 text-sm mt-1">
        {{ emailError }}
      </p>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    emails: Array,
  },
  data: function () {
    return {
      allowedEmails: this.emails,
      newEmail: "",
      emailError: "",
      isEmailAdding: false,
      isEmailRemoving: false,
    };
  },

  methods: {
    isEmailValid: function () {
      // Not perfect, but good enough to prevent typos
      return /^[\w.-]+@[\w-]+\.[\.\w+-]*\w$/.test(this.newEmail);
    },

    addEmail: function () {
      this.emailError = "";

      if (!this.isEmailValid()) {
        this.emailError =
          'Not a valid email address. Please enter an email address like "support@feedbear.com"';
        return;
      }

      this.isEmailAdding = true;
      var params = new FormData();
      params.append("email_address", this.newEmail);

      Rails.ajax({
        url: "/invited_customers",
        type: "POST",
        data: params,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.newEmail = "";
          this.allowedEmails.push(data);
          this.isEmailAdding = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isEmailAdding = false;
        },
      });
    },

    destroyEmail: function (email) {
      this.isEmailRemoving = true;

      Rails.ajax({
        url: "/invited_customers/" + email.id,
        type: "DELETE",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.allowedEmails.splice(this.allowedEmails.indexOf(email), 1);
          this.isEmailRemoving = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isEmailRemoving = false;
        },
      });
    },
  },

  filters: {
    humanDate: function (date) {
      let event = new Date(date);
      let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      return event.toLocaleDateString(undefined, options);
    },
  },
};
</script>

<style></style>
