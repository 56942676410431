<template>
  <div>
    <div class="flex flex row items-center mt-4" v-if="isBeingEdited">
      <div id="draggable-icon">
        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h16M4 16h16"></path></svg>
      </div>

      <div class="ml-3 swatchDiv swatchesContainer" id="status-color">
        <v-swatches
          v-model="newColor"
          :swatches="swatches"
          show-fallback
          fallback-ok-class="swatchOkBtn"
          :wrapper-style="this.screenWidth < 400 ? {width: '160px'} : {width: '265px'}"
          :container-style="{margin: '5px 0px'}"
          fallback-input-class="swacthInputField"
          fallback-ok-text="Save"

        >
          <div
            slot="trigger"
            class="color_div flex items-end justify-end"
            :style="{ backgroundColor: newColor }"
            @click="fallbackInputField"
          >
          </div>
        </v-swatches>
      </div>
      <div class="status_input" id="status-input-container">
        <div class="ml-4 mr-4">

          <input
          type="text"
          class="input newNameInput"
          v-model="newName"
          @keyup.enter="handleSave()"
          placeholder="Status name"
          ref="input"
          v-if="isBeingEdited"
          id="new_status_id"
          @focusout="focusOut"
          />
      </div>
      </div>
      <div class="" id="status-delete-btn">
        <a
          href="#"
          class="text-neutral-800"
          @mousedown.prevent="textRemove()"
          id="deleteBtnId"
        >
         <span class="delete-btn-flat">
          <simple-svg
            :filepath="trashIcon"
            :width="'20px'"
            :height="'20px'"
          />
        </span>
        </a>
      </div>
    </div>
    <div
      v-if="error"
      class="error_show mt-2"
      id="status-error"
    >
      {{ this.error }}
    </div>
    <div class="pb-28 flex flex-col justify-end " >
      <div class="flex flex-row justify-end ">
        <a
          href="#"
          class="labels__item-inside addStatusBtn mt-4"

          key="new_label_inside"
          @click.prevent="startEditing()"
        >
          <div id="add-status-btn">
            <span class="btn-flat">
            <svg class="w-5 h-5"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              <span class="ml-2 text-neutral-800">{{ $t("Add status") }}</span>
            </span>
          </div>
        </a>
      </div>
      <div class="mt-2 flex flex-row justify-end" :class="successMessage ? 'messageVisible' : 'messageHidden'" id="status-successfull-msg">
        <p class="successMessageAlert py-4 px-6 flex">{{ $t("Your changes have been saved successfully") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import plusIcon from "../images/icons/plus_alt.svg";
import editIcon from "../images/icons/edit.svg";
import trashIcon from "../images/icons/trash.svg";
import questionIcon from "../images/icons/question-circle.svg";
import EventBus from "event_bus";
import VSwatches from "vue-swatches";

export default {
  props: ["project-id", "status-name"],
  data: function() {
    return {
      isBeingEdited: false,
      isLoading: false,
      newName: "",
      newColor: "#828282",
      plusIcon,
      editIcon,
      trashIcon,
      questionIcon,
      successMessage: false,
      error: "",
      screenWidth: null,
      focusremove:true,
      swatches: [
        "#2D80FA",
        "#18BFFF",
        "#1ECFC7",
        "#FF08C3",
        "#F92B60",
        "#FF702C",
        "#FDB402",
        "#20C933",
        "#8B46FF",
        "#666666"
      ]
    };
  },

  components: {
    plusIcon,
    editIcon,
    trashIcon,
    questionIcon,
    VSwatches
  },

  mounted: function() {
    EventBus.$on("hideNewInput", (hide) => {
      this.isBeingEdited = hide
    });
    EventBus.$on("success_message", (msg) => {
      this.successMessage = msg
      setTimeout(()=>{ this.successMessage = false; }, 1000);
    });
    this.screenWidth = window.innerWidth
  },

  methods: {
    startEditing(e) {
      this.isBeingEdited = true;
      this.$emit("delHide", -1);
      this.$nextTick(()=> {
        document.getElementById("new_status_id").focus();
      })
    },
    focusOut() {
      if(!this.focusremove)
        {
          this.focusremove=true;
          return
        }
      this.createNewLabel();
    },
    fallbackInputField() {
      const parent = document.querySelector('.swatchesContainer');
      parent.querySelector('.vue-swatches__container').style.margin="7px 0px 0px 0px";
      parent.querySelector('.vue-swatches__container').style.padding="5px 5px 0px 7px";

      var fallback_wrapper = document.getElementsByClassName("vue-swatches__fallback__wrapper");
      fallback_wrapper[fallback_wrapper.length-1].style.width="92%"
    },

    handleClose() {
      this.isBeingEdited = false;
      this.newName = "";
      this.newColor = "#828282";
      this.error="";
    },
    textRemove() {
      this.focusremove=false;
      this.newName = "";
      this.error="";
      this.isBeingEdited = false;
    },

    handleSave() {
      this.createNewLabel();
    },

    createNewLabel() {
      if(!this.newName || this.newName==this.statusName.not_set) return
      this.isLoading = true;
      let params = new FormData();

      params.append("label[name]", this.newName);
      params.append("label[color]", this.newColor);
      params.append("label[project_id]", this.projectId);
      params.append("label[hide_roadmap]", false);
      params.append("label[hide_boards]", false);

      Rails.ajax({
        url: "/statuses",
        type: "POST",
        data: params,
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data =>  {
          if (data.error){
            this.error=data.error;
          }
          else{
            this.$emit("newLabel", data);
            this.successMessage = true;
            setTimeout(()=>{ this.successMessage = false; }, 1000);
            this.handleClose();
          }
          this.isLoading = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped>
.error_show {
  color: red;
  margin-left: 4.35rem;
}
.deleteBtn {
  padding: 4px;
}
.addStatusBtn {
  justify-content: flex-end !important;
  font-size: 0.9rem !important;
  width: 140px;
}
.messageVisible {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s linear;
}
.messageHidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1s, opacity 1s linear;
}
</style>
