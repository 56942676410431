<template>
  <div class="sso-webhook-config">
    <div>
      <div class="flex flex-row">
        <div class="flex flex-row px-2 dataInstruct" @click="instrectionData"  :class="openIntrectionTab? 'borderSelectLine' : 'borderLine'">
          <div>
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path></svg>
          </div>
          <div class="ml-3 cursor-pointer">
            <span :class="screenWidth>440 ? 'whitespace-nowrap' : '' "> Events </span>
          </div>
        </div>
        <div class="flex flex-row px-2" @click="codeData" :class="openCodeInstructionTab? 'borderSelectLine' : 'borderLine'">
          <div>
           <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"></path></svg>
          </div>
          <div class="ml-3 cursor-pointer">
            <span :class="screenWidth>440 ? 'whitespace-nowrap' : '' "> Code samples</span>
          </div>
        </div>
        <div class="borderLine" style="width:65%;" v-if="screenWidth>440  "></div>
      </div>
    </div>
    <div class="mt-4 mb-4" v-if="openIntrectionTab">
      <div>
        <p>Here you can find a list of all the available events along with their corresponding payloads:</p>
        <div class="mt-3">
          <div><h4 class="font-semibold text-neutral-900">post_new</h4></div>
          <div class="mt-1"><p>This event is triggered whenever a new idea is published to one of your boards.</p></div>
          <prism-editor class="prism-code-editor rounded" v-model="new_post_code" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
        </div>
        <div class="mt-3">
          <div><h4 class="font-semibold text-neutral-900">post_update</h4></div>
          <div class="mt-1"><p>This event is triggered whenever a idea update is published to one of your boards.</p></div>
          <prism-editor class="prism-code-editor rounded" v-model="update_post_code" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
        </div>
        <div class="mt-3">
          <div><h4 class="font-semibold text-neutral-900">comment_new</h4></div>
          <div class="mt-1"><p>This event is triggered whenever a new comment is published to one of your ideas.</p></div>
          <prism-editor class="prism-code-editor rounded" v-model="new_comment_code" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
        </div>
        <div class="mt-3">
          <div><h4 class="font-semibold text-neutral-900">comment_update</h4></div>
          <div class="mt-1"><p>This event is triggered whenever a comment update is published to one of your ideas.</p></div>
          <prism-editor class="prism-code-editor rounded" v-model="update_comment_code" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
        </div>
        <div class="mt-3">
          <div><h4 class="font-semibold text-neutral-900">post_upvote</h4></div>
          <div class="mt-1"><p>This event is triggered whenever a new upvote is published to one of your ideas.</p></div>
          <prism-editor class="prism-code-editor rounded" v-model="post_upvote_code" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
        </div>
        <div class="mt-3">
          <div><h4 class="font-semibold text-neutral-900">new_user</h4></div>
          <div class="mt-1"><p>This event is triggered whenever a new user is added in your project.</p></div>
          <prism-editor class="prism-code-editor rounded" v-model="new_user_code" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
        </div>
        <div class="mt-3">
          <div><h4 class="font-semibold text-neutral-900">post_status_update</h4></div>
          <div class="mt-1"><p>This event is triggered whenever the status of the idea is updated.</p></div>
          <prism-editor class="prism-code-editor rounded" v-model="post_status_update_code" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
        </div>
      </div>
    </div>
    <div v-else-if="openInstallTab">
      <div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <div class="mt-5">
              <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
                <li class="-mb-px mr-1 last:mr-0 text-center">
                  <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleInstallTabs(1)" v-bind:class="{'btn-main': openInstallTab !== 1, 'active-tab': openInstallTab === 1}">
                    Node.js
                  </a>
                </li>
                <li class="-mb-px mr-1 last:mr-0 text-center">
                  <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal" v-on:click="toggleInstallTabs(2)" v-bind:class="{'btn-main': openInstallTab !== 2, 'active-tab': openInstallTab === 2}">
                    Ruby
                  </a>
                </li>
              </ul>
            </div>
            <h4 class="mt-6 font-semibold text-neutral-700">Create webhooks action on your server</h4>
            <div class="flex flex-wrap">
              <div class="w-full">
                <div class="">
                  <div class="pt-2 flex-auto">
                    <div class="tab-content tab-space">
                      <div v-bind:class="{'hidden': openInstallTab !== 1, 'block': openInstallTab === 1}">
                        <prism-editor class="prism-code-editor rounded" v-model="codes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                      </div>
                      <div v-bind:class="{'hidden': openInstallTab !== 2, 'block': openInstallTab === 2}">
                        <prism-editor class="prism-code-editor rounded" v-model="codes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'highlight.js/styles/github.css';
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
export default {
   props:{
    subDomain:String,
    customDomain: String
  },
  data(){
    return{
      openIntrectionTab: true,
      openCodeInstructionTab: false,
      openInstallTab: 1,
      inputVerifyToken: '',
      showLoader: false,
      screenWidth: '',
      codes: '',
      lineNumbers: true,
      new_post_code: '',
      update_post_code: '',
      new_comment_code: '',
      update_comment_code: '',
      post_upvote_code: '',
      new_user_code: '',
      post_status_update_code: '',
    }
  },
  mounted () {
    this.screenWidth = window.innerWidth;
    // this code indendation is wrong because of editor
    this.codes = this.nodeCodeSample();
    this.new_post_code = this.newPostCodeSample();
    this.update_post_code = this.updatePostCodeSample();
    this.new_comment_code = this.newCommentCodeSample();
    this.update_comment_code = this.updateCommentCodeSample();
    this.post_upvote_code = this.postUpvoteCodeSample();
    this.new_user_code = this.newUserCodeSample();
    this.post_status_update_code = this.postStatusUpdateCodeSample();

  },
  methods: {

    toggleInstallTabs: function(tabNumber){
      this.openInstallTab  = tabNumber;
      if (this.openInstallTab == 1) {
        this.codes = this.nodeCodeSample();
      }
      else if (this.openInstallTab == 2) {
        this.codes=`FEEDBEAR_AUTH_TOKEN = [Generated auth token]\n\nbefore_action :verify_token\n\ndef event_handler\n\tif params[:event_name] == 'post_new'\n\t\t# Write implementation to handle new post event\n\tend\nend\n\ndef verify_token\n\treturn render json: { message: "Missing auth token" } if request.headers["token"].blank? || request.headers["token"] != FEEDBEAR_AUTH_TOKEN\nend`
      }
    },
    highlighter(code) {
      return highlight(code, languages.js);
    },
    instrectionData: function(){
      this.openIntrectionTab = true;
      this.openCodeInstructionTab = false;
    },
    codeData: function(){
      this.openIntrectionTab = false;
      this.openCodeInstructionTab = true;
    },
    nodeCodeSample: function() {
      return `post('{{Your Webhook Action}}', (req, res) => {
  const webhookToken = '{{Generated Webhook Token}}';
  if (req.headers.token === webhookToken) {
    return res.status(200).json({ message: 'success' });
  }
  return res.status(401).json({ message: '{{Relevent Error Message}}'});
});`
    },
    newPostCodeSample: function(){
       return ` 'event_name' => 'post_new',
 'event_date' => '2020-01-23T04:56:07',
 'payload'   => array (
    'post_id'      => '123',
    'post_title'   => '{{ post title }}',
    'post_content' =>  '{{ post content }}',
    'created'      => '2020-01-23T04:56:07',
    'board_id'     => '11',
    'board_name'   => '{{ board name }}',
    'author_name'  => '{{ author name }}',
    'author_email' => '{{ author email }}',
    'post_url'     => '{{ post_url }}',
    'attachments'  => '[{ image: {url} }]'
  );`
    },
    updatePostCodeSample: function(){
       return ` 'event_name' => 'post_update',
 'event_date' => '2020-01-23T04:56:07',
 'payload'   => array (
    'post_id'            => '123',
    'post_title'         => '{{ post title }}',
    'post_content'       =>  '{{ post content }}',
    'updated'            => '2020-01-23T04:56:07',
    'number_of_upvotes'  => '12',
    'number_of_comments' => '7',
    'board_id'           => '11',
    'board_name'         => '{{ board name }}',
    'author_name'  => '{{ author name }}',
    'author_email' => '{{ author email }}',
    'post_url'           => '{{ post_url }}',
    'attachments'  => '[{ image: {url} }]'
  );`
    },
    newCommentCodeSample: function(){
       return ` 'event_name' => 'comment_new',
 'event_date' => '2020-01-23T04:56:07',
 'payload'   => array (
    'comment_id'   => '123',
    'comment_body' => '{{ comment body }}',
    'created'      => '2020-01-23T04:56:07',
    'post_id'      => '121',
    'post_title'   =>  '{{ post title }}',
    'author_name'  => '{{ author name }}',
    'author_email' => '{{ author email }}',
    'post_url'     => '{{ post_url }}',
    'attachments'  => '[{ image: {url} }]'
  );`
    },
    updateCommentCodeSample: function(){
      return ` 'event_name' => 'comment_update',
 'event_date' => '2020-01-23T04:56:07',
 'payload'   => array (
    'comment_id'   => '123',
    'comment_body' => '{{ comment body }}',
    'updated'      => '2020-01-23T04:56:07',
    'post_id'      => '121',
    'post_title'   =>  '{{ post titile }}'
  );`
    },
    postUpvoteCodeSample: function(){
      return ` 'event_name => 'post_upvote',
 'event_date' => '2020-01-23T04:56:07',
 'payload'   => array (
    'vote_id'    => '123',
    'vote_type'  => 'Post',
    'voter_name' => '{{ voter name }}',
    'created'    => '2020-01-23T04:56:07',
    'post_id'    => '121',
    'post_title' =>  '{{ post title }}',
    'board_id'   => '11',
    'board_name' => '{{ board name }}',
    'author_name'  => '{{ author name }}',
    'author_email' => '{{ author email }}',
    'attachments'  => '[{ image: {url} }]'
  );`
    },
    newUserCodeSample: function(){
      return ` 'event_name' => 'user_new',
 'event_date' => '2020-01-23T04:56:07',
 'payload'   => array (
    'user_id' => '123',
    'email'   => 'newuser@gmail.com',
    'name'    => '{{ user name }}',
    'handle'  => 'new-user-258',
    'created' => '2020-01-23T04:56:07'
  );`
    },
    postStatusUpdateCodeSample: function(){
      return ` 'event_name' => 'post_status_update',
 'event_date' => '2020-01-23T04:56:07',
 'payload'   => array (
    'post_title' => '{{ post title }}',
    'post_id'   => '123',
    'previous_status'    => '{{ previous status }}',
    'new_status'  => '{{ new status }}',
    'created' => '2020-01-23T04:56:07',
    'changed_by' => '{{ user name }}'
  );`
    },
  }
};
</script>

<style scoped>
</style>

