<template>
  <div>
    <tippy
      interactive
      :animate-fill="false"
      placement="bottom-start"
      theme="light"
      animation="fade"
      trigger="mouseenter"
    >
      <template v-slot:trigger>
        <div class="flex flex-row items-center">
          <avatar
            class="avatar shadow-md mr-2 border-2 border-white cursor-pointer"
            :class="{ 'comment__avatar--admin': isByProjectAdmin }"
            :size="28"
            :src="avatarUrl"
            :username="postUser.name"
          >
          </avatar>
          <p class="pr-1">
            <span class="font-semibold cursor-pointer">{{
              postUser.name
            }}</span>
            <span v-if="isByProjectAdmin" class="text-gray-500"
              >({{ projectName }})</span
            >
          </p>
        </div>
      </template>
      <tooltip-tippy :user="postUser" :avatar-url="avatarUrl"></tooltip-tippy>
    </tippy>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import mailIcon from "../images/icons/email.svg";
import copyTextIcon from "../images/icons/copy-text.svg";
import "tippy.js/themes/light.css";

export default {
  props: ["post-user", "avatar-url", "by-project-admin", "project-name"],

  data: function () {
    return {
      screenWidth: null,
      mailIcon,
      copyTextIcon,
      isByProjectAdmin: JSON.parse(this.byProjectAdmin),
    };
  },
  components: {
    Avatar,
  },
  mounted: function () {
    this.screenWidth = window.innerWidth;

    console.log(this.isByProjectAdmin, "this.isByProjectAdmin");
  },
  methods: {
    copyEmail() {
      navigator.clipboard.writeText(this.comment.user.email);
    },
  },
};
</script>
<style scoped>
.lineToltip {
  width: 100%;
  border: 1px solid #f0f0f0;
}

.btnAdjustment {
  padding: 4px !important;
}

.btn-flat {
  outline: none;
}

.sendEmailDecoration:hover {
  text-decoration: none;
}
</style>
