<template v-if="getCurrentProjectPlan && getPlanFeatures">
  <div
    class="grid grid-cols-1 lg:grid-cols-7 gap-6 mt-4 mb-8 items-start relative"
  >
    <nav class="lg:col-span-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1">
      <a
        href="#"
        @click.prevent="changeTab('general')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'general' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
            clip-rule="evenodd"
          ></path></svg
        ><span>General settings</span>
      </a>
      <a
        href="#"
        @click.prevent="changeTab('billing')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'billing' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
          <path
            fill-rule="evenodd"
            d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
            clip-rule="evenodd"
          ></path></svg
        ><span>Billing</span>
      </a>
      <a
        href="#"
        @click.prevent="changeTab('team')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'team' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
          ></path></svg
        ><span>Team</span>
      </a>
      <a
        href="#"
        @click.prevent="changeTab('integrations')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'integrations' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z"
          ></path></svg
        ><span>Integrations</span>
      </a>

      <a
        href="#"
        @click.prevent="changeTab('sso')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'sso' }"
      >
        <simple-svg
          :filepath="keyIcon"
          :width="'1em'"
          :height="'1em'"
          class="mr-3"
          style="display: inline"
        />
        <span>Single Sign-On</span>
      </a>
      <a
        href="#"
        @click.prevent="changeTab('domain')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'domain' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
            clip-rule="evenodd"
          ></path></svg
        ><span>Custom domain</span>
      </a>
      <a
        href="#"
        @click.prevent="changeTab('privacy')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'privacy' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          v-if="projectPrivate"
          key="lock_closed"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <svg
          class="w-5 h-5 mr-2"
          v-else
          key="lock_open"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"
          ></path>
        </svg>
        <span>Privacy</span>
      </a>
      <a
        href="#"
        @click.prevent="changeTab('menus')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'menus' }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 mr-2"
          viewBox="0 0 92 92"
          fill="currentColor"
        >
          <path
            d="M78 23.5H14c-3.6 0-6.5-2.9-6.5-6.5s2.9-6.5 6.5-6.5h64c3.6 0 6.5 2.9 6.5 6.5s-2.9 6.5-6.5 6.5zM84.5 46c0-3.6-2.9-6.5-6.5-6.5H14c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5h64c3.6 0 6.5-2.9 6.5-6.5zm0 29c0-3.6-2.9-6.5-6.5-6.5H14c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5h64c3.6 0 6.5-2.9 6.5-6.5z"
          />
        </svg>
        <span>Menus</span>
      </a>
      <a
        href="#"
        @click.prevent="changeTab('feedback')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'feedback' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          fill="currentColor"
          viewBox="0 0 24 24  "
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22,1H15a2.44,2.44,0,0,0-2.41,2l-.92,5.05a2.44,2.44,0,0,0,.53,2,2.47,2.47,0,0,0,1.88.88H17l-.25.66A3.26,3.26,0,0,0,19.75,16a1,1,0,0,0,.92-.59l2.24-5.06A1,1,0,0,0,23,10V2A1,1,0,0,0,22,1ZM21,9.73l-1.83,4.13a1.33,1.33,0,0,1-.45-.4,1.23,1.23,0,0,1-.14-1.16l.38-1a1.68,1.68,0,0,0-.2-1.58A1.7,1.7,0,0,0,17.35,9H14.06a.46.46,0,0,1-.35-.16.5.5,0,0,1-.09-.37l.92-5A.44.44,0,0,1,15,3h6ZM9.94,13.05H7.05l.25-.66A3.26,3.26,0,0,0,4.25,8a1,1,0,0,0-.92.59L1.09,13.65a1,1,0,0,0-.09.4v8a1,1,0,0,0,1,1H9a2.44,2.44,0,0,0,2.41-2l.92-5a2.44,2.44,0,0,0-.53-2A2.47,2.47,0,0,0,9.94,13.05Zm-.48,7.58A.44.44,0,0,1,9,21H3V14.27l1.83-4.13a1.33,1.33,0,0,1,.45.4,1.23,1.23,0,0,1,.14,1.16l-.38,1a1.68,1.68,0,0,0,.2,1.58,1.7,1.7,0,0,0,1.41.74H9.94a.46.46,0,0,1,.35.16.5.5,0,0,1,.09.37Z"
          ></path></svg
        ><span>Feedback</span>
      </a>
      <a
        href="#"
        @click.prevent="changeTab('roadmap')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'roadmap' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
          ></path>
        </svg>
        <span> Roadmap </span>
      </a>
      <a
        href="#"
        @click.prevent="changeTab('changelog')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'changelog' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
          <path
            fill-rule="evenodd"
            d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          ></path></svg
        ><span>Changelog</span>
      </a>
      <a
        href="#"
        @click.prevent="changeTab('widget')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'widget' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
            clip-rule="evenodd"
          ></path></svg
        ><span>Widget</span>
      </a>

      <a
        href="#"
        @click.prevent="changeTab('embed_settings')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'embed_settings' }"
      >
        <svg
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          class="w-5 h-5 mr-2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
          ></path>
        </svg>

        <span> Embed Settings</span>
      </a>

      <a
        href="#"
        @click.prevent="changeTab('custom_code')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'custom_code' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
          ></path></svg
        ><span>Custom Code</span>
      </a>

      <a
        href="#"
        @click.prevent="changeTab('export_screen')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'export_screen' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
          ></path>
        </svg>
        <span>Export</span>
      </a>

      <a
        href="#"
        @click.prevent="changeTab('webhooks')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'webhooks' }"
      >
        <simple-svg
          :filepath="webhookIcon"
          :width="'1em'"
          :height="'1em'"
          class="mr-3"
          style="display: inline"
        />
        <span>Webhooks</span>
      </a>

      <a
        href="#"
        @click.prevent="changeTab('email_setting')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'email_setting' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"
          />
        </svg>
        <span class="material-icons-outlined"></span>
        <span>Email Settings</span>
      </a>

      <a
        href="#"
        @click.prevent="changeTab('translation_setting')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'translation_setting' }"
      >
        <svg
          class="w-5 h-5 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path
            d="M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z"
          />
        </svg>
        <span>Translation Settings</span>
      </a>

      <a
        href="#"
        @click.prevent="changeTab('ai_settings')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'ai_settings' }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 544 512"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 h-5 mr-2"
        >
          <path
            d="M234.7 42.7L192 64L234.7 85.3L256 128L277.3 85.3L320 64L277.3 42.7L256 0L234.7 42.7ZM384.4 192.4L351.6 159.6L432 79.2L464.8 112L384.4 192.4ZM96 32L64 96L0 128L64 160L96 224L128 160L192 128L128 96L96 32ZM416 352L352 384L416 416L448 480L480 416L544 384L480 352L448 288L416 352ZM144 512L183.6 472.4L504.4 151.6L544 112L504.4 72.4L471.6 39.6L432 0L392.4 39.6L71.6 360.4L32 400L71.6 439.6L104.4 472.4L144 512Z"
            fill="currentColor"
          />
        </svg>
        <span>AI Settings</span>
      </a>

      <a
        href="#"
        @click.prevent="changeTab('spam_settings')"
        class="list-item"
        :class="{ 'list-item--active': activeTab === 'spam_settings' }"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="24" height="24" class="w-5 h-5 mr-2">
          <path 
          fill="currentColor"
          d="M 41.070313 16.230469 C 39.96875 15.980469 39.296875 13.992188 38.515625 11.691406 C 36.941406 7.035156 34.5625 0 24.996094 0 C 15.222656 0 13.054688 6.542969 11.472656 11.320313 C 10.679688 13.714844 9.992188 15.78125 8.640625 16.261719 C 3.148438 18.203125 0 22.605469 0 28.332031 C 0 28.863281 0.414063 29.304688 0.945313 29.332031 C 7.097656 29.65625 7.328125 32.609375 7.570313 35.738281 C 7.722656 37.683594 7.894531 39.890625 10.042969 40.605469 L 11.089844 40.949219 C 14.453125 42.050781 15.710938 42.460938 16.945313 45.078125 C 18.097656 47.511719 19.949219 50 24.972656 50 L 25.171875 50 C 30.167969 49.9375 31.761719 47.320313 33.042969 45.21875 C 33.777344 44.015625 34.355469 43.066406 35.4375 42.742188 C 40.28125 41.285156 40.96875 38.304688 41.523438 35.910156 C 42.113281 33.375 42.5 31.699219 47.597656 31.699219 C 48.015625 31.699219 48.460938 31.710938 48.9375 31.738281 C 49.210938 31.730469 49.480469 31.621094 49.679688 31.433594 C 49.878906 31.242188 49.992188 30.949219 49.992188 30.675781 C 49.992188 25.144531 49.992188 18.257813 41.070313 16.230469 Z M 18.996094 16.996094 C 17.890625 16.996094 16.996094 15.65625 16.996094 13.996094 C 16.996094 12.339844 17.890625 10.996094 18.996094 10.996094 C 20.101563 10.996094 20.996094 12.339844 20.996094 13.996094 C 20.996094 15.65625 20.101563 16.996094 18.996094 16.996094 Z M 26.996094 16.996094 C 25.890625 16.996094 24.996094 15.65625 24.996094 13.996094 C 24.996094 12.339844 25.890625 10.996094 26.996094 10.996094 C 28.101563 10.996094 28.996094 12.339844 28.996094 13.996094 C 28.996094 15.65625 28.101563 16.996094 26.996094 16.996094 Z"/>
        </svg>
        <span>Spam Settings</span>
      </a>
    </nav>

    <div class="lg:col-span-5 bg-white rounded-lg border mb-4 p-10">
      <div id="settings-div">
        <transition name="fromBottom" mode="out-in" appear>
          <div v-if="activeTab === 'general'" :key="1" class="relative">
            <slot name="general"></slot>
          </div>

          <div v-if="activeTab === 'team'" :key="7" class="relative">
            <transition name="fromBottom" mode="out-in" appear>
              <TeamSettings
                v-if="getCurrentProjectPlan && getPlanFeatures"
                :admins="JSON.parse(this.admins)"
                :project="currentProject"
              />
            </transition>
          </div>

          <div
            v-if="activeTab === 'integrations'"
            :key="2"
            class="relative"
            id="integration-div"
          >
            <slot name="integrations"></slot>
          </div>

          <div v-if="activeTab === 'domain'" :key="3" class="relative">
            <div v-if="currentProject.sub_plan === 'lite'">
                <CustomDomainAlert title="Upgrade your plan to use this feature" :description="'Adding a custom domain isn\'t included in the Lite plan. Please upgrade your plan to use this feature.'" :link="'/edit?view=billing'" :click="'Click here to upgrade'" class="mb-4"/>  
            </div>
            <SetDomainError
              v-if="setDomainError"
              title="Error"
              :message="setDomainErrorText"
            />
            <div class="flex justify-between">
              <h3 class="text-xl font-bold text-neutral-800">Custom domain</h3>
              <a
                href="https://feedbear.helpscoutdocs.com/article/9-setting-up-a-custom-domain"
                target="_blank"
                data-beacon-article-sidebar="5d7787d704286364bc8eec43"
                @click.prevent="openHelp('5d7787d704286364bc8eec43', 'sidebar')"
                title="Help center"
                v-tippy="{ placement: 'top' }"
              >
                <simple-svg
                  :filepath="helpIcon"
                  :width="'2em'"
                  :height="'2em'"
                  style="display: inline"
                />
              </a>
            </div>
            <p class="text-neutral-800">
              Make your FeedBear project available on your own subdomain.
              <br />
              <em>(for example feedback.company.com)</em>
            </p>

            <div class="mt-4">
              <div
                class=""
                v-if="(done || currentDomain.length > 0) && !doneRemoving"
              >
                <span class="">
                  Your custom domain is <code>{{ domain }}</code>
                </span>
                <span
                  v-if="done || currentDomain.length > 0 || doneRemoving"
                  id="remove_domain_button"
                  class="inline-flex"
                >
                  <a
                    href="#"
                    @click.prevent="removeDomain()"
                    style="display: inline-flex; align-items: baseline"
                  >
                    <simple-svg
                      :filepath="trashIcon"
                      :width="'1em'"
                      :height="'1em'"
                      style="display: inline-flex; position: relative; top: 2px"
                    />
                    Remove this domain
                  </a>
                </span>
              </div>

              <form action="#" @submit.prevent="setDomain()" class="" v-if="currentProject.sub_plan != 'lite'">
                <div class="">
                  <label class="label" for="custom_domain"
                    >Your custom domain</label
                  >
                  <div class="flex md:items-center flex-col md:flex-row">
                    <input
                      type="text"
                      id="custom_domain"
                      name="custom_domain"
                      class="input mr-2"
                      v-model="domain"
                    />
                    <button
                      type="submit"
                      class="btn-main flex-shrink-0 mt-3 md:mt-0 justify-center"
                      id="set_domain_button"
                    >
                      Set custom domain
                    </button>
                  </div>
                </div>
              </form>

              <div v-if="domainFeedbearComError" class="mt-2 text-red-600">
                Please point your custom domain to a domain you control - like
                your project's domain. If you wish to change your feedbear.com
                subdomain, please contact support.
              </div>
              <div v-if="invaliddomainError" class="mt-2 text-red-600">
                The provided URL is invalid, please check it for typos
              </div>

              <div
                v-if="(done || currentDomain.length > 0) && !doneRemoving"
                class="mt-2"
              >
                <p>
                  <span class="font-semibold"
                    ><span v-if="currentProject.cert_name === null">1. </span
                    >Configure your DNS by adding a CNAME record like
                    this:</span
                  ><br />
                  Name: <code>{{ domain }}</code> <br />
                  Content: <code>{{ this.projectDomain }}</code>
                </p>
                <p class="mt-4" v-if="currentProject.cert_name === null">
                  <span class="font-bold"
                    >2. After you add the CNAME record</span
                  >, click on "Save and Activate Custom Domain".
                </p>
                <p class="mt-4 text-green-600" v-else>
                  Your custom domain is active. Your project is available at
                  <a :href="'https://' + domain" target="_blank">{{ domain }}</a
                  >.
                </p>
                <button
                  type="button"
                  @click="generateCertificate()"
                  v-if="
                    currentProject.cert_name === null &&
                    !addingSSL &&
                    !sslSuccess &&
                    !sslError
                  "
                  class="btn-main flex-shrink-0 mt-2 justify-center"
                >
                  Save and Activate Custom Domain
                </button>
                <div class="mt-2 animate-pulse" v-if="addingSSL">
                  Requesting certficate, please wait ...
                </div>
                <div class="mt-2 text-green-600" v-if="sslSuccess">
                  Success! Your custom domain is now active. You can visit your
                  project at
                  <a :href="'https://' + domain" target="_blank">{{ domain }}</a
                  >.
                </div>
                <div class="mt-2 text-red-600" v-if="sslError">
                  There was an error requesting a certificate. Make sure you
                  configured your DNS as described in step 1. If you need a
                  hand, please contact support.
                </div>
              </div>
            </div>
          </div>

          <div v-if="activeTab === 'privacy'" :key="4" class="relative">
            <transition name="fromBottom" mode="out-in" appear>
              <PrivacySettings
                v-if="getCurrentProjectPlan && getPlanFeatures"
                :project-private="projectPrivate"
                :allowed-emails="projectAllowedEmails"
                :allowed-domains="projectAllowedDomains"
                :is-intercom-connected="intercomConnected"
                :sub-domain="projectDomain"
                :current-project="JSON.parse(this.project)"
                @privacyUpdate="updatePrivacy"
              ></PrivacySettings>
            </transition>
          </div>

          <div v-if="activeTab === 'menus'" key="menus" class="relative">
            <transition name="fromBottom" mode="out-in" appear>
              <menus
                :project-menus="currentProject.menu_items"
                :roadmap-enabled="currentProject.roadmap_active"
                :changelog-enabled="currentProject.changelog_active"
                :boards="currentProject.boards"
              />
            </transition>
          </div>

          <div v-if="activeTab === 'changelog'" :key="5" class="relative">
            <transition name="fromBottom" mode="out-in">
              <ChangelogSettings
                :changelog-active="changelogActive"
                :token="this.form_token"
                :changelog-intro-heading="this.changelog_intro_heading"
                :changelog-intro-text="this.changelog_intro_text"
                :changelog-page-name="this.changelog_page_name"
              ></ChangelogSettings>
            </transition>
          </div>

          <div v-if="activeTab === 'sso'" :key="5" class="relative">
            <transition name="fromBottom" mode="out-in">
              <SsoSettings
                v-if="getCurrentProjectPlan && getPlanFeatures"
                :project-token="projectToken"
                :sso-verify-token-url="ssoVerifyTokenUrl"
                :project-update-url="projectUpdateUrl"
                :toggle-sso-only-url="toggleSsoOnlyUrl"
                :project-sso-redirect-url="this.currentProject.sso_redirect_url"
                :project-sso-only="this.currentProject.sso_only"
                :project-sso-enabled="this.currentProject.sso_enabled"
                :toggle-sso-integration-url="toggleSsoIntegrationUrl"
                :sub-domain="this.projectDomain"
                :custom-domain="this.currentDomain"
                :payload-sso-token-data="payloadSsoTokenData"
                :payload-sso-token="this.payloadSsoToken"
                @ssoEnableUpdate="updateSsoEnable"
                @ssoOnlyUpdate="updateSsoOnly"
                @ssoRedirectUrlUpdate="updateSsoRedirectUrl"
                @updateToken="updateToken"
              ></SsoSettings>
            </transition>
          </div>

          <div v-if="activeTab === 'widget'" key="widget" class="relative">
            <transition name="fromBottom" mode="out-in" appear>
              <div>
                <div class="absolute top-0 right-0">
                  <a
                    href="https://help.feedbear.com/category/23-feedback-widget"
                    target="_blank"
                    data-beacon-article-sidebar="5f328f5d042863444aa0250e"
                    @click.prevent="
                      openHelp('5f328f5d042863444aa0250e', 'sidebar')
                    "
                    title="Help center"
                    v-tippy="{ placement: 'top' }"
                  >
                    <simple-svg
                      :filepath="helpIcon"
                      :width="'2em'"
                      :height="'2em'"
                      style="display: inline"
                    />
                  </a>
                </div>
                <WidgetSettings
                  :project="currentProject"
                  :project-token="projectJwt ? projectJwt : projectToken"
                  :project-update-url="projectUpdateUrl"
                  @updateToken="updateToken"
                ></WidgetSettings>
              </div>
            </transition>
          </div>

          <div
            v-if="activeTab === 'email_setting'"
            key="email_setting"
            class="relative"
          >
            <transition name="fromBottom" mode="out-in" appear>
              <email-setting
                :saveEmailSettingsUrl="saveEmailSettingsUrl"
                :getEmailSettingsUrl="getEmailSettingsUrl"
                :currentUserOwner="currentUserOwner"
              >
              </email-setting>
            </transition>
          </div>

          <div
            v-if="activeTab === 'translation_setting'"
            key="translation"
            class="relative"
          >
            <transition name="fromBottom" mode="out-in">
              <Translation />
            </transition>
          </div>

          <div v-if="activeTab === 'billing'" :key="6" class="relative">
            <transition name="fromBottom" mode="out-in" appear>
              <Billing
                v-if="
                  getCurrentProjectPlan &&
                  getPlanFeatures &&
                  selectedPlan.length == 0
                "
                :currentPlan="getCurrentProjectPlan"
                :isCodeActivated="currentProject.coupon_code ? true : false"
                @plan-selection="handleSelectedPlan"
              />
              <div v-if="selectedPlan.length > 0">
                <a
                  href="#"
                  @click.prevent="selectedPlan = ''"
                  class="flex items-center text-neutral-800 hover:no-underline hover:text-project-500"
                >
                  <svg
                    class="w-6 h-6 mr-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16l-4-4m0 0l4-4m-4 4h18"
                    ></path>
                  </svg>
                  Back to plans
                </a>
                <checkout
                  :public-key="this.stripePublicKey"
                  :action="this.paymentAction"
                  :validate-vat-url="this.validateVatUrl"
                  :stripe-customer="stripeCustomer"
                  :plan="selectedPlan"
                  @payment-ok="handlePaymentOk"
                ></checkout>
              </div>
            </transition>
          </div>

          <div v-if="activeTab === 'roadmap'" :key="0" class="relative">
            <transition name="fromBottom" mode="out-in">
              <RoadmapSettings
                :roadmap-active="roadmapActive"
                :token="this.form_token"
                :roadmap-intro-heading="this.roadmap_intro_heading"
                :roadmap-intro-text="this.roadmap_intro_text"
                :roadmap-page-name="this.roadmap_page_name"
              ></RoadmapSettings>
            </transition>
          </div>

          <div v-if="activeTab === 'feedback'" key="feedback" class="relative">
            <transition name="fromBottom" mode="out-in">
              <FeedbackSettings
                :allow-anonymous-vote="this.allow_anonymous_vote"
                :allow-anonymous-posts="this.allow_anonymous_posts"
                :allow-reactions="this.allow_reactions"
                :allow-anonymous-reactions="this.allow_anonymous_reactions"
                :restricted-domains="this.restricted_domains"
                :project-id="this.project_id"
                :token="this.form_token"
                @allowAnonymouslyVote="changeVote"
                @allowAnonymousPosts="changeAnonPosts"
                @allowReactions="changeReactions"
                @allowAnonymousReactions="changeAnonymousReactions"
              ></FeedbackSettings>
            </transition>
          </div>
          <div
            v-if="activeTab === 'custom_code'"
            key="custom_code"
            class="relative"
          >
            <transition name="fromBottom" mode="out-in">
              <div class="">
                <h3 class="text-xl font-bold text-neutral-800">Custom Code</h3>

                <h4 class="mt-2 font-bold">Custom CSS</h4>
                <p class="text-neutral-800">
                  Add your own CSS code to customize the appearance and layout
                  of your boards.
                </p>
                <prism-editor
                  class="my-editor mt-4"
                  v-model="code"
                  :highlight="highlighter"
                  :line-numbers="lineNumbers"
                  @input="changeCss"
                ></prism-editor>

                <h4 class="mt-6 font-bold">
                  Custom code at the end of <code>&lt;/head&gt;</code>
                </h4>
                <p class="text-neutral-800">
                  Add custom HTML code to the end of the
                  <code>&lt;/head&gt;</code> tag. Scripts executed here can
                  impact site performance.
                </p>
                <prism-editor
                  class="my-editor mt-4"
                  v-model="code_head"
                  :highlight="html_highlighter"
                  :line-numbers="lineNumbers"
                  @input="changeCss"
                ></prism-editor>

                <h4 class="mt-6 font-bold">
                  Custom code at the end of <code>&lt;/body&gt;</code>
                </h4>
                <p class="text-neutral-800">
                  Add custom HTML code to the end of the
                  <code>&lt;/body&gt;</code> tag. Ideal for analytics scripts.
                </p>
                <prism-editor
                  class="my-editor mt-4"
                  v-model="code_body"
                  :highlight="html_highlighter"
                  :line-numbers="lineNumbers"
                  @input="changeCss"
                ></prism-editor>

                <button class="btn-main mt-4" type="submit" @click="saveCss()">
                  Save
                </button>
              </div>
            </transition>
          </div>

          <div
            v-if="activeTab === 'export_screen'"
            key="export_screen"
            class="relative"
          >
            <h3 class="text-xl font-bold text-neutral-800">Export</h3>
            <p class="text-neutral-800 mb-4">
              You can download an archive of all of your project's data,
              including feedback, users, comments and upvotes.
            </p>
            <button class="btn-main mt-4" type="submit" @click="archiveZip()">
              Download project archive
            </button>
          </div>

          <div v-if="activeTab === 'webhooks'" key="webhooks" class="relative">
            <transition name="fromBottom" mode="out-in">
              <Webhooks
                :project-webhook-token="projectWebhookToken"
                :project-webhook-url="currentProject.webhook_url"
                :project-update-url="projectUpdateUrl"
                :project-webhook-enabled="currentProject.webhook_enabled"
                :webhook-error-message="currentProject.webhook_error_message"
                @webhookEnabledUpdate="updateWebhookEnabled"
                @webhookUrlUpdate="updateWebhookUrl"
                :sub-domain="this.projectDomain"
                :custom-domain="this.currentDomain"
              ></Webhooks>
            </transition>
          </div>

          <div
            v-if="activeTab === 'embed_settings'"
            key="webhooks"
            class="relative"
          >
            <transition name="fromBottom" mode="out-in">
              <EmbedSettings
                :project="currentProject"
                :project-domain="projectDomain"
              />
            </transition>
          </div>

          <div v-if="activeTab === 'ai_settings'" :key="ai" class="relative">
            <transition name="fromBottom" mode="out-in">
              <AiSettings
                :project="currentProject"
                :token="this.form_token"
              ></AiSettings>
            </transition>
          </div>

          <div v-if="activeTab === 'spam_settings'" :key="spam" class="relative">
            <transition name="fromBottom" mode="out-in">
              <SpamSettings 
                :project="currentProject"
                :token="this.form_token"
              />
            </transition>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Checkout from "./checkout";
import Billing from "./billing";
import PlanSelect from "./plan_select";
import RedeemCode from "./redeem_code";
import PrivacySettings from "./settings/privacy";
import ChangelogSettings from "./settings/changelog";
import RoadmapSettings from "./settings/roadmap";
import TeamSettings from "./settings/team";
import WidgetSettings from "./settings/widget";
import FeedbackSettings from "./settings/feedback";
import SsoSettings from "./settings/sso";
import Webhooks from "./settings/webhook";
import EmailSetting from "./settings/email_setting";
import SetDomainError from "./settings/custom_alert";
import Translation from "./settings/translation_setting";
import EmbedSettings from "./settings/embed_settings.vue";
import AiSettings from "./settings/ai_settings";
import SpamSettings from "./settings/spam_settings";

import helpIcon from "images/icons/help-circle.svg";
import trashIcon from "images/icons/trash.svg";
import leftArrow from "images/icons/arrow-left.svg";
import keyIcon from "images/icons/key.svg";
import webhookIcon from "images/icons/webhook.svg";

import CustomDomainAlert from "./settings/plan_alert";

import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism-tomorrow.css";
import { mapGetters } from "vuex";
import Menus from "./settings/menus";

export default {
  props: {
    currentUser: String,
    saveEmailSettingsUrl: String,
    getEmailSettingsUrl: String,
    setDomainUrl: String,
    generateCertUrl: String,
    currentDomain: String,
    projectDomain: String,
    stripePublicKey: String,
    paymentAction: String,
    validateVatUrl: String,
    stripeCustomer: String,
    currentPlan: String,
    isProjectPrivate: String,
    isChangelogActive: String,
    isRoadmapActive: String,
    allowedDomains: String,
    allowedEmails: String,
    admins: String,
    isOwner: String,
    isIntercomConnected: String,
    project: String,
    isFeedbearAdmin: String,
    token: String,
    roadmapIntroHeading: String,
    roadmapIntroText: String,
    roadmapPageName: String,
    changelogIntroHeading: String,
    changelogIntroText: String,
    changelogPageName: String,
    allowAnonymousVote: String,
    allowAnonymousPosts: String,
    allowReactions: String,
    allowAnonymousReactions: String,
    restrictedDomains: String,
    projectId: String,
    projectToken: String,
    projectJwt: String,
    ssoVerifyTokenUrl: String,
    projectUpdateUrl: String,
    toggleSsoOnlyUrl: String,
    toggleSsoIntegrationUrl: String,
    customCss: String,
    customHead: String,
    customBody: String,
    subscriptionMessage: String,
    reactiveUrl: String,
    reactiveValid: String,
    payloadSsoToken: String,
    payloadSsoTokenData: String,
    projectWebhookToken: String,
    billingMessage: String,
    warningsUrl: String,
    plansUrl: String,
    currentPlanUrl: String,
    comparePlanUrl: String,
    stripePriceKeysUrl: String,
    cancelUrl: String,
    stripeSubscription: String,
  },

  data: function () {
    return {
      currentLoggedInUser: JSON.parse(this.currentUser),
      activeTab: "",
      domain: this.currentDomain || "",
      done: false,
      doneRemoving: false,
      selectedPlan: "",
      paymentOkMessageShown: false,
      subActive: this.currentPlan.length > 0 ? true : false,
      projectPrivate: JSON.parse(this.isProjectPrivate),
      changelogActive: JSON.parse(this.isChangelogActive),
      roadmapActive: JSON.parse(this.isRoadmapActive),
      projectAllowedDomains: JSON.parse(this.allowedDomains),
      projectAllowedEmails: JSON.parse(this.allowedEmails),
      intercomConnected: JSON.parse(this.isIntercomConnected),
      currentUserOwner: JSON.parse(this.isOwner),
      currentProject: JSON.parse(this.project),
      currentUserAdmin: JSON.parse(this.isFeedbearAdmin),
      addingSSL: false,
      sslSuccess: false,
      sslError: false,
      domainFeedbearComError: false,
      RedeemCode,
      helpIcon,
      trashIcon,
      leftArrow,
      keyIcon,
      webhookIcon,
      form_token: this.token,
      roadmap_intro_heading: this.roadmapIntroHeading,
      roadmap_intro_text: this.roadmapIntroText,
      roadmap_page_name: this.roadmapPageName,
      changelog_intro_heading: this.changelogIntroHeading,
      changelog_intro_text: this.changelogIntroText,
      changelog_page_name: this.changelogPageName,
      allow_anonymous_vote: this.allowAnonymousVote,
      allow_anonymous_posts: this.allowAnonymousPosts,
      allow_reactions: this.allowReactions,
      allow_anonymous_reactions: this.allowAnonymousReactions,
      restricted_domains: this.restrictedDomains,
      project_id: this.projectId,
      code: this.customCss
        ? this.customCss
        : "/*" + " body {\n\tbackground-color: red !important\n} " + "*/\n",
      code_head: this.customHead ? this.customHead : "",
      code_body: this.customBody ? this.customBody : "",
      lineNumbers: true,
      change_css: false,
      invaliddomainError: false,
      setDomainError: false,
      setDomainErrorText: "",
      subscriptionMsg: this.subscriptionMessage,
    };
  },
  components: {
    Checkout,
    PlanSelect,
    RedeemCode,
    PrivacySettings,
    ChangelogSettings,
    RoadmapSettings,
    TeamSettings,
    WidgetSettings,
    FeedbackSettings,
    SsoSettings,
    Webhooks,
    EmailSetting,
    SetDomainError,
    Translation,
    Billing,
    EmbedSettings,
    Menus,
    AiSettings,
    SpamSettings,
    CustomDomainAlert,
  },
  async mounted() {
    await this.$store.dispatch("setWarningsUrl", this.warningsUrl);
    await this.$store.dispatch("setPlansUrl", this.plansUrl);
    await this.$store.dispatch("setCurrentPlanUrl", this.currentPlanUrl);
    await this.$store.dispatch("setComparePlanUrl", this.comparePlanUrl);
    await this.$store.dispatch("getAllWarnings");
    await this.$store.dispatch("getPlan");
    await this.$store.dispatch("fetchCurrentPlan");
    try {
      await this.$store.commit(
        "setStripeSubscriptionID",
        JSON.parse(this.stripeSubscription).id
      );
    } catch (error) {
      console.log("Stripe Customer not found");
    }
    await this.$store.dispatch("stripePriceKeys", this.stripePriceKeysUrl);
    await this.$store.commit(
      "setStripeSubscription",
      JSON.parse(this.stripeSubscription)
    );
    let current_url = new URL(window.location.href);
    let url_tab = current_url.searchParams.get("view");
    this.activeTab = url_tab || "general";
  },
  computed: {
    ...mapGetters(["getCurrentProjectPlan", "getPlanFeatures"]),
  },
  methods: {
    changeCss() {
      this.change_css = true;
    },
    highlighter(code) {
      return highlight(code, languages.js);
    },
    html_highlighter(code) {
      return highlight(code, languages.html);
    },
    openHelp(article_id, type) {
      Beacon("article", article_id, {
        type: type,
      });
    },
    changeVote(data) {
      this.allow_anonymous_vote = data;
    },
    changeAnonPosts(data) {
      this.allow_anonymous_posts = data;
    },
    changeReactions(data) {
      this.allow_reactions = data;
    },
    changeAnonymousReactions(data) {
      this.allow_anonymous_reactions = data;
    },
    handleSelectedPlan: function (plan_name) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "checkoutV2",
      });

      this.selectedPlan = plan_name;
    },
    handlePaymentOk: function () {
      this.paymentOkMessageShown = true;
      this.subActive = true;
      if (typeof fbq !== "undefined") {
        fbq("track", "Subscribe", {
          value: "29.00",
          currency: "USD",
          predicted_ltv: "290.00",
        });
      }
      document.cookie = "subscribe_track=subscribe_track";
      window.location.assign("/edit?view=billing");
      this.activeTab = "billing";
    },

    changeTab: function (value) {
      var current_url = new URL(window.location.href);
      current_url.searchParams.set("view", value);
      window.history.replaceState({}, "", current_url);
      this.activeTab = value;
    },
    loadBilling: function () {
      this.activeTab = "billing";
    },

    loadTeam: function () {
      this.activeTab = "team";
    },

    generateCertificate() {
      this.addingSSL = true;
      this.sslSuccess = false;

      Rails.ajax({
        url: this.generateCertUrl,
        type: "POST",
        data: {},
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          console.log("Success!");
          this.addingSSL = false;
          this.sslSuccess = true;
        },
        error: (data, status) => {
          console.log("Error generating certificate.", data);
          this.addingSSL = false;
          this.sslError = true;
        },
      });
    },

    setDomain: function () {
      if (this.domain.includes("feedbear.com")) {
        this.domainFeedbearComError = true;
        return;
      }
      if (
        !this.domain.match(
          /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        )
      ) {
        this.invaliddomainError = true;
        return;
      }
      this.domainFeedbearComError = false;
      this.invaliddomainError = false;
      this.setDomainError = false;
      var data = new FormData();
      data.append("project[custom_domain]", this.domain);

      document.getElementById("set_domain_button").disabled = true;
      document.getElementById("set_domain_button").innerText = "Working...";

      Rails.ajax({
        url: this.setDomainUrl,
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          if (data.domain_error) {
            this.invaliddomainError = true;
            document.getElementById("set_domain_button").innerText =
              "Set custom domain";
            return;
          }
          console.log("Success!");
          this.domain = data;
          document.getElementById("set_domain_button").innerText = "Done!";
          this.done = true;
        },
        error: (data, status) => {
          console.log("Error saving domain.", data);
          this.setDomainErrorText = JSON.parse(data[0].body[0]).message;
          this.setDomainError = true;
          document.getElementById("set_domain_button").disabled = false;
          document.getElementById("set_domain_button").innerText =
            "Set custom domain";
          this.domain = "";
        },
      });
    },

    removeDomain: function () {
      if (window.confirm("Do you really want to remove the custom domain?")) {
        document.getElementById("remove_domain_button").innerHTML =
          "Working...";

        Rails.ajax({
          url: this.setDomainUrl,
          type: "DELETE",
          beforeSend: function () {
            return true;
          },
          success: (data) => {
            console.log("Success!");
            this.doneRemoving = true;
            document.getElementById("remove_domain_button").innerHTML =
              "Domain removed successfully.";
            this.domain = "";
            window.location.href = "https://" + this.projectDomain + "/edit";
          },
          error: (data, status) => {
            console.log("Error saving domain.", data);
          },
        });
      }
    },

    updatePrivacy: function (isPrivate) {
      this.projectPrivate = isPrivate;
    },

    updateSsoEnable: function (isSsoEnable) {
      this.currentProject.sso_enabled = isSsoEnable;
    },

    updateSsoOnly: function (isSsoOnly) {
      this.currentProject.sso_only = isSsoOnly;
    },

    updateSsoRedirectUrl: function (ssoRedirectUrl) {
      this.currentProject.sso_redirect_url = ssoRedirectUrl;
    },

    updateWebhookEnabled: function (enabled) {
      this.currentProject.webhook_enabled = enabled;
    },
    updateWebhookUrl: function (webhookUrl) {
      this.currentProject.webhook_url = webhookUrl;
    },

    saveCss: function () {
      // if (!this.change_css) return;
      let data = new FormData();
      data.append("custom_css", this.code);
      data.append("custom_head", this.code_head);
      data.append("custom_body", this.code_body);
      data.append("id", this.project_id);
      Rails.ajax({
        url: "/custom_css",
        type: "patch",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.code = data.custom_css;
          this.code_head = data.custom_head;
          this.code_body = data.custom_body;
          this.$store.dispatch("getAllWarnings");
          location.reload();
        },
        error: (data, status) => {
          console.log(data);
          this.isAllowAnonymousToggleLoading = false;
        },
      });
    },

    archiveZip: function () {
      window.location = "/projects/archive_csv?project_id=" + this.project_id;
    },

    reactiveSubscription: function () {
      Rails.ajax({
        url: this.reactiveUrl,
        type: "POST",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          location.reload();
          console.log(data);
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },

    updateToken(value) {
      this.payloadSsoToken = value;
      this.currentProject.token = value;
    },

    updateJwt(value) {
      this.currentProject.jwt = value;
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
};
</script>

<style scoped>
.list-item {
  display: flex;
}

.my-editor {
  background: #f6f3f3;
  color: #2f2f2f;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

.prism-editor__textarea:focus {
  outline: none;
}

@media only screen and (max-width: 480px) {
  #settings-div {
    margin-left: -1rem;
  }
}
</style>
<style>
.prism-editor__container textarea:focus {
  outline: none !important;
}
.prism-editor-wrapper .prism-editor__container {
  min-height: 150px;
}
</style>
