<template>
  <div class="rounded-lg bg-neutral-100 p-4" ref="form">
    <!--<div v-if="this.currentProjectAdmin && this.showAiMessage == 'true' && this.subscriptionValid == 'true' && !accept && !reject && !elaborate && !describe && this.commentsSuggestions == 'true'" id="ai-message" class="relative flex flex-col lg:flex-row justify-between xl:justify-around items-justify lg:items-center rounded-lg border-2 p-4 pt-6 md:p-6 lg:p-4 xl:px-12 gap-4 mb-4 bg-white">
      <div class="flex flex-col gap-1 xl:-ml-8 2xl:-ml-36" style="max-width: 32rem;">
        <p class="font-bold text-xl text-gray-700">FeedBear can help you reply!</p>
        <p class="text-gray-500">Use FeedBear's AI tools to generate replies for new ideas. Demonstrate to your users that you are listening to their feedback.</p>
      </div>
      <button class="btn-main text-md flex justify-center items-justify rounded-lg whitespace-nowrap px-2 xl:px-14 2xl:-mr-36" @click.prevent="suggestComment()">
        Try it now!
      </button>
      <button class="absolute top-0 right-0" style="height: 35px; width: 35px; padding-top: 4px; padding-left: 4px;" @click.prevent="closeAIMessage()">
        <svg fill="grey" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
        </svg>
      </button>
    </div>-->
    <div
      class="bg-red-100 rounded border border-red-300 text-red-900 px-4 py-3 mt-4 mb-3"
      v-if="error"
      id="comment-error"
    >
      <p>{{ error }}</p>
    </div>
    <div
      class="bg-red-100 rounded border border-red-300 text-red-900 px-4 py-3 mt-4 mb-3 cursor-pointer"
      v-if="emailError"
    >
      <p>
        Email is already in use.
        <a @click="clickLoginBtn()"> Click here </a>
        to login and add your idea
      </p>
    </div>
    <div class="flex items-center" v-if="editComment" id="edit-comment-input">
      <vue-tribute :options="tributeOptions" class="flex flex-col flex-1 gap-1">
        <textarea
          v-model="message"
          @input="resize()"
          ref="messageAreaInput"
          name="new_comment"
          id="new_comment"
          rows="1"
          class="input"
          style="min-height: 120px; max-height: fit-content"
          :placeholder="$t('What do you think?')"
        ></textarea>
      </vue-tribute>
    </div>

    <div class="flex items-center" v-else-if="currentUser" id="comment-input">
      <avatar
        v-if="this.screenWidth >= 500"
        class="avatar shadow-md mr-4 border-2 border-white"
        :class="{ 'comment__avatar--admin': currentProjectAdmin }"
        :src="currentUser.avatar"
        :username="currentUser.name"
        :size="36"
      ></avatar>

      <vue-tribute :options="tributeOptions" class="flex flex-col flex-1 gap-1">
        <p
          contenteditable
          @input="resize()"
          ref="messageAreaInput"
          name="new_comment"
          id="new_comment"
          rows="1"
          class="input break-words"
          style="min-height: 42px; max-height: fit-content"
          :placeholder="$t('What do you think?')"
        ></p>
      </vue-tribute>
    </div>

    <div class="flex items-center" id="comment-input" v-else>
      <!-- <avatar
        class="avatar shadow-md mr-4 border-2 border-white"
        :src="currentUser.avatar"
        :username="currentUser.name"
        :size="36"
      ></avatar> -->
      <vue-tribute :options="tributeOptions" class="flex flex-col flex-1 gap-1">
        <p
          contenteditable
          @input="resize()"
          ref="messageAreaInput"
          name="new_comment"
          id="new_comment"
          rows="1"
          class="input break-words"
          style="min-height: 42px; max-height: fit-content"
          :placeholder="$t('What do you think?')"
        ></p>
        <div
          class="flex flex-col md:flex-row gap-1"
          v-if="this.projectSsoOnly !== 'true'"
        >
          <input
            type="text"
            v-model="name"
            name="name"
            id="name"
            class="input"
            :placeholder="$t('Name')"
          />
          <input
            v-model="email"
            name="email"
            id="email"
            class="input"
            :placeholder="$t('Email')"
          />
          <input
            type="hidden"
            class="remark"
            name="remark"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            placeholder="name@domain.com"
            v-model="remark"
          />
        </div>
        <div
          class="text-neutral-700 text-xs text-width mt-3 flex flex-row md:mt-1"
        >
          <input
            required="required"
            type="checkbox"
            v-model="agreed_with_privacy_policy"
            name="agree_checkbox"
            id="user_agree_checkbox"
            style="height: 12px; margin-top: 2px"
          />
          <div class="ml-1">
            <span>{{ $t("I agree with") }}</span>
            <a
              href="https://www.feedbear.com/privacy-policy"
              title="Privacy Policy"
              target="_blank"
              class="text-neutral-600 underline hover:no-underline hover:text-project-500"
              >{{ $t("Privacy Policy") }}</a
            >
            {{ $t("and") }}
            <a
              href="https://www.feedbear.com/terms"
              title="Terms of Service"
              target="_blank"
              class="text-neutral-600 underline hover:no-underline hover:text-project-500"
              >{{ $t("Terms and Conditions") }}</a
            >
          </div>
        </div>
      </vue-tribute>
    </div>
    <div
      v-if="isLoading && !isError"
      class="flex flex-col mt-6 mb-8 justify-center items-center"
    >
      <Spinner border-color="#6b7280" />
      <p class="text-sm text-gray-500 mt-8">
        Please hold on, we are creating some replies.
      </p>
    </div>
    <div
      id="suggestions"
      v-if="!isLoading && !isError"
      style="opacity: 1; transition: opacity 1s"
    >
      <p
        class="text-md ml-1 text-gray-500 mt-4 mb-2"
        v-if="this.accept && this.reject && this.elaborate && this.describe"
      >
        Here are a few suggestions for your reply, click on the one that sounds
        more appropriate to edit before posting.
      </p>
      <div class="mt-2 mb-2 flex flex-row" v-if="this.accept">
        <p class="text-2xl p-5 whitespace-nowrap">&#128077;</p>
        <p
          id="accept"
          class="p-3 bg-white text-gray-500 text-md rounded-md border-2 hover:bg-gray-200 transition ease-in-out duration-150 cursor-pointer changeFill"
          @click.prevent="select('accept')"
        >
          {{ this.accept }}
        </p>
      </div>
      <div class="mt-2 mb-2 flex flex-row" v-if="this.elaborate">
        <p class="text-2xl p-5 whitespace-nowrap">&#129300;</p>
        <p
          id="elaborate"
          class="p-3 bg-white text-gray-500 text-md rounded-md border-2 hover:bg-gray-200 transition ease-in-out duration-150 cursor-pointer changeFill"
          @click.prevent="select('elaborate')"
        >
          {{ this.elaborate }}
        </p>
      </div>
      <div class="mt-2 mb-2 flex flex-row" v-if="this.describe">
        <p class="text-2xl p-5 whitespace-nowrap">&#128591;</p>
        <p
          id="describe"
          class="p-3 bg-white text-gray-500 text-md rounded-md border-2 hover:bg-gray-200 transition ease-in-out duration-150 cursor-pointer changeFill"
          @click.prevent="select('describe')"
        >
          {{ this.describe }}
        </p>
      </div>
    </div>
    <transition name="fade" v-if="!isLoading && isError">
      <div class="alert alert--bad rounded-lg mt-4 mb-4">
        <p class="-ml-1">
          {{ "There was an error generating the text." }}
          <a
            href="#"
            class="text-white underline"
            @click.prevent="suggestComment()"
            >{{ "Please try again..." }}</a
          >
        </p>
      </div>
    </transition>
    <transition name="popdown">
      <div v-if="editComment">
        <div class="flex items-center flex-wrap mt-2" v-if="!uploadImage">
          <a
            :href="file.url"
            target="_blank"
            v-for="(file, index) in commentData.attached_files"
            :key="index"
            class="w-20 h-20 mr-2 shadow-lg border border-neutral-200 rounded-md transform hover:scale-105 hover:shadow-xl transition duration-150 ease-in-out"
          >
            <img
              :src="file.preview_url"
              class="object-cover h-20 w-full rounded-md"
              alt
            />
          </a>
        </div>
        <div class="comment_button items-start justify-between md:mt-4">
          <div>
            <FileUpload
              name="comment[attachments][]"
              class="mb-3 md:mt-2"
              noun="images"
              :attach-file="commentData.attached_files"
              :upy-id="'uppyTrigger' + commentData.id"
            >
            </FileUpload>
          </div>
          <a
            href="#"
            class="flex items-center text-neutral-600 uppercase text-sm font-bold tracking-wider hover:no-underline hover:text-project-500 float-right"
            @click.prevent="cancelEditComment()"
            type="button"
          >
            {{ $t("Cancel") }}
          </a>
          <a
            href="#"
            class="flex items-center text-neutral-600 uppercase text-sm font-bold tracking-wider mr-2 hover:no-underline hover:text-project-500 float-right"
            @click.prevent="saveEditComment()"
            type="button"
          >
            {{ $t("Save") }}
          </a>
        </div>
      </div>
      <div
        v-else
        class="comment_button flex items-start justify-between mt-2 md:mt-4 gap-1"
        id="comment-file-upload"
      >
        <FileUpload
          name="comment[attachments][]"
          class="mb-3 md:mt-2 mr-4"
          :noun="$t('images')"
          :upy-id="id"
        >
        </FileUpload>
        <div class="flex items-start">
          <button
            id="suggest-comment"
            class="btn mr-2 mb-2 xs:w-full justify-center items-justify"
            @click.prevent="suggestComment()"
            v-if="
              !accept &&
              !reject &&
              !elaborate &&
              !describe &&
              this.currentProjectAdmin &&
              this.commentsSuggestions == 'true'
            "
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 544 512"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5 mr-2"
            >
              <path
                d="M234.7 42.7L192 64L234.7 85.3L256 128L277.3 85.3L320 64L277.3 42.7L256 0L234.7 42.7ZM384.4 192.4L351.6 159.6L432 79.2L464.8 112L384.4 192.4ZM96 32L64 96L0 128L64 160L96 224L128 160L192 128L128 96L96 32ZM416 352L352 384L416 416L448 480L480 416L544 384L480 352L448 288L416 352ZM144 512L183.6 472.4L504.4 151.6L544 112L504.4 72.4L471.6 39.6L432 0L392.4 39.6L71.6 360.4L32 400L71.6 439.6L104.4 472.4L144 512Z"
                fill="black"
              />
            </svg>
            {{ "Reply using AI" }}
          </button>
          <button
            @click.prevent="ssoPopUp()"
            class="btn-main show_button_below"
            v-if="
              !currentUser &&
              this.projectSsoOnly === 'true' &&
              customWebsiteHref != ''
            "
            id="comment-btn"
          >
            {{ $t("Comment") }}
          </button>
          <button
            @click="submitComment()"
            class="btn-main show_button_below"
            v-else
            id="comment-btn"
          >
            {{ $t("Comment") }}
          </button>
        </div>
      </div>
    </transition>
    <Dialog v-model="ssoLoginPopup">
      <div class="px-4 pb-4">
        <div class="text-center font-semibold">
          {{ $t("Log in to proceed") }}
        </div>
        <div class="p-4 text-center">
          {{ $t("You must be logged in to submit feedback") }}, <br />{{
            $t("click the button below to login")
          }}
        </div>
        <div class="flex justify-center">
          <a
            :href="this.customWebsiteHref"
            @click="ssoAddComment()"
            class="btn-main btn-SignSso"
          >
            {{ $t("Sign up") }} / {{ $t("Log in") }}
          </a>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import VueTribute from "vue-tribute";
import FileUpload from "./components/file_upload";
import Dialog from "./components/dialog";
import EventBus from "event_bus";
import Spinner from "./projects/spinner_big";

var autosize = require("autosize");

export default {
  props: [
    "post-id",
    "comment-id",
    "current-user",
    "discussion-users",
    "parent-comment-id",
    "time-now",
    "edit-comment",
    "comment-data",
    "id",
    "added-new-comment",
    "project-sso-only",
    "custom-website-href",
    "board-slug",
    "current-project-admin",
    "show-ai-message",
    "subscription-valid",
    "comments-suggestions",
  ],
  data: function () {
    return {
      message: this.editComment ? this.commentData.body : "",
      name: "",
      email: "",
      error: "",
      emailError: "",
      user_ip_address: "",
      remark: "",
      screenWidth: null,
      agreed_with_privacy_policy: false,
      uploadImage: false,
      ssoLoginPopup: false,
      tributeOptions: {
        values: this.discussionUsers || [],
        // function called on select that returns the content to insert
        selectTemplate: (item) => {
          const mention_user =
            this.discussionUsers
              .map((a) => a.name)
              .filter((x) => x == item.original.name).length > 1
              ? item.original.handle
              : item.original.name;
          return (
            '<span contenteditable="false" class="mention">@' +
            mention_user +
            "</span>"
          );
        },

        // template for displaying item in menu
        menuItemTemplate: function (item) {
          return this.collection[0].values
            .map((a) => a.name)
            .filter((x) => x == item.original.name).length > 1
            ? "@" + item.original.handle
            : "@" + item.original.name;
        },

        // what to look for when typing
        lookup: function (item) {
          return item.name + item.handle;
        },
        menuItemLimit: 5,
      },
      accept: this.accept,
      reject: this.reject,
      elaborate: this.elaborate,
      describe: this.describe,
      isLoading: false,
      isError: false,
    };
  },
  components: {
    Avatar,
    VueTribute,
    FileUpload,
    Dialog,
    Spinner,
  },

  mounted() {
    fetch("https://api.ipify.org?format=json")
      .then((x) => x.json())
      .then(({ ip }) => {
        this.user_ip_address = ip;
      });
    this.screenWidth = window.innerWidth;
    EventBus.$on("uploadedImage", (data) => {
      this.uploadImage = data;
    });
    if (this.addedNewComment) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
    var commentData = JSON.parse(window.localStorage.getItem("comment"));
    if (commentData && commentData.post_id == this.postId) {
      this.message = commentData.message;
      this.agreed_with_privacy_policy = commentData.agreed_with_privacy_policy;
      localStorage.removeItem("comment");
    }
  },
  methods: {
    submitComment: function () {
      if (this.remark != "") return;

      var data = new FormData();
      var comment_post_url = "";

      var parent_id = this.commentId;

      let error =
        this.$refs.messageAreaInput.innerText == ""
          ? `${this.$t("Comment body is empty")}.`
          : "";
      if (!this.currentUser) {
        if (this.email) {
          error =
            error +
            (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
              this.email
            )
              ? ""
              : ` ${this.$t("Invalid email address")}`);
        }
        error =
          error + (this.name == "" ? ` ${this.$t("Name is empty")},` : "");
        error =
          error + (this.email == "" ? ` ${this.$t("Email is empty")},` : "");
        error =
          error +
          (!this.agreed_with_privacy_policy
            ? ` ${this.$t(
                "You'll need to accept our Privacy Policy and Terms to post a comment"
              )}`
            : "");
      }
      if (error) {
        this.error = error;
        return;
      }
      let allSpans = this.$refs.messageAreaInput.getElementsByTagName("span");
      let allSpansArray = [...allSpans];
      let allMentionUsers = allSpansArray.map((e) => e.innerText);
      data.append("comment[body]", this.$refs.messageAreaInput.innerText);
      data.append("mention_users", allMentionUsers);
      data.append("user_ip_address", this.user_ip_address);
      data.append("comment[name]", this.name);
      data.append("comment[email]", this.email);
      data.append("comment[agreed_with_privacy_policy]", this.timeNow);
      let attachments = Array.from(
        this.$refs.form.querySelectorAll(
          "input[name='comment[attachments][]']"
        ),
        (el) => el.value
      );

      attachments.forEach((file) => {
        data.append("comment[attachments][]", file);
      });

      if (this.parentCommentId !== 0) {
        parent_id = this.parentCommentId;
      }

      if (parent_id) {
        comment_post_url =
          "/boards/" +
          this.board_slug +
          "/posts/" +
          this.postId +
          "/comments/" +
          parent_id +
          "/comments";
      } else {
        comment_post_url =
          "/boards/" + this.board_slug + "/posts/" + this.postId + "/comments";
      }

      Rails.ajax({
        url: comment_post_url,
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.emailError = data.email_used;
          if (!this.emailError) {
            if (parent_id) {
              data.parent_comment_id = parent_id;

              this.$emit("newNestedComment", data);
              EventBus.$emit("newNestedComment", data);
            } else {
              this.$emit("newComment", data);
            }
            self = this;
            self.$refs.messageAreaInput.innerText = "";
            this.name = "";
            this.email = "";
            this.error = "";
            EventBus.$emit("imagesUpload", true);
          }
          document.getElementById("suggestions").style.opacity = "0";
          setTimeout(
            () => document.getElementById("suggestions").remove(),
            700
          );
        },
        error: (data, status) => {
          this.emailError = data.email_used;
        },
      });
    },
    clickLoginBtn: function () {
      const login = document.getElementById("auth-login-btn");
      login.click();
    },
    saveEditComment: function () {
      let error = this.message == "" ? "Comment body is empty." : "";
      if (error) {
        this.error = error;
        return;
      }
      var data = new FormData();
      let attachments = Array.from(
        this.$refs.form.querySelectorAll(
          "input[name='comment[attachments][]']"
        ),
        (el) => el.value
      );

      attachments.forEach((file) => {
        data.append("comment[attachments][]", file);
      });

      data.append("comment[body]", this.message);
      var pinUrl =
        "/boards/" +
        this.boardSlug +
        "/posts/" +
        this.postId +
        "/comments/" +
        this.commentData.id;

      Rails.ajax({
        url: pinUrl,
        type: "PUT",
        data: data,
        dataType: "json",
        processData: true,
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.$emit("updateCommentData", data);
          this.error = "";
        },
        error: (data, status) => {
          this.error = data;
          console.log(data);
        },
      });
    },
    cancelEditComment: function () {
      this.$emit("editHide", false);
    },
    ssoPopUp() {
      this.ssoLoginPopup = true;
    },
    ssoAddComment() {
      const comment_data = {
        message: this.message,
        agreed_with_privacy_policy: this.agreed_with_privacy_policy,
        post_id: this.postId,
      };
      window.localStorage.setItem("comment", JSON.stringify(comment_data));
      this.ssoLoginPopup = false;
    },
    resize: function () {
      autosize(this.$refs.messageAreaInput);
    },
    suggestComment() {
      this.isLoading = true;
      this.isError = false;
      Rails.ajax({
        url: `/boards/${this.boardSlug}/posts/${this.postId}/comments/suggest`,
        type: "GET",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isLoading = false;
          this.isError = false;
          this.accept = data["positive_reply"];
          this.reject = data["thankful_but_non_priority_reply"];
          this.elaborate = data["reply_requesting_more_information"];
          this.describe = data["reply_explaining_the_process"];
        },
        error: (data, status) => {
          console.log(data);
          this.isLoading = false;
          this.isError = true;
        },
      });
    },
    closeAIMessage() {
      Rails.ajax({
        url: `/comments/remove_ai_message`,
        type: "POST",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          document.getElementById("ai-message").remove();
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    select(type) {
      if (type == "accept") {
        document.getElementById("accept").classList.remove("bg-white");
        document.getElementById("accept").classList.add("bg-black");
        document.getElementById("accept").classList.add("bg-opacity-5");

        document.getElementById("elaborate").classList.remove("bg-black");
        document.getElementById("elaborate").classList.remove("bg-opacity-5");
        document.getElementById("elaborate").classList.add("bg-white");

        document.getElementById("describe").classList.remove("bg-black");
        document.getElementById("describe").classList.remove("bg-opacity-5");
        document.getElementById("describe").classList.add("bg-white");

        document.getElementById("new_comment").textContent = this.accept;
      }

      if (type == "elaborate") {
        document.getElementById("elaborate").classList.remove("bg-white");
        document.getElementById("elaborate").classList.add("bg-black");
        document.getElementById("elaborate").classList.add("bg-opacity-5");

        document.getElementById("accept").classList.remove("bg-black");
        document.getElementById("accept").classList.remove("bg-opacity-5");
        document.getElementById("accept").classList.add("bg-white");

        document.getElementById("describe").classList.remove("bg-black");
        document.getElementById("describe").classList.remove("bg-opacity-5");
        document.getElementById("describe").classList.add("bg-white");

        document.getElementById("new_comment").textContent = this.elaborate;
      }

      if (type == "describe") {
        document.getElementById("describe").classList.remove("bg-white");
        document.getElementById("describe").classList.add("bg-black");
        document.getElementById("describe").classList.add("bg-opacity-5");

        document.getElementById("accept").classList.remove("bg-black");
        document.getElementById("accept").classList.remove("bg-opacity-5");
        document.getElementById("accept").classList.add("bg-white");

        document.getElementById("elaborate").classList.remove("bg-black");
        document.getElementById("elaborate").classList.remove("bg-opacity-5");
        document.getElementById("elaborate").classList.add("bg-white");

        document.getElementById("new_comment").textContent = this.describe;
      }
    },
  },
};
</script>

<style scoped>
.offset {
  margin-left: calc(36px + 1rem);
}

@media only screen and (max-width: 500px) and (min-width: 320px) {
  #suggest-comment {
    width: 100%;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  .show_button_below {
    width: 100%;
    text-align: center !important;
    display: block;
  }
  .comment_button {
    display: block;
  }
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: #808080;
  background-color: transparent;
}
</style>

<style>
.mention {
  color: #009be5 !important;
  background-color: #dbf2ff !important;
  padding: 0 4px;
}
</style>
