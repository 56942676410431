<template>
  <span>
    <a
      href="#"
      @click.prevent="dialogVisible = true"
      class="text-neutral-700 hover:text-red-500"
    >
      <svg
        class="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
        ></path>
      </svg>
    </a>

    <Dialog title="Confirmation" v-model="dialogVisible">
      <div class="px-4 py-4">
        <h2 class="text-lg text-neutral-800">
          You are about to permanently delete board
          <strong>{{ originalBoard.name }}</strong>
        </h2>

        <p class="mt-4">
          Transfer ideas from {{ originalBoard.name }} to board:
        </p>

        <div class="mt-1">
          <div class="relative">
            <select
              name="transferboards"
              id="transfer-boards"
              v-model="selectedOption"
              class="select"
            >
              <option :value="0">Don't transfer (ideas will be deleted)</option>
              <optgroup label="Remaining boards">
                <option
                  v-for="board in allBoards"
                  :key="board.id"
                  :value="board.id"
                >
                  {{ board.name }}
                </option>
              </optgroup>
            </select>
            <svg
              class="w-6 h-6 absolute right-0 top-0 mt-2 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>

        <div class="flex justify-end items-center mt-4 space-x-2">
          <button class="btn" @click="dialogVisible = false">Cancel</button>
          <button
            class="btn-main bg-red-500 border-red-500 hover:border-red-500 hover:text-red-500"
            type="primary"
            @click="deleteBoard"
          >
            Delete board
            {{ selectedOption === 0 ? "and its ideas" : "and transfer ideas" }}
          </button>
        </div>
      </div>
    </Dialog>
  </span>
</template>

<script>
import trashIcon from "images/trash.svg";
import Dialog from "../components/dialog";

export default {
  props: ["original-board", "all-boards"],
  data: function () {
    return {
      dialogVisible: false,
      selectedOption: 0,
      trashIcon,
    };
  },
  components: {
    trashIcon,
    Dialog,
  },
  methods: {
    deleteBoard: function () {
      Rails.ajax({
        url:
          window.location.origin +
          "/boards/" +
          this.originalBoard.slug +
          "?transfer=" +
          this.selectedOption,
        type: "DELETE",
        processData: true,
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          console.log("Deleted", data);
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
  },
};
</script>

<style scoped></style>
