<template>
  <div>
    <div class="alert_box flex flex-row mb-4">
      <div class="alert_border"></div>
      <div class="flex flex-row">
        <div class="alert_vector">
            <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 24H28L14 0L0 24ZM15.2727 20.2105H12.7273V17.6842H15.2727V20.2105ZM15.2727 15.1579H12.7273V10.1053H15.2727V15.1579Z" fill="#EAAA06"/>
            </svg>
        </div>
        <div class="my-4">
          <h3 class="text-md font-bold text-neutral-800 relative flex-1">{{title}}</h3>
          <p>{{description}}</p><a :href="link" class="link">{{ click }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "PlanAlert",
  props: {
    title: String,
    description: String,
    link: String,
    click: String,
  },
}
</script>

<style scoped>
  .alert_box{
    width: 100%;
    left: 40px;
    top: 71px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    border-radius: 3px;
  }
  .alert_border {
    width: 5px;
    left: 40px;
    top: 71px;
    background: #EAAA06;
    border-radius: 3px 0px 0px 3px;
  }
  .alert_vector {
    padding: 25px 28px 25px 32px;
  }
  .link{
    cursor: pointer;
    color: red;
  }
</style>
