<template>
  <span class="relative">
    <slot></slot>
    <div
      v-if="realcount !== localCount"
      class="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full animate-pulse -mr-2"
    ></div>
  </span>
</template>

<script>
export default {
  props: ["current-posts-count"],
  data: function () {
    return {
      get localCount() {
        return localStorage.getItem("feedbearChangelogPostsSeen") || "0";
      },
      set localCount(value) {
        localStorage.setItem("feedbearChangelogPostsSeen", value);
      },

      realcount: this.currentPostsCount,
    };
  },
};
</script>

<style></style>
