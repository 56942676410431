<template>
  <span></span>
</template>

<script>
import EventBus from "event_bus";

export default {
  props: ["current-posts-count"],
  data: function() {
    return {};
  },

  mounted: function() {
    localStorage.setItem("feedbearChangelogPostsSeen", this.currentPostsCount);
    EventBus.$emit("changelogDotUpdated");
  }
};
</script>

<style></style>
