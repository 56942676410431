<template>
  <div class="w-full" ref="wrapper">
    <div
      class="w-full"
      ref="button"
      role="button"
      tabindex="0"
    >
      <slot name="button"></slot>
    </div>
    <transition name="popdown">
      <div
        class="text-base w-full"
        v-if="isOpen"
        ref="items"
      >
        <slot name="items"></slot>
        <div ref="append" class="bg-white rounded-b-md">
          <slot name="append"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: ["align", "autoclose", "mTopClass"],

  data() {
    return {
      isOpen: true,
      alignment: this.align || "left",
    };
  },

  mounted: function(){
    // open
    const burger = document.querySelectorAll('.navbar-burger');
    const menu = document.querySelectorAll('.navbar-menu');

    if (burger.length && menu.length) {
      for (var i = 0; i < burger.length; i++) {
        burger[i].addEventListener('click', function () {
          for (var j = 0; j < menu.length; j++) {
            menu[j].classList.toggle('hidden');
          }
        });
      }
    }

    // close
    const close = document.querySelectorAll('.navbar-close');
    const backdrop = document.querySelectorAll('.navbar-backdrop');

    if (close.length) {
      for (var i = 0; i < close.length; i++) {
        close[i].addEventListener('click', function () {
          for (var j = 0; j < menu.length; j++) {
            menu[j].classList.toggle('hidden');
          }
        });
      }
    }

    if (backdrop.length) {
      for (var i = 0; i < backdrop.length; i++) {
        backdrop[i].addEventListener('click', function () {
          for (var j = 0; j < menu.length; j++) {
            menu[j].classList.toggle('hidden');
          }
        });
      }
    }
  },

  computed: {
    alignmentClass() {
      return this.alignment + "-0";
    },
  },

  methods: {
    open: function () {
      if (this.isOpen) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
        window.addEventListener("click", this.closeDropdown, true);
        this.$nextTick(() => {
          this.$refs.items.querySelector(".hidden").classList.remove("hidden");
          this.$refs.append.querySelector(".hidden").classList.remove("hidden");
        });
      }
    },

    closeDropdown: function (event) {
      if (this.autoclose) {
        this.isOpen = false;
        window.removeEventListener("click", this.closeDropdown, true);
      } else {
        if (
          this.$refs.wrapper &&
          this.$refs.wrapper.contains(event.target) === false
        ) {
          this.isOpen = false;
          window.removeEventListener("click", this.closeDropdown, true);
        }
      }
    },
  },
};

</script>

<style scoped>
.safe-height {
  max-height: 70vh;
}
</style>
