<template>
  <div class="mt-2">
    <div
      class="bg-yellow-200 text-neutral-700 text-sm px-3 py-3 rounded-md flex items-center"
      v-if="!hideTagHelp"
    >
      <svg
        class="w-6 h-6 mr-2"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        ></path>
      </svg>
      <span>
        Tags are visible only to project admins.
        <a
          href="https://feedbear.helpscoutdocs.com/article/7-using-tags"
          data-beacon-article-sidebar="5d7786572c7d3a7e9ae0f4cd"
          target="_blank"
          >Learn more</a
        >.
      </span>
      <a
        href="#"
        class="ml-1 text-yellow-700 text-opacity-50 hover:text-opacity-100 hover:text-red-500 self-start"
        @click.prevent="dismissTagHelp"
      >
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </a>
    </div>

    <div class="flex flex-wrap items-center justify-start mt-2">
      <Tag
        v-for="tag in allTags"
        :key="tag.id"
        @close="handleClose(tag)"
        class="my-1 mr-2"
      >
        {{ tag.name }}
      </Tag>
      <a
        href="#"
        v-if="!inputVisible"
        class="inline-flex items-center text-neutral-800 hover:no-underline hover:text-project-500"
        @click.prevent="showInput"
      >
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span>Add tag</span>
      </a>
    </div>
    <div
      v-if="error"
      class="error_show mt-2"
      id="tag-error"
      >{{this.error}}
    </div>
    <div class="relative">
      <transition name="switch" appear>
        <input
          class="input mt-2"
          v-if="inputVisible"
          v-model="inputValue"
          ref="tagInput"
          placeholder="Type to search or add new..."
          @keyup.enter="handleInputConfirm()"
          @keyup.esc="handleInputCancel()"
          @blur="handleBlur"
        />
      </transition>
      <transition name="show" appear>
        <ul
          class="absolute z-10 bg-white rounded-b-md border border-neutral-200 shadow-md w-full py-2"
          v-if="tagSuggestions.length > 0 && inputVisible"
        >
          <li
            class=""
            v-for="suggestionItem in tagSuggestions"
            :key="suggestionItem.id"
          >
            <a
              href="#"
              class="flex items-center w-full py-3 px-3 text-neutral-800 text-sm hover:no-underline hover:bg-neutral-100 transition duration-150 ease-in-out"
              @click.prevent="
                pickSuggestion(suggestionItem.id, suggestionItem.name)
              "
            >
              <div>{{ suggestionItem.name }}</div>
            </a>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import Tag from "../components/tag";

import throttle from "lodash/throttle";

export default {
  props: ["tags", "tags-url"],
  data: function () {
    return {
      allTags: this.tags,
      inputVisible: false,
      inputValue: "",
      tagSuggestions: [],
      hideTagHelp: false,
      error: ""
    };
  },

  components: {
    Tag,
  },

  mounted: function () {
    if (window.localStorage.getItem("hideFeedBearTagHelp")) {
      this.hideTagHelp = true;
    }
  },

  methods: {
    dismissTagHelp() {
      this.hideTagHelp = true;
      window.localStorage.setItem("hideFeedBearTagHelp", true);
    },

    handleClose(tag) {
      this.destroyTag(tag);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.tagInput.focus();
        this.getTagSuggestions();
      });
    },

    postTag: function (name) {
      var params = new FormData();
      params.append("tag_name", name);

      Rails.ajax({
        url: this.tagsUrl,
        type: "POST",
        data: params,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          if (data.name){
            this.allTags.push(data);
            this.error = "";
          }else{
            this.error = data.error;
          }
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },

    destroyTag: function (tag) {
      Rails.ajax({
        url: this.tagsUrl + "/" + tag.id,
        type: "DELETE",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.allTags.splice(this.allTags.indexOf(tag), 1);
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },

    handleInputConfirm() {
      if (this.inputValue) {
        this.postTag(this.inputValue);
      }

      this.inputVisible = false;
      this.inputValue = "";
    },

    handleInputCancel() {
      this.inputVisible = false;
      this.inputValue = "";
    },

    handleBlur(event) {
      if (this.inputValue || event.relatedTarget) {
      } else {
        this.inputVisible = false;
      }
    },

    getTagSuggestions: throttle(function () {
      var original_post = this.originalPost;

      Rails.ajax({
        url: this.tagsUrl + "?search_term=" + this.inputValue,
        type: "GET",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.tagSuggestions = data;
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    }, 500),

    pickSuggestion: function (tag_id, tag_name) {
      this.postTag(tag_name);
      this.inputVisible = false;
    },
  },

  watch: {
    inputValue: function () {
      this.getTagSuggestions();
    },
  },
};
</script>

<style scoped>
.switch-enter {
  position: absolute;
  opacity: 0;
}

.switch-leave-to {
  position: absolute;
  opacity: 0;
}

.switch-enter-to,
.switch-leave {
  position: relative;
  opacity: 1;
}

.switch-enter-active,
.switch-leave-active {
  transition: opacity 200ms ease-out;
}

.show-enter,
.show-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.show-enter-to,
.show-leave {
  opacity: 1;
  transform: translateY(0);
}

.show-enter-active,
.show-leave-active {
  transition: opacity, transform 200ms ease-out;
}
</style>
