<template>
  <div class="sso-webhook-config">
    <div>
      <div class="flex flex-row">
        <div class="flex flex-row px-2 dataInstruct" @click="instrectionData"  :class="openIntrectionTab? 'borderSelectLine' : 'borderLine'">
          <div>
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path></svg>
          </div>
          <div class="ml-3 cursor-pointer">
            <span class="span-class" :class="screenWidth>440 ? 'whitespace-nowrap' : '' "> Read instructions</span>
          </div>
        </div>
        <div class="flex flex-row px-2" @click="codeData" :class="openCodeInstructionTab? 'borderSelectLine' : 'borderLine'">
          <div>
           <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"></path></svg>
          </div>
          <div class="ml-3 cursor-pointer">
            <span class="span-class" :class="screenWidth>440 ? 'whitespace-nowrap' : '' "> Code samples</span>
          </div>
        </div>
        <div class="borderLine" style="width:65%;" v-if="screenWidth>440  "></div>
      </div>
    </div>
    <div class="mt-4 mb-4" v-if="openIntrectionTab">
      <div>
        <p>Single Sign-On (SSO), allows your users to log in to FeedBear using their existing account on your website. Users will be prompted to login on your website, then automatically redirected to your FeedBear project. Below you will find more details on how to set up SSO.</p>
        <div class="mt-3">
          <div><h4 class="font-semibold text-neutral-700">1. Copy your FeedBear Private Key</h4></div>
          <div><p>Copy your private key to a safe place as it will be used to generate a secure JWT token on your website.</p></div>
        </div>
        <div class="mt-3">
          <div><h4 class="font-semibold text-neutral-700">2. Install a JWT library on your website</h4></div>
          <div><p>We use JSON Web Tokens (JWT) to authenticate users, the token is composed of a name and email, encrypted and signed on your server, allowing FeedBear to verify that the token comes from a trusted source without exposing your user information.</p></div>
        </div>
        <div class="mt-3">
          <div ><h4 class="font-semibold text-neutral-700">3. Validate the user login </h4></div>
          <div><p>Once the user is sent to your website login URL, you will need to provide a login form, and then validate the details provided in that form. The next steps described below should take place within your login validation logic.</p></div>
        </div>
        <div class="mt-3">
          <div><h4 class="font-semibold text-neutral-700">4. Generate the JWT Token</h4></div>
          <div><p>If the user login is successful, you will now use his name and email to generate the JWT token using the JWT library you previously installed.</p></div>
        </div>
        <div class="mt-3">
          <div>
            <h4 class="font-semibold text-neutral-700">5. Redirect the user back to FeedBear</h4>
          </div>
          <div>
            <p class="break-words">
              With the JWT token generated, all you need to do is redirect the user back to
              https://<span v-if="customDomain">{{this.customDomain}}</span><span v-else>{{this.subDomain}}</span>/sso/auth?payload={{<span>GENERATED TOKEN</span>}}</span> so we can log him in.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="openInstallTab">
      <div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <div class="mt-5">
              <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
                <li class="-mb-px mr-1 last:mr-0 text-center">
                  <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal mt-1" v-on:click="toggleInstallTabs(1)" v-bind:class="{'btn-main': openInstallTab !== 1, 'active-tab': openInstallTab === 1}">
                    Node.js
                  </a>
                </li>
                <li class="-mb-px mr-1 last:mr-0 text-center">
                  <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal mt-1" v-on:click="toggleInstallTabs(2)" v-bind:class="{'btn-main': openInstallTab !== 2, 'active-tab': openInstallTab === 2}">
                    C#
                  </a>
                </li>
                <li class="-mb-px mr-1 last:mr-0 text-center">
                  <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal mt-1" v-on:click="toggleInstallTabs(3)" v-bind:class="{'btn-main': openInstallTab !== 3, 'active-tab': openInstallTab === 3}">
                    Go
                  </a>
                </li>
                <li class="-mb-px mr-1 last:mr-0 text-center">
                  <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal mt-1" v-on:click="toggleInstallTabs(4)" v-bind:class="{'btn-main': openInstallTab !== 4, 'active-tab': openInstallTab === 4}">
                    Java
                  </a>
                </li>
                <li class="-mb-px mr-1 last:mr-0 text-center">
                  <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal mt-1" v-on:click="toggleInstallTabs(5)" v-bind:class="{'btn-main': openInstallTab !== 5, 'active-tab': openInstallTab === 5}">
                    PHP
                  </a>
                </li>
                <li class="-mb-px mr-1 last:mr-0 text-center">
                  <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal mt-1" v-on:click="toggleInstallTabs(6)" v-bind:class="{'btn-main': openInstallTab !== 6, 'active-tab': openInstallTab === 6}">
                    Python
                  </a>
                </li>
                <li class="-mb-px mr-1 last:mr-0 text-center">
                  <a class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal mt-1" v-on:click="toggleInstallTabs(7)" v-bind:class="{'btn-main': openInstallTab !== 7, 'active-tab': openInstallTab === 7}">
                    Ruby
                  </a>
                </li>
              </ul>
            </div>
            <h4 class="mt-5 font-semibold text-neutral-700">Install a JWT library</h4>
            <p>
              We use JSON Web Tokens to securely authenticate your users.
              First, install the appropriate JWT library for your server.
            </p>
            <div class="">
              <div class="">
                <div class="tab-content tab-space">
                  <div v-bind:class="{'hidden': openInstallTab !== 1, 'block': openInstallTab === 1}">
                    <prism-editor class="prism-code-editor rounded" v-model="installCodes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                  </div>
                  <div v-bind:class="{'hidden': openInstallTab !== 2, 'block': openInstallTab === 2}">
                    <prism-editor class="prism-code-editor rounded" v-model="installCodes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                  </div>
                  <div v-bind:class="{'hidden': openInstallTab !== 3, 'block': openInstallTab === 3}">
                    <prism-editor class="prism-code-editor rounded" v-model="installCodes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                  </div>
                  <div v-bind:class="{'hidden': openInstallTab !== 4, 'block': openInstallTab === 4}">
                    <prism-editor class="prism-code-editor rounded" v-model="installCodes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                  </div>
                  <div v-bind:class="{'hidden': openInstallTab !== 5, 'block': openInstallTab === 5}">
                    <prism-editor class="prism-code-editor rounded" v-model="installCodes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                  </div>
                  <div v-bind:class="{'hidden': openInstallTab !== 6, 'block': openInstallTab === 6}">
                    <prism-editor class="prism-code-editor rounded" v-model="installCodes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                  </div>
                  <div v-bind:class="{'hidden': openInstallTab !== 7, 'block': openInstallTab === 7}">
                    <prism-editor class="prism-code-editor rounded" v-model="installCodes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                  </div>
                </div>
              </div>
            </div>
            <h4 class="mt-6 font-semibold text-neutral-700">Generate tokens on your server</h4>
            <div class="flex flex-wrap">
              <div class="w-full">
                <div class="">
                  <div class="pb-5 pt-2 flex-auto">
                    <div class="tab-content tab-space">
                      <div v-bind:class="{'hidden': openInstallTab !== 1, 'block': openInstallTab === 1}">
                        <prism-editor class="prism-code-editor rounded" v-model="codes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                      </div>
                      <div v-bind:class="{'hidden': openInstallTab !== 2, 'block': openInstallTab === 2}">
                        <prism-editor class="prism-code-editor rounded" v-model="codes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                      </div>
                      <div v-bind:class="{'hidden': openInstallTab !== 3, 'block': openInstallTab === 3}">
                        <prism-editor class="prism-code-editor rounded" v-model="codes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                      </div>
                      <div v-bind:class="{'hidden': openInstallTab !== 4, 'block': openInstallTab === 4}">
                        <prism-editor class="prism-code-editor rounded" v-model="codes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                      </div>
                      <div v-bind:class="{'hidden': openInstallTab !== 5, 'block': openInstallTab === 5}">
                        <prism-editor class="prism-code-editor rounded" v-model="codes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                      </div>
                      <div v-bind:class="{'hidden': openInstallTab !== 6, 'block': openInstallTab === 6}">
                        <prism-editor class="prism-code-editor rounded" v-model="codes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                      </div>
                      <div v-bind:class="{'hidden': openInstallTab !== 7, 'block': openInstallTab === 7}">
                        <prism-editor class="prism-code-editor rounded" v-model="codes" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>

      </div>
    </div>
  </div>
</template>

<script>
import 'highlight.js/styles/github.css';
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
export default {
   props:{
    subDomain:String,
    customDomain: String,
    payloadSsoToken: String,
  },
	data(){
		return{
			openIntrectionTab: true,
    	openCodeInstructionTab: false,
      openInstallTab: 1,
      inputVerifyToken: '',
      showLoader: false,
      screenWidth: '',
      codes: "",
      installCodes:"",
      lineNumbers: true,
		}
	},
  mounted () {
    this.screenWidth = window.innerWidth;
    // this code indendation is wrong because of editor
    this.codes = this.nodeCodeSample();
    this.installCodes="npm install --save jsonwebtoken";
  },
	methods: {
    toggleInstallTabs: function(tabNumber){
      this.openInstallTab  = tabNumber;
      if (this.openInstallTab == 1) {
        this.codes = this.nodeCodeSample();
        this.installCodes = "npm install --save jsonwebtoken";
      }
      else if (this.openInstallTab == 2) {
        this.codes='public class FeedBearTokenCreator\n{\n\tprivate static string PrivateKey = "YOUR_PRIVATE_SSO_KEY";\n\n\tpublic static string CreateToken(User user)\n\t{\n\t\tvar securityKey = new SymmetricSecurityKey(Encoding.UTF8.GetBytes(PrivateKey)); \n\t\tvar credentials = new SigningCredentials(securityKey, "HS256");\n\n\t\tvar header = new JwtHeader(credentials);\n\t\tvar payload = new JwtPayload\n\t\t{\n\t\t\t{ "email": user.email },\n\t\t\t{ "name": user.name },\n\t\t}\n\n\t\tvar securityToken = new JwtSecurityToken(header, payload);\n\t\tvar handler = new JwtSecurityTokenHandler();\n\n\t\treturn handler.WriteToken(secToken);\n\t}\n};'
        this.installCodes="# See instructions here:\nhttps://www.nuget.org/packages/System.IdentityModel.Tokens.Jwt/"
      }
      else if (this.openInstallTab == 3) {
        this.codes='import (\n\t"github.com/dgrijalva/jwt-go"\n)\n\nconst PrivateKey = "YOUR_PRIVATE_SSO_KEY"\n\nfunc createFeedBearToken(user map[string]interface{}) (string, error) {\n\ttoken := jwt.NewWithClaims(jwt.SigningMethodHS256, jwt.MapClaims{\n\t\t"email": user["email"],\n\t\t"name": user["name"],\n\t})\n\treturn token.SignedString([]byte(PrivateKey));\n}'
        this.installCodes="go get github.com/dgrijalva/jwt-go"

      }
      else if (this.openInstallTab == 4) {
        this.codes='import java.util.HashMap; \n\nimport io.jsonwebtoken.Jwts;\nimport io.jsonwebtoken.SignatureAlgorithm;\n\npublic class FeedBearTokenCreator {\n\tprivate static final String PrivateKey = "YOUR_PRIVATE_SSO_KEY";\n\n\tpublic static String createToken(User user) throws Exception {\n\t\tHashMap<String, Object> userData = new HashMap<String, Object>();\n\t\tuserData.put("email", user.email);\n\t\tuserData.put("name", user.name);\n\n\treturn Jwts.builder()\n\t\t\t\t.setClaims(userData)\n\t\t\t\t.signWith(SignatureAlgorithm.HS256, PrivateKey.getBytes("UTF-8"))\n\t\t\t\t.compact();\n\t}\n}'
        this.installCodes="# See instructions here:\nhttps://mvnrepository.com/artifact/io.jsonwebtoken/jjwt/0.7.0"

      }
      else if (this.openInstallTab == 5) {
        this.codes=`use \\Firebase\\JWT\\JWT; \n\n$privateKey = 'YOUR_FEEDBEAR_PRIVATE_KEY';\n\n// Create the array containing the user information\n$payload= array(\n\t\t'email' => $userEmail,\n\t\t'name' => $userName\n);\n\n// Generate the JWT token\n$jwtToken = JWT::encode($payload, $privateKey, 'HS256');\n\n//After a successful login redirect the user back to your FeedBear project\nheader('https://${this.customDomain? this.customDomain : this.subDomain}/sso/auth?payload=' . $jwtToken);`
        this.installCodes="composer require firebase/php-jwt"
      }
      else if (this.openInstallTab == 6) {
        this.codes="import jwt\n\nprivate_key = 'YOUR_PRIVATE_SSO_KEY'\n\ndef create_feedbear_token(user):\n\tuser_data = {\n\t\t'email': user.email,\n\t\t'name': user.name,\n\t}\n\treturn jwt.encode(user_data, private_key, algorithm='HS256')"
        this.installCodes="pip install PyJWT"

      }
      else if (this.openInstallTab == 7) {
        this.codes=`require 'jwt'\n\nvar PrivateKey = 'YOUR_PRIVATE_SSO_KEY';\n\ndef createFeedBearToken(user)\n\tuserData = {\n\t\temail: user.email,\n\t\tname: user.name\n\t}\n\n\t#Generate the JWT token\n\tJWT.encode(payload, private_key, 'HS256')\n\n\t#After a successful login redirect the user back to your FeedBear project\n\tredirect_to 'https://${this.customDomain? this.customDomain : this.subDomain}/sso/auth?payload=[[ your generated token ]]'\nend`
        this.installCodes="sudo gem install jwt"

      }
    },
    highlighter(code) {
      return highlight(code, languages.js);
    },
		instrectionData: function(){
      this.openIntrectionTab = true;
      this.openCodeInstructionTab = false;
    },
    codeData: function(){
      this.openIntrectionTab = false;
      this.openCodeInstructionTab = true;
    },
    nodeCodeSample: function() {
      return `var jwt = require('jsonwebtoken');
var PrivateKey = 'YOUR_PRIVATE_SSO_KEY';
function createFeedBearToken(user) {
  var userData = {
    email: user.email,;
    name: user.name,
  };
  token = jwt.sign(userData, PrivateKey, {algorithm: 'HS256'});
  res.redirect("https://${this.customDomain? this.customDomain : this.subDomain}/sso/auth?payload=#{token}")
}`
    }
	},
};
</script>

<style scoped>
 @media only screen and (max-width: 330px){
   .span-class{
    font-size: smaller;
    font-weight: bold;
   }
 } 
</style>
