<template>
  <div>
    <SSOAlert v-if="!ssoCheck" title="Upgrade your plan to use this feature" :description="getAppropriateDescription" :link="'/edit?view=billing'" :click="'Click here to upgrade'"/>
    <div class="mb-4 sso-webhook-style">
      <h3 class="text-xl font-bold text-neutral-800 relative mr-2 flex-1 mb-6">
        Single Sign-On
      </h3>
      <SsoConfig
        :sub-domain="this.subDomain"
        :custom-domain="this.customDomain"
        :payload-sso-token="this.payloadSsoToken"
        >
      </SsoConfig>
      <div class="mt-8">
        <h4 class="text-xm mt-3 font-bold text-neutral-700">FeedBear Private Key</h4>
        <p>Below you can find your private key, which will be used to generate the JWT token on your website</p>
        <div class="flex mt-2 inp-width ">
          <div class="relative flex justify-between items-center input">
            <div class="parentInput">
              <input
                v-model="keyToken"
                class="childInput"
                :readonly="true"
              />
            </div>
            <tippy
              interactive
              :animate-fill="false"
              theme="translucent"
              animation="fade"
              trigger="click"
              arrow="roundArrow"
            >
              <template v-slot:trigger>
                <div @click="clipCopy" class="cursor-pointer">
                  <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"></path></svg>
                </div>
              </template>
              <span>
                <p class="font-bold">COPIED!</p>
              </span>
            </tippy>
          </div>
          <button class="btn-main whitespace-nowrap ml-2" @click.prevent="generateNewKeyPopup = true">Generate new key</button>
        </div>
      </div>

      <transition name="fromBottom" mode="out-in" appear>
        <div class v-if="!isPrivateToggleLoading">
          <h4 class="text-xm mt-5 font-bold text-neutral-700">Your website's login URL</h4>
          <p>Provide the URL to which your users will be redirected when they click the login button </p>
          <div class="mt-2" :class="screenWidth < 768? '' : 'flex'">
            <div class="relative mr-2 flex justify-between items-center inp-width input">
              <div class="parentInput">
                <input
                  :disabled="!ssoCheck"
                  class="childInput"
                  v-model="inputRedirectUrl"
                  placeholder="e.g. https://yoursite.com/login"
                />
              </div>
              <div v-if="tick_url">
                <svg class="w-6 h-6 text-green-600 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
              </div>
            </div>
            <div class="btn-width" :class="screenWidth < 768? 'mt-3' : ''">
              <a
                v-if='showLoader'
                href="#"
                class="btn-flat flex justify-center"
              >
                <svg class="animate-spin h-6 w-5" viewBox="0 0 26 26">
                  <circle class="opacity-0" cx="12" cy="12" r="10" fill="#EA4F36" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </a>
              <div v-else class="">
                <a
                  v-if = "tempRedirectUrl == null || tempRedirectUrl == ''"
                  href="#"
                  class="btn-main flex justify-center"
                  @click.prevent="ssoRedirectUrl(inputRedirectUrl,'Successfully added redirect url.')"
                >Save URL
                </a>
                <a
                  v-else
                  href="#"
                  class="btn-main flex justify-center"
                  @click.prevent="ssoRedirectUrl(inputRedirectUrl,'Successfully updated redirect url.')"
                >Change URL
                </a>
              </div>
            </div>
          </div>
          <h4 class="mt-6 font-semibold text-neutral-700">Validate your Token</h4>
          <p>Paste a sample SSO token generated on your website in the following input to verify it will work correctly</p>
          <div class="mb-7 mt-2" :class="screenWidth < 768? '' : 'flex'">
            <div class="relative mr-2 flex justify-between items-center inp-width input">
              <div class="parentInput">
                <input
                  :disabled="!ssoCheck"
                  class="childInput"
                  v-model="inputVerifyToken"
                  placeholder="e.g. eyJ0eXAiOiJKV1QiLCJhbGciOiJI.eyJlbWFpbC..."
                />
              </div>
              <div v-if="tick_sso">
                <svg class="w-6 h-6 text-green-600 " fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
               </div>
            </div>
            <div class="btn-width" :class="screenWidth < 768? 'mt-3' : ''">
              <a
                v-if='showLoaderToken'
                href="#"
                class="btn-flat flex justify-center"
              >
                <svg class="animate-spin h-6 w-5" viewBox="0 0 26 26">
                  <circle class="opacity-0" cx="12" cy="12" r="10" fill="#EA4F36" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </a>
              <a
                v-else
                href="#"
                class="btn-main flex justify-center"
                @click.prevent="verifyToken(inputVerifyToken)"
              >Validate
              </a>
            </div>
          </div>
          <div v-if="show_code_block">
            <prism-editor class="prism-code-editor rounded" v-model="ssoDataCode" :highlight="highlighter" :line-numbers="lineNumbers" readonly></prism-editor>
          </div>
        </div>
      </transition>

      <div>
        <h4 class="mt-6 font-semibold text-neutral-700">Enable SSO integration</h4>
        <p>Before you can enable SSO make sure to provide a valid redirect URL and validate your token using the fields above</p>
        <div class="toggle-wrapper mt-5">
          <div
            class="button--toggle"
            :class="{ 'area--disabled': isPrivateToggleLoading }"
            @click="tokenUrlValidation()"
            v-if="ssoCheck"
          >
            <div v-if="isDisabled" class="buttonToggle"></div>

            <input
              type="checkbox"
              class="checkbox"
              @change="toggleSsoOnly()"
              v-model="isSsoOnly" />
            <div class="knobs"></div>
            <div class="layer"></div>
          </div>
          <transition name="fromLeft" mode="out-in" appear>
            <span v-if="!isPrivateToggleLoading" key="loading1">
              <span
                v-if="isSsoOnly"
                key="isprivate"
                class="flex items-center"
              >
                <span><strong>SSO Enabled</strong> – users will be allowed to login to your board using your website credentials </span>
              </span>
              <span v-else key="ispublic" class="flex items-center">
                <span><strong>SSO Disabled</strong> – users can login using their FeedBear and social accounts </span>
              </span>
            </span>
            <span class="text-neutral-700" v-else key="loading2">Saving …</span>
          </transition>
        </div>
        <div class=" mt-5 p-4 rounded-md messageSsoEnabling" v-if="validationMessage">
          <p class=" text-base not-italic messageSsoEnablingText">Please add a redirect URL and validate your token before enabling SSO</p>
        </div>
      </div>
      <Dialog v-model="ssoEnablePopup" @input="setToPrivious">
        <div class="px-4 pb-8">
          <div class="text-center font-semibold">Important!</div>
          <div class="p-2 text-center">Make sure your login URL is properly configured<br />before enabling SSO, otherwise your idea board<br />will not be accessible</div>
          <div class="flex justify-center">
          <a
            href="#"
            class="btn-main btn-main-sso justify-center mx-2"
            @click.prevent="saveSso()"
          >
          Enable SSO</a>
          <a
            href="#"
            class="btn-flat btn-flat-sso justify-center mx-2"
            @click.prevent="setToPrivious()"
          >
          Go Back</a>
          </div>
        </div>
      </Dialog>
      <Dialog v-model="generateNewKeyPopup" title="Generate new key" custom-width-class="w-3/12">
        <div class="p-4 pt-0">
          <div class="p-2 pl-0">
            Are you sure you want to generate a new key?<br> Once a new key is
            generated your old key will be deleted, this is not reversible.
          </div>
          <div class="flex justify-start mt-2">
            <a
              href="#"
              class="btn-flat justify-center mr-2 whitespace-nowrap"
              @click.prevent="generateNewKeyPopup = false"
            >
              Cancel</a
            >
            <a
              href="#"
              class="btn-main justify-center mx-2 whitespace-nowrap"
              @click.prevent="regenerateFeedbearToken"
            >
              Generate new key</a
            >
          </div>
        </div>
      </Dialog>
    </div>
  </div>
</template>

<script>
import SsoConfig from '../../components/sso_config.vue'
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";
import SSOAlert from "./plan_alert"
import Dialog from "../../components/dialog";
import {mapGetters} from "vuex"

export default {
  components: {
    SsoConfig,
    Dialog,
    SSOAlert
  },
  props:{
    projectToken: String,
    ssoVerifyTokenUrl: String,
    projectSsoRedirectUrl: String,
    projectSsoOnly: Boolean,
    toggleSsoOnlyUrl: String,
    projectSsoEnabled: Boolean,
    toggleSsoIntegrationUrl: String,
    projectUpdateUrl: String,
    subDomain:String,
    customDomain: String,
    payloadSsoToken: String,
    payloadSsoTokenData: String,

  },
  data() {
    return {
      inputRedirectUrl: this.projectSsoRedirectUrl,
      tempRedirectUrl: this.projectSsoRedirectUrl,
      showLoader: false,
      showLoaderToken: false,
      isPrivateToggleLoading: false,
      isSsoOnly: this.projectSsoOnly,
      inputVerifyToken: this.payloadSsoToken? this.payloadSsoToken : '',
      tick_url: false,
      tick_sso: false,
      validationMessage: false,
      screenWidth: '',
      sso_data: true,
      ssoDataCode:'',
      lineNumbers: true,
      show_code_block: false,
      ssoEnablePopup: false,
      ssoPriviousValue: this.projectSsoOnly,
      generateNewKeyPopup: false,
      keyToken: this.projectToken,
    }
  },
  computed: {
    ...mapGetters(['getPlanFeatures','getCurrentProjectPlan']),
    isDisabled() {
      if (this.isSsoOnly) {
        return false;
      }
      return !this.tick_url || !this.tick_sso;
    },
    ssoCheck(){
      if(this.getCurrentProjectPlan.name!="trial" && this.getCurrentProjectPlan.active_status){
        const plans = this.getPlanFeatures;
        return plans[this.getCurrentProjectPlan.name].sso;
      }
      else{
        return this.getCurrentProjectPlan.active_status;
      }
    },
    getAppropriateDescription(){
      if(this.getCurrentProjectPlan.name!="trial"){
        if(this.getCurrentProjectPlan.active_status) return 'SSO is only available to Business and Enterprise users. Please upgrade your plan to use this feature.';
        else return 'Your subscription failed to update. To use SSO feature, please update your card.'
      }
      else{
        return 'Your trial period has expired. Please upgrade your plan.';
      }
    }
  },
  mounted () {
    if (this.inputRedirectUrl) {
      this.tick_url = true;
    }
    if (this.payloadSsoToken){
      this.tick_sso = true;
      this.show_code_block=true;
      const ssoEmailName = JSON.parse(this.payloadSsoTokenData);
      this.ssoDataCode = this.ssoEmailNameDATA(ssoEmailName)
    }
    this.screenWidth = window.innerWidth;
  },
  methods: {
    tokenUrlValidation() {
      this.validationMessage = this.isDisabled;
    },
    setToPrivious () {
      this.isSsoOnly = this.ssoPriviousValue;
      this.ssoEnablePopup = false;
    },
    ssoRedirectUrl(redirect_url,msg) {
      var data = new FormData();
      data.append("project[sso_redirect_url]", redirect_url);
      data.append("sso_data", this.sso_data);
      this.validationMessage = false;
      this.showLoader = true;
      Rails.ajax({
        url: this.projectUpdateUrl,
        type: "PATCH",
        data: data,
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          this.showLoader = false;
          this.inputRedirectUrl = '';
          this.tempRedirectUrl = data.project.sso_redirect_url;
          this.inputRedirectUrl = this.tempRedirectUrl;
          this.tick_url=true;
          this.$emit("ssoRedirectUrlUpdate", this.tempRedirectUrl);
          this.$notify({
            group: 'success',
            text: msg,
          });
          console.log(data);
        },
        error: (data, status) => {
          this.showLoader = false;
          this.inputRedirectUrl = '';
          this.$notify({
            group: 'alert',
            text: data,
          });
          console.log(data);
        }
      });
    },
    toggleSsoOnly() {
      if(this.isSsoOnly == true) {
        this.ssoEnablePopup = true;
      } else {
        this.saveSso();
      }
    },
    saveSso() {
      Rails.ajax({
        url: this.toggleSsoOnlyUrl,
        type: "POST",
        data: "",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isSsoOnlyEnabled = data.is_sso_only;
          this.$emit("ssoOnlyUpdate", this.isSsoOnly);
          this.ssoEnablePopup = false;
          this.ssoPriviousValue = data.is_sso_only;
          this.$notify({
            group: data.is_sso_only? 'success' : 'alert',
            text: data.is_sso_only? "Successfully enabled SSO only." : "SSO has been disabled",
          });
          this.$store.dispatch('getAllWarnings')
          this.$store.dispatch('fetchCurrentPlan')
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    clipCopy () {
      navigator.clipboard.writeText(this.projectToken);
    },
    highlighter(code) {
      return highlight(code, languages.js);
    },
    ssoEmailNameDATA(ssoEmailName) {
      return ` // Here is your decoded token for reference\n {\n\t'email' => '${ssoEmailName.email}', \n\t'name' => '${ssoEmailName.name}',\n };`
    },
    verifyToken(payload) {
      var data = new FormData();
      data.append("payload", payload);
      this.showLoaderToken = true;
      Rails.ajax({
        url: this.ssoVerifyTokenUrl,
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          this.showLoaderToken = false;
          this.tick_sso = true;
          this.show_code_block=true;
          this.ssoDataCode = this.ssoEmailNameDATA(data.sso_token_data);
          this.$notify({
            group: 'success',
            text: data.message,
          });
          console.log(data);
        },
        error: (data, status) => {
          this.showLoaderToken = false;
          this.tick_sso = false;
          this.inputVerifyToken = '';
          this.show_code_block=false;
          this.$notify({
            group: 'alert',
            text: data.message,
          });
          console.log(data);
        }
      });
    },
    regenerateFeedbearToken(){
      this.generateNewKeyPopup = false;
      var data = new FormData();
      data.append("project[token_type]", "token");
      Rails.ajax({
        url: this.projectUpdateUrl,
        type: "PATCH",
        data: data,
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          this.keyToken = data.token;
          this.inputVerifyToken = data.payload_sso_token;
          this.tick_sso = false;
          this.isSsoOnly = data.sso_only;
          this.payloadSsoTokenData = "";
          this.show_code_block = false;
          this.$notify({
            group: 'success',
            text: "New FeedBear key is generated successfully",
          });
          this.$emit("updateToken", data.token);
        },
        error: (status) => {
          this.$notify({
            group: 'alert',
            text: data,
          });
        }
      });
    }, 
  },
};
</script>

<style scoped>
.button--toggle .checkbox:checked + .knobs:before {
  content: "";
  left: 42px;
  background-color: #3bd087;
}

.button--toggle .checkbox:checked ~ .layer {
  background-color: #d1f0e1;
  border-color: #a3e6c6;
}
.inp-width {
  width: 60%;
}
.btn-width {
  width: 14%;
}
.childInput {
  width: 100%;
  outline: none;
}
.parentInput {
  width: 100% !important;
}
.messageSsoEnabling {
  background: #FFF3C2;
  width:100%;
  min-height: 42px;
}
.messageSsoEnablingText {
  color: #83743A;
  align-items: center;
}
.btn-flat-sso {
  width: 150px;
}
.btn-main-sso {
  width: 150px;
}
@media only screen and (min-width: 320px) and (max-width: 414px){
  .inp-width {
    width: 100%;
  }
  .btn-width {
    width: 64%;
  }
}

@media only screen and (min-width: 415px) and (max-width: 767px){
  .inp-width {
    width: 100%;
  }
  .btn-width {
    width: 45%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1279px){
  .inp-width {
    width: 60%;
  }
  .btn-width {
    width: 26%;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1535px){
  .inp-width {
    width: 60%;
  }
  .btn-width {
    width: 18%;
  }
}

/* Animations */

.fromLeft-move {
  transition: all 250ms;
}
.fromLeft-enter-active,
.fromLeft-leave-active {
  transition: all 250ms;
}
.fromLeft-enter,
.fromLeft-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}

.imp-parent {
  background: #FEE2E2;
  width:100%;
  min-height: 42px;
}
.imp-txt {
  font-family: Roboto;
  color: #C91C1C;
}

.sso-code-prism .prism-code-editor {
  background-color: #F4F5F7;
  color: #2f2f2f;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sso-code-prism .prism-editor__container{
  min-height:0px !important;
}

</style>

