<template>
  <div class="relative mt-4">
    <label class="label">Accent color</label>
    <input
      id="project_color"
      type="text"
      v-model="color"
      name="project[color]"
      class="input pl-10"
    />
    <div
      class="h-6 w-6 rounded-full text-white text-sm font-semibold absolute left-0 bottom-0 mb-2 ml-2 flex items-center justify-center"
      :style="{ backgroundColor: color }"
    >
      <span class="leading-none">Aa</span>
    </div>
    <a
      href="#"
      @click.prevent="color = '#F84339'"
      class="absolute right-0 bottom-0 mb-3 mr-3 text-sm leading-tight"
      >Reset</a
    >
  </div>
</template>

<script>
export default {
  props: ["current-color"],
  data: function () {
    return {
      color: this.currentColor || "#F84339",
    };
  },
  components: {},
  methods: {},
};
</script>

<style scoped></style>
