<template>
  <div class="">
    <transition name="showUp">
      <div
        class="fixed flex inset-0 bg-black bg-opacity-25 z-40"
        v-if="value"
        @click.self="handleClose"
      ></div>
    </transition>
    <transition name="comeIn">
      <div
        class="fixed flex inset-0 z-[9999]"
        v-if="value"
        @click.self="handleClose"
      >
        <div
          v-if="value"
          id="dialog-modal"
          :class="`${customWidthClass ? customWidthClass:'w-full'} ${customMaxWidthClass ? customMaxWidthClass: 'max-w-screen-sm'} max-h-screen bg-white text-black rounded-lg shadow-lg m-auto overflow-auto`"
        >
          <div v-if="isBilling" class="flex px-4 text-gray-700 items-center justify-center mt-8 mb-4">
            <h4 class="font-semibold text-lg">{{ title }}</h4>
          </div>
          <div v-else class="flex px-4 pt-4 items-center">
            <button
            v-if="backFunction"
            @click.prevent="backFunction"
            class="text-neutral-700 hover:bg-neutral-100 p-1 rounded-md mr-2">
            <svg data-v-4d775606="" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" style="transform:rotate(90deg)"><path data-v-4d775606="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
          </button>
            <h4 class="font-semibold">{{ title }}</h4>
            <a
              href="#"
              class="text-neutral-700 hover:text-project-500 ml-auto"
              @click.prevent="handleClose"
              ><svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path></svg
            ></a>
          </div>
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["title", "value", "isBilling", "custom-width-class", "custom-max-width-class", "back-function"],
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$emit("input", false);
    }
  }
};
</script>

<style scoped>
.showUp-enter-active,
.showUp-leave-active {
  transition: opacity 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.showUp-enter,
.showUp-leave-to {
  opacity: 0;
}

.showUp-leave,
.showUp-enter-to {
  opacity: 1;
}

.comeIn-enter-active,
.comeIn-leave-active {
  transition: opacity 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.comeIn-enter,
.comeIn-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.comeIn-leave,
.comeIn-enter-to {
  opacity: 1;
  transform: scale(1);
}
@media screen and (max-width: 640px) {
  #dialog-modal{
    width: 90%;
  }
}
</style>
