<template>
  <div>
    <table class="table-auto w-full mt-4" v-if="entries.length > 0">
      <thead>
        <tr>
          <th v-for="col in columns" :key="col.id" class="px-4 py-2 text-left">
            {{ col.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="customer in entries"
          :key="customer.id"
          class="hover:bg-neutral-100 transition-colors duration-150 ease-in-out"
        >
          <td
            v-for="col in columns"
            :key="col.id"
            class="border px-4 py-2"
            v-html="col.format(customer, col.prop)"
          ></td>
        </tr>
      </tbody>
    </table>

    <p class="text--center text--lighter pb-2" v-else>No Data</p>
  </div>
</template>

<script>
export default {
  props: ["customers"],
  data: function () {
    return {
      columns: [
        {
          id: 1,
          prop: "name",
          label: "Name",
          format: this.nameFormatter,
        },
        {
          id: 2,
          prop: "upvotes_received",
          label: "Upvotes received",
          format: this.passThrough,
        },
        {
          id: 3,
          prop: "upvotes_given",
          label: "Upvotes given",
          format: this.passThrough,
        },
        {
          id: 4,
          prop: "comments_count",
          label: "Comments count",
          format: this.passThrough,
        },
      ],

      entries: this.customers,
    };
  },
  methods: {
    countryFormatter(row, column = null) {
      return row.country_flag + " " + row.country_name;
    },

    nameFormatter(row, column) {
      return `<a href="/users/${row.handle}">${row.name}</a>`;
    },

    passThrough(row, column) {
      return row[column];
    },
  },
};
</script>
