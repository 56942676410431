<template>
  <div
    class="labels__item"
    :class="{
      'labels__item--editing': isBeingEdited || isBeingDeleted,
      'labels__item--loading': isLoading
    }"
    :key="label.id"
    :style="'--label-color:' + label.color"
  >
    <transition name="switch" appear mode="out-in" @after-enter="focusInput()">
      <a
        v-if="!isBeingEdited && !isBeingDeleted"
        href="#"
        key="label"
        class="labels__item-inside branded-link"
        @click.prevent="startEditing()"
      >
        <span class="labels__item__name">{{ label.name }}</span>
        <div class="labels__item__controls">
          <span class="labels__item__controls__item branded-link">
            <simple-svg :filepath="editIcon" width="20px" height="20px" />
          </span>
        </div>
      </a>

      <div
        class="labels__item__form"
        v-else-if="isBeingEdited && !isBeingDeleted"
        key="form"
      >
        <input
          type="text"
          class="input"
          v-model="newName"
          @keydown.enter.prevent="handleSave()"
          placeholder="Label name"
          ref="input"
        />

        <v-swatches
          v-model="newColor"
          :swatches="swatches"
          show-fallback
          fallback-input-class="input"
        >
          <div
            slot="trigger"
            class="w-8 h-8 rounded-md flex items-end justify-end"
            :style="{ backgroundColor: newColor }"
          >
            <svg
              class="w-4 h-4 mr-1 mb-1 text-white"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </v-swatches>

        <div class="labels__item__form__controls">
          <a
            href="#"
            class="labels__item__controls__block text-neutral-800"
            @click.prevent="deleteConfirmation()"
          >
            <simple-svg
              :filepath="trashIcon"
              :width="'16px'"
              :height="'16px'"
            />Delete label
          </a>

          <span class="labels__item__controls__block">
            <a href="#" @click.prevent="handleClose()" class="text-neutral-800"
              >Close</a
            >
            <button
              type="button"
              class="btn text-sm px-3 py-1 ml-2"
              @click="handleSave()"
            >
              Save
            </button>
          </span>
        </div>
      </div>

      <div
        class="labels__item__confirmation"
        v-else-if="isBeingEdited && isBeingDeleted"
      >
        <p class="text-center">
          Deleting this label will remove it from all updates entries.
        </p>
        <p class="mt-2 labels__item__controls__block">
          <a
            href="#"
            @click.prevent="destroyLabel()"
            class="btn text-sm px-3 py-1 mr-2 flex items-center"
          >
            <simple-svg
              :filepath="trashIcon"
              :width="'16px'"
              :height="'16px'"
            /><span>Yes, delete</span>
          </a>
          <small>
            <a
              href="#"
              @click.prevent="isBeingDeleted = false"
              class="text-neutral-800"
              >Cancel</a
            >
          </small>
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import editIcon from "../../images/icons/edit.svg";
import trashIcon from "../../images/icons/trash.svg";

import VSwatches from "vue-swatches";

export default {
  props: ["original-label"],

  data: function() {
    return {
      label: this.originalLabel,
      newName: this.originalLabel.name,
      newColor: this.originalLabel.color,
      isBeingEdited: false,
      isBeingDeleted: false,
      isLoading: false,
      editIcon,
      trashIcon,
      swatches: [
        "#2D80FA",
        "#18BFFF",
        "#1ECFC7",
        "#FF08C3",
        "#F92B60",
        "#FF702C",
        "#FDB402",
        "#20C933",
        "#8B46FF",
        "#666666"
      ]
    };
  },

  mounted: function() {
    window.addEventListener("keydown", e => {
      if (e.key == "esc") {
        this.labelsOpen = false;
      }
    });
  },

  components: {
    editIcon,
    trashIcon,
    VSwatches
  },

  methods: {
    startEditing(e) {
      this.isBeingEdited = true;
    },

    focusInput() {
      if (this.isBeingEdited && !this.isBeingDeleted) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },

    handleClose() {
      this.isBeingEdited = false;
      this.newName = this.label.name;
      this.newColor = this.label.color;
    },

    handleSave() {
      this.updateLabel();
    },

    deleteConfirmation() {
      this.isBeingDeleted = true;
    },

    destroyLabel() {
      this.isLoading = true;

      Rails.ajax({
        url: "/updates/labels/" + this.label.id,
        type: "DELETE",
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          this.handleClose();
          this.$emit("destroyedLabel", this.label);
          this.isLoading = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isLoading = false;
        }
      });
    },

    updateLabel() {
      this.isLoading = true;

      let params = new FormData();

      params.append("label[name]", this.newName);
      params.append("label[color]", this.newColor);

      Rails.ajax({
        url: "/updates/labels/" + this.label.id,
        type: "PATCH",
        data: params,
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          this.label.name = this.newName;
          this.label.color = this.newColor;
          this.$emit("updatedLabel", this.label);

          this.isBeingEdited = false;
          this.isLoading = false;
        },
        error: (data, status) => {
          console.log(data);
          this.isLoading = false;
        }
      });
    }
  }
};
</script>

<style scoped>
.vue-swatches {
  @apply leading-none;
}

.switch-enter,
.switch-leave-to {
  position: absolute;
  opacity: 0;
  transform: translateY(-10px);
}

.switch-enter-to,
.switch-leave {
  position: relative;
  opacity: 1;
  transform: translateY(0);
}

.switch-enter-active,
.switch-leave-active {
  transition: opacity, transform 150ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
