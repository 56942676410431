<template>
  <div class="relative mt-2">
    <transition name="switch" mode="out-in" @after-enter="afterAnim">
      <input
        class="input"
        v-if="inputVisible"
        v-model="inputValue"
        ref="boardInput"
        placeholder="Filter boards..."
        @keyup.esc="handleInputCancel()"
        @blur="handleBlur"
      />
      <button v-else class="btn-flat w-full" @click.prevent="showInput">
        <simple-svg
          :filepath="moveIcon"
          :width="'16px'"
          :height="'16px'"
          class="mr-2"
        />Move to another board
      </button>
    </transition>
    <transition name="show" appear>
      <ul
        class="absolute z-10 bg-white rounded-b-md border border-neutral-200 shadow-md w-full py-2"
        v-if="boards.length > 0 && inputVisible"
      >
        <li class="" v-for="board in filteredBoards" :key="board.id">
          <a
            href="#"
            class="flex items-center w-full py-3 px-3 text-neutral-800 text-sm hover:no-underline hover:bg-neutral-100 transition duration-150 ease-in-out"
            @click.prevent="performMoveTo(board.id)"
          >
            <div>{{ board.name }}</div>
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import moveIcon from "images/advance.svg";

export default {
  props: ["original-post", "all-boards", "all-boards", "move-url"],
  data: function() {
    return {
      inputVisible: false,
      inputValue: "",
      moveIcon
    };
  },
  methods: {
    afterAnim(el) {
      if (el.classList.contains("input")) {
        el.focus();
      }
    },

    showInput() {
      this.inputVisible = true;
    },

    handleInputCancel() {
      this.inputVisible = false;
      this.inputValue = "";
    },

    handleBlur(event) {
      if (this.inputValue || event.relatedTarget) {
      } else {
        this.inputVisible = false;
      }
    },

    performMoveTo(board_id) {
      var data = new FormData();
      data.append("new_board_id", board_id);

      Rails.ajax({
        url: this.moveUrl,
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function() {
          return true;
        },
        success: data => {
          console.log(data);
        },
        error: (data, status) => {
          console.log(data);
        }
      });
    }
  },

  computed: {
    boards() {
      return this.allBoards.filter(
        board => board.id !== this.originalPost.board_id
      );
    },

    filteredBoards() {
      if (this.inputValue.length > 0) {
        return this.boards.filter(board =>
          board.name.toLowerCase().includes(this.inputValue.toLowerCase())
        );
      } else {
        return this.boards;
      }
    }
  }
};
</script>

<style scoped>
.switch-enter {
  opacity: 0;
}

.switch-leave-to {
  opacity: 0;
}

.switch-enter-to,
.switch-leave {
  opacity: 1;
}

.switch-enter-active,
.switch-leave-active {
  transition: opacity 150ms ease-out;
}

.show-enter,
.show-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.show-enter-to,
.show-leave {
  opacity: 1;
  transform: translateY(0);
}

.show-enter-active,
.show-leave-active {
  transition: opacity, transform 150ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
