<template>
  <section>
    <div class="bg-white border border-red-500 rounded-lg mt-4">
      <div
        class="px-4 py-5 sm:p-6 items-center justify-between"
        id="delete-btn-div"
      >
        <div class="">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Delete this project
          </h3>
          <div class="mt-2 max-w-xl text-sm text-gray-500">
            <p>
              Once you delete this project, you will lose all data associated
              with it.
            </p>
          </div>
        </div>
        <button
          id="delete-btn"
          type="button"
          @click.prevent="dialogVisible = true"
          class="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
        >
          Delete this project
        </button>
      </div>
    </div>

    <Dialog title="Confirmation" v-model="dialogVisible">
      <div class="px-4 py-4">
        <h2 class="text-lg text-neutral-800">
          You are about to permanently delete this project
        </h2>

        <p class="mt-4">
          This action cannot be undone. This will permanently delete the
          <strong>{{ this.projectSubdomain }}.feedbear.com</strong> project,
          boards, ideas, comments, votes, and remove all collaborator
          associations.
        </p>

        <div class="flex justify-end items-center mt-4 space-x-2">
          <button class="btn" @click="dialogVisible = false">Cancel</button>
          <slot name="delete_button"></slot>
        </div>
      </div>
    </Dialog>
  </section>
</template>

<script>
import Dialog from "../../components/dialog";

export default {
  props: ["project-id", "project-subdomain", "current-user-handle"],
  data() {
    return {
      dialogVisible: false,
    };
  },
  components: {
    Dialog,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
#delete-btn-div {
  display: flex;
}

@media only screen and (max-width: 480px) {
  #delete-btn-div {
    display: block;

    #delete-btn {
      width: -webkit-fill-available;
      margin-top: 1rem;
    }
  }
}
</style>
