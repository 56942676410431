<template>
  <div class="relative flex items-center gap-2 ml-auto" ref="scontainer">
    <transition
      name="show"
      mode="out-in"
      @before-enter="fixWidth"
      @after-enter="focusInput"
    >
      <div v-if="isSearchBoxVisible" class="w-full md:w-auto p-2">
        <form :action="search_url" class="relative">
          <input
            type="text"
            name="term"
            class="w-full md:w-72 px-4 py-[8px] text-sm pr-10 rounded-lg border border-neutral-200 focus:border-project-500 focus:ring-1 focus:ring-project-500 transition-all duration-200"
            :placeholder="$t('Search ideas')+'...'"
            ref="searchBox"
          />
          <button
            type="submit"
            class="absolute right-3 top-1/2 -translate-y-1/2 text-neutral-400 hover:text-project-500 transition-colors duration-200"
          >
            <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </button>
        </form>
      </div>

      <button
        v-else
        @click="openSearch"
        class="flex items-center p-[10px] rounded-lg hover:bg-neutral-100 mr-2 gap-2 text-[14px] text-neutral-600 hover:text-project-500 transition-colors duration-200"
      >
        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
        <!-- <span class="hidden sm:block">{{ $t('Search') }}</span> -->
      </button>
    </transition>
  </div>
</template>

<script>
import searchIcon from "../images/icons/search-fat.svg";
import closeIcon from "../images/icons/close.svg";

export default {
  props: ["search_url"],
  data() {
    return {
      isSearchBoxVisible: false,
      searchIcon,
      closeIcon,
    };
  },
  components: {
    searchIcon,
    closeIcon,
  },
  methods: {
    openSearch() {
      this.isSearchBoxVisible = true;
    },

    focusInput() {
      if (this.$refs.searchBox) {
        this.$refs.searchBox.focus();
      }
    },

    fixWidth() {
      if (this.$refs.searchBox) {
        this.$refs.scontainer.classList.add("order-4", "w-full", "md:order-2", "md:w-auto");
      }
    },
  },
};
</script>

<style scoped>
.show-enter {
  opacity: 0;
  transform: translateX(20px);
}

.show-enter-to {
  opacity: 1;
  transform: translateX(0);
}

.show-enter-active {
  transition: all 200ms ease-out;
}
</style>
