<template>
  <div>
    <CompanyDetails v-if="this.showCompanyDetails" @company-details-updated="captureCompanyDetailsUpdated" @disable-changelog-suggestion="captureCloseSuggestions" :post="this.post" :board="this.board" :project="this.project" :show-company-details="this.showCompanyDetails" :token="this.token"/>
    <ChangelogModal v-if="this.showChangelogPostSuggestion" :post="this.post" :show-changelog-post-suggestion="this.showChangelogPostSuggestion"/>
  </div>
</template>

<script>

import CompanyDetails from "./company_details.vue";
import ChangelogModal from "./changelog_modal.vue";

export default {
  props: ['show-company-details', 'show-changelog-post-suggestion', 'post', 'board', 'project', 'token'],

  data: function () {
    return {
    };
  },

  components: {
    CompanyDetails,
    ChangelogModal,
  },

  computed: {

  },

  mounted: function(){

  },

  created: function () {

  },

  methods: {
    captureCompanyDetailsUpdated(details){
      this.showCompanyDetails = false;
      this.showChangelogPostSuggestion = true;
    },
    captureCloseSuggestions(){
      this.showCompanyDetails = false;
      this.showChangelogPostSuggestion = false;
    }

  }
};
</script>
