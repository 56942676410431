<template>
  <div class="project__content__posts" id="posts">
    <div class="project__content__posts__header">
      <h3>Ideas</h3>
    </div>

    <div class="project__content__posts__nodata" v-if="posts.length == 0">
      <simple-svg :filepath="noPostsIcon" :width="'200px'" :height="'190px'" />
      <p class="mt-2">This customer does not have any ideas yet.</p>
    </div>

    <transition-group name="list" tag="div" appear>
      <div
        class="post"
        is="post"
        v-for="post in posts"
        :originalPost="post"
        :currentUser="currentUser"
        :key="post.id"
      ></div>
    </transition-group>
  </div>
</template>

<script>
import Post from "../post.vue";

import noPostsIcon from "../images/no_posts.svg";

import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";

export default {
  props: ["current-user", "original-posts"],
  data: function () {
    return {
      posts: this.originalPosts,
      noPostsIcon,
      sort: "trend_score",
      filter: "not_done",
    };
  },
  components: {
    Post,
    noPostsIcon,
  },
  computed: {
    filteredPosts: function () {
      if (this.filter !== "") {
        if (this.filter === "nan") {
          this.filter = null;
        } else if (this.filter === "not_done") {
          return this.posts.filter((post) => {
            return post.status !== "done";
          });
        }

        return this.posts.filter((post) => {
          return post.status === this.filter;
        });
      } else {
        return this.posts;
      }
    },

    sortedAndFilteredPosts: function () {
      var filtered = this.filteredPosts;
      return reverse(sortBy(filtered, this.sort));
    },
  },
  methods: {
    changeSort(value) {
      this.sort = value;
    },
    changeFilter(value) {
      this.filter = value;
    },
  },
};
</script>

<style scoped>
.list-move {
  transition: all 250ms;
}
.list-enter-active,
.list-leave-active {
  transition: all 250ms;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>
