<template>
  <div class="mb-4">
    <div v-if="project.sub_plan === 'lite'">
      <IntegrationsAlert title="Upgrade your plan to use these integrations" :description="'Integrating with partner apps isn\'t included in the Lite plan. Please upgrade your plan to use this feature.'" :link="'/edit?view=billing'" :click="'Click here to upgrade'" class="mb-2"/> 
    </div>
    <div>
      <h3 class="text-xl font-bold text-neutral-800">Integrations</h3>
      <p class="text-neutral-800">
        Connect FeedBear with the tools you already use.
      </p>
    </div>

    <div
      class="mt-4 grid gap-4 sm-grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3"
    >
      <zapier-integration 
        :z-api-key="zApiKey"
        :is-disabled="project.sub_plan === 'lite'"
      ></zapier-integration>
      <intercom-integration
        :intercom-workspace-id="intercomWorkspaceId"
        :disconnect-intercom-url="disconnectIntercomUrl"
        :intercom-url="intercomUrl"
        :is-disabled="project.sub_plan === 'lite'"
      ></intercom-integration>
      <slack-integration
        :boards="project.boards"
        :save-slack-settings-url="saveSlackSettingsUrl"
        :currentProject="project"
        :slackHookUrl="slackHookUrl"
        :is-disabled="project.sub_plan === 'lite'"
      ></slack-integration>
      <trello-integration
        :trello-config="trelloConfig"
        :boards="boards"
        :lists="lists"
        :project="project"
        :trello-oauth-path="trelloOauthPath"
        :auth-token="authToken"
        :form-url="formUrl"
        :fetch-list-url="fetchListUrl"
        :disconnect-trello-url="disconnectTrelloUrl"
        :is-disabled="project.sub_plan === 'lite'"
      ></trello-integration>
      <jira-integration-container
        :allow-jira-integration="canIntegrateJira"
        :resource-url="jiraResourceUrl"
        :project="{
          id: project.id,
          trialValid: project['trial_valid?'] && !!!project.sub_plan,
        }"
        :integration="project.jira_integration"
        :is-disabled="project.sub_plan === 'lite'"
      />
    </div>
  </div>
</template>

<script>
import JiraIntegrationContainer from "./settings/jira_integration_container";
import IntegrationsAlert from "./settings/plan_alert";

export default {
  props: [
    "trello-config",
    "boards",
    "lists",
    "project",
    "trello-oauth-path",
    "auth-token",
    "form-url",
    "fetch-list-url",
    "disconnect-trello-url",
    "intercom-workspace-id",
    "disconnect-intercom-url",
    "intercom-url",
    "save-slack-settings-url",
    "slack-hook-url",
    "can-integrate-jira",
    "jira-resource-url",
    "z-api-key",
  ],
  components: {
    JiraIntegrationContainer,
    IntegrationsAlert,
  },
};
</script>

<style scoped>
@media only screen and (max-width: 640px) {
  .sm-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
