<template>
  <div>
    <h3 class="text-xl font-bold text-neutral-800">Spam Settings</h3>
    <p class="text-neutral-800">Protect your project from spam and abuse.</p>
    <form @submit.prevent="saveSettings">
    <div class="mt-4">
      <h4 class="text-lg font-bold text-neutral-800">Strictness</h4>
      <p class="text-neutral-800">
        Choose how strict you want to be when it comes to labeling content as
        spam.
      </p>
      <div class="grid grid-cols-1 gap-4 mt-4">
        <label
          :class="[
            'flex items-center gap-4 p-4 border-2 rounded-lg cursor-pointer transition-all duration-200',
            { 'border-[var(--accent-color)]': strictness === 'none' },
          ]"
        >
          <input
            type="radio"
            name="strictness"
            value="none"
            v-model="strictness"
            class="hidden"
          />
          <div
            class="flex items-center justify-center bg-gray-100 rounded-full p-1 w-8 h-8"
          >
            <div
              class="w-6 h-6 rounded-full"
              :class="strictness === 'none' ? 'bg-gray-500' : 'bg-gray-300'"
            ></div>
          </div>
          <div>
            <h5 class="font-bold text-neutral-800">None</h5>
            <p class="text-sm text-gray-600">
              No filtering, all content is allowed
            </p>
          </div>
        </label>
        <label
          :class="[
            'flex items-center gap-4 p-4 border-2 rounded-lg cursor-pointer transition-all duration-200',
            { 'border-[var(--accent-color)]': strictness === 'low' },
          ]"
        >
          <input
            type="radio"
            name="strictness"
            value="low"
            v-model="strictness"
            class="hidden"
          />
          <div
            class="flex items-center justify-center bg-gray-100 rounded-full p-1 w-8 h-8"
          >
            <div
              class="w-6 h-6 rounded-full"
              :class="strictness === 'low' ? 'bg-emerald-500' : 'bg-gray-300'"
            ></div>
          </div>
          <div>
            <h5 class="font-bold text-neutral-800">Low</h5>
            <p class="text-sm text-gray-600">
              Minimal filtering, allows most content through
            </p>
          </div>
        </label>
        <label
          :class="[
            'flex items-center gap-4 p-4 border-2 rounded-lg cursor-pointer transition-all duration-200',
            { 'border-[var(--accent-color)]': strictness === 'medium' },
          ]"
        >
          <input
            type="radio"
            name="strictness"
            value="medium"
            v-model="strictness"
            class="hidden"
          />
          <div
            class="flex items-center justify-center bg-gray-100 rounded-full p-1 w-8 h-8"
          >
            <div
              class="w-6 h-6 rounded-full"
              :class="strictness === 'medium' ? 'bg-yellow-400' : 'bg-gray-300'"
            ></div>
          </div>
          <div>
            <h5 class="font-bold text-neutral-800">Medium</h5>
            <p class="text-sm text-gray-600">
              Balanced filtering, suitable for most use cases
            </p>
          </div>
        </label>
        <label
          :class="[
            'flex items-center gap-4 p-4 border-2 rounded-lg cursor-pointer transition-all duration-200',
            { 'border-[var(--accent-color)]': strictness === 'high' },
          ]"
        >
          <input
            type="radio"
            name="strictness"
            value="high"
            v-model="strictness"
            class="hidden"
          />
          <div
            class="flex items-center justify-center bg-gray-100 rounded-full p-1 w-8 h-8"
          >
            <div
              class="w-6 h-6 rounded-full"
              :class="strictness === 'high' ? 'bg-rose-500' : 'bg-gray-300'"
            ></div>
          </div>
          <div>
            <h5 class="font-bold text-neutral-800">High</h5>
            <p class="text-sm text-gray-600">
              Aggressive filtering, may block some legitimate content
            </p>
          </div>
        </label>
      </div>
    </div>
    
    <div class="mt-8">
      <h4 class="text-lg font-bold text-neutral-800">Spam Keywords</h4>
      <p class="text-neutral-800">
        Add keywords or phrases that should be automatically flagged as spam.
      </p>
      <div class="mt-4">
        <textarea
          v-model="spam_keywords"
          rows="4"
          class="input h-40"
          spellcheck="false"
          placeholder="Enter keywords or phrases separated by commas (e.g. 'spam, scam, fake')"
        ></textarea>
      </div>
    </div>

    <div class="mt-8">
      <button type="submit" class="btn-main">Save</button>
    </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    token: String,
    project: Object
  },
  data() {
    return {
      strictness: this.project.spam_strictness,
      spam_keywords: this.project.spam_keywords.join(', '),
    };
  },
  methods: {
    saveSettings() {
      let data = new FormData();
      data.append("spam_strictness", this.strictness);
      data.append("spam_keywords", this.spam_keywords);
      data.append("project_id", this.project.id);

      Rails.ajax({
        url: "/projects/spam_settings",
        type: "PATCH",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (result) => {
          if (result.message) {
            window.location.reload();
          } else if (result.errors) {
            console.error("Error saving settings:", result.errors);
          }
        },
        error: (data, status) => {
          console.error("Error:", data);
        },
      });
    },
  },
};
</script>
