<template>
  <div class="flex items-center px-1">
    <upvote :post="post"></upvote>

    <div class="flex flex-col items-start justify-start py-3">
      <h3 class="text-lg font-medium" v-line-clamp:24="2">
        <router-link
          :to="{ name: 'post', params: { id: post.id } }"
          class="text-neutral-900 hover:no-underline hover:text-project-500 transition duration-200 ease-in-out"
          style="word-break: normal;"
          >{{ post.title }}</router-link
        >
      </h3>
      <PostStatus
        v-if="post.status"
        :status="post.status"
      ></PostStatus>
      <p class="text-neutral-800 mt-1" style="word-break: normal;" v-line-clamp:22="lines">
        {{ post.content }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import PostStatus from "./post_status";

export default {
  props: ["post"],
  data: function () {
    return {
      lines: 2,
    };
  },
  components: {
    PostStatus,
  },
  methods: {
    ...mapMutations(["showDialog"]),
  },
};
</script>

<style scoped></style>
