<template>
  <div>
    <h3 class="text-xl font-bold text-neutral-800">AI settings</h3>
    <p class="text-neutral-800">
      Here, you can control the settings and source information for FeedBear’s AI content generation features.
    </p>

    <p class="mt-2 text-neutral-800">
    </p>

    <h4 class="mt-4 font-semibold text-neutral-800">Changelog</h4>

    <p class="text-neutral-800">
      Enable AI features to help your team write changelog drafts.
    </p>

    <div class="toggle-wrapper mt-2">
      <div class="button--toggle">
        <input type="checkbox" class="checkbox" @change="toggleChangelog()" v-model="this.project.changelog_suggestions"/>
        <div class="knobs"></div>
        <div class="layer"></div>
      </div>
      <transition name="fromLeft" mode="out-in" appear>
        <span v-if="this.project.changelog_suggestions" key="isactive" class="flex items-center" >
          <span><strong>On</strong></span>
        </span>
        <span v-else key="ispublic" class="flex items-center">
          <span><strong>Off</strong></span>
        </span>
      </transition>
    </div>

    <h4 class="mt-4 font-semibold text-neutral-800">Comments</h4>

    <p class="text-neutral-800">
      Enable AI features to draft replies for new ideas.
    </p>

    <div class="toggle-wrapper mt-2">
      <div class="button--toggle">
        <input type="checkbox" class="checkbox" @change="toggleComments()" v-model="this.project.comments_suggestions"/>
        <div class="knobs"></div>
        <div class="layer"></div>
      </div>
      <transition name="fromLeft" mode="out-in" appear>
        <span v-if="this.project.comments_suggestions" key="isactive" class="flex items-center" >
          <span><strong>On</strong></span>
        </span>
        <span v-else key="ispublic" class="flex items-center">
          <span><strong>Off</strong></span>
        </span>
      </transition>
    </div>

    <p class="mt-2 text-neutral-800">
    </p>

    <h4 class="mt-4 font-semibold text-neutral-800">About your project</h4>

    <p class="mt-2 text-neutral-800">
      Please tell us more about what {{project.name}} does. This will help us give you better suggestions and content to save you time when you write changelog entries and comments for new ideas.
    </p>

    <form
      enctype="multipart/form-data"
      action="/"
      accept-charset="UTF-8"
      method="post"
      class=""
    >
      <input type="hidden" name="_method" value="patch" />
      <input type="hidden" name="authenticity_token" :value="this.form_token" />
      <input type="hidden" name="settings" value="ai_settings" />

      <div class="flex items-center flex-wrap mt-4 mb-4">
          <textarea
            id="company_details"
            type="text"
            name="project[company_details]"
            class="input h-40"
            v-model="this.project.company_details"
            maxlength="500"
            :placeholder="`${this.project.name} is the solution for [target audience] who struggle with [main problem or pain point]. Our product provides [list of benefits] that help you [jobs to be done] so you can achieve [expected results].`"
            rows="7"
          />
      </div>
      <button class="btn-main" type="submit">
        Save
      </button>
    </form>
  </div>
</template>

<script>
export default {
  props: ["token", "project"],
  data: function () {
    return {
      form_token: this.token,
      isChangelogActive: this.project.changelog_suggestions,
      isCommentsActive: this.project.comments_suggestions,
    };
  },

  methods: {
    toggleChangelog: function () {
      Rails.ajax({
        url: `/changelog/suggestions/enable?enable=${!this.project.changelog_suggestions}`,
        type: "POST",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isChangelogActive = data.changelog_suggestions;
          this.project.changelog_suggestions = data.changelog_suggestions;
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
    toggleComments: function () {
      Rails.ajax({
        url: `/comments/suggestions/enable?enable=${!this.project.comments_suggestions}`,
        type: "POST",
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          this.isChangelogActive = data.comments_suggestions;
          this.project.comments_suggestions = data.comments_suggestions;
        },
        error: (data, status) => {
          console.log(data);
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.area--disabled {
  opacity: 0.4;
  pointer-events: none;
}

.toggle-wrapper {
  display: flex;
  align-items: center;
}

.button--toggle {
  position: relative;
  width: 74px;
  height: 36px;
  overflow: hidden;
  margin-right: 0.5rem;
}

.button--toggle,
.button--toggle .layer {
  border-radius: 100px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #f5f5f5;
  transition: 0.3s ease all;
  z-index: 1;
  border: 1px solid gainsboro;
}

.button--toggle .knobs:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 28px;
  height: 28px;
  background-color: #d2d2d2;
  border-radius: 50%;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
}

.button--toggle .checkbox:active + .knobs:before {
  width: 46px;
  border-radius: 100px;
}

.button--toggle .checkbox:checked:active + .knobs:before {
  margin-left: -26px;
}

.button--toggle .checkbox:checked + .knobs:before {
  content: "";
  left: 42px;
  background-color: #3bd087;
  @media only screen and (min-width:381px) and (max-width:420px) {
    left: 40px;
  }
  @media only screen and (min-width:320px) and (max-width:350px) {
    left: 25px;
  }
  @media only screen and (min-width:351px) and (max-width:380px) {
    left: 32px;
  }
}

.button--toggle .checkbox:checked ~ .layer {
  background-color: #d1f0e1;
  border-color: #a3e6c6;
}

.ai-screenshot-wrapper {
  height: 95px;
  margin-top: 0.5rem;
}

.ai-screenshot {
  max-width: 100%;
  display: block;
  border-radius: 4px;
}

/* Animations */

.fadeIn-enter,
.fadeIn-leave-to {
  opacity: 0;
}

.fadeIn-enter-to,
.fadeIn-leave {
  opacity: 1;
}

.fadeIn-enter-active,
.fadeIn-leave-active {
  transition: opacity 200ms ease-in;
}

// .fromBottom-move {
//   transition: all 250ms;
// }
// .fromBottom-enter-active,
// .fromBottom-leave-active {
//   transition: all 250ms;
//   position: absolute;
// }
// .fromBottom-enter,
// .fromBottom-leave-to {
//   opacity: 0;
//   transform: translateY(10px);
// }

.fromLeft-move {
  transition: all 250ms;
}
.fromLeft-enter-active,
.fromLeft-leave-active {
  transition: all 250ms;
}
.fromLeft-enter,
.fromLeft-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}
</style>
