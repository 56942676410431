<template>
  <div class="flex items-center gap-2 mt-5" ref="reactionsContainer">
    <!-- Display existing reactions -->
    <div class="flex items-center gap-2 flex-wrap">
      <button
        v-for="emoji in sortedReactions"
        :key="emoji"
        @click="toggleReaction(emoji)"
        class="hover:bg-transparent flex-shrink-0 hover:border-gray-100 border border-transparent rounded-full px-3 py-1 text-xs bg-gray-100 h-6 flex items-center justify-center duration-150 ease-in-out transition-all hover:scale-105"
        :class="{ 'user-reacted': hasUserReacted(emoji) }"
      >
        {{ emoji }} {{ reactions[emoji] > 0 ? reactions[emoji] : 1 }}
      </button>

      <!-- Plus button to add a new reaction -->
      <button
        ref="emojiButton"
        class="hover:bg-transparent hover:border-gray-100 border border-transparent text-xs bg-gray-100 rounded-full px-3 py-1 h-6 flex items-center justify-center duration-150 ease-in-out transition-all hover:scale-105"
        @click="openEmojiPicker"
      >
        <svg
          fill="#000000"
          height="16"
          width="16"
          viewBox="0 0 330 330"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300 c-74.44,0-135-60.561-135-135S90.56,30,165,30s135,60.561,135,135S239.439,300,165,300z"
          />
          <path
            d="M215.911,200.912H114.088c-6.067,0-11.537,3.654-13.858,9.26c-2.321,5.605-1.038,12.057,3.252,16.347 C119.914,242.95,141.762,252,165,252c23.238,0,45.086-9.05,61.518-25.481c4.29-4.29,5.573-10.741,3.252-16.347 C227.448,204.566,221.978,200.912,215.911,200.912z"
          />
          <path
            d="M115.14,147.14c3.72-3.72,5.86-8.88,5.86-14.14c0-5.26-2.14-10.42-5.86-14.141 C111.42,115.14,106.26,113,101,113c-5.27,0-10.42,2.14-14.14,5.859C83.13,122.58,81,127.74,81,133c0,5.26,2.13,10.42,5.86,14.14 c3.72,3.72,8.88,5.86,14.14,5.86C106.26,153,111.42,150.859,115.14,147.14z"
          />
          <path
            d="M229,113c-5.26,0-10.42,2.14-14.14,5.86c-3.72,3.721-5.86,8.87-5.86,14.141c0,5.26,2.14,10.42,5.86,14.14 c3.72,3.72,8.88,5.86,14.14,5.86c5.26,0,10.42-2.141,14.14-5.86c3.73-3.72,5.86-8.88,5.86-14.14c0-5.26-2.13-10.42-5.86-14.141 C239.42,115.14,234.27,113,229,113z"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import EventBus from "event_bus";

export default {
  props: {
    boardId: {
      type: Number,
      required: false,
    },
    reactionableId: {
      type: Number,
      required: true,
    },
    reactionableType: {
      type: String,
      required: true,
      validator: (value) => ['Post', 'ChangelogEntry'].includes(value)
    },
    isAuthenticated: {
      type: String,
      required: true,
    },
    allowAnonymous: {
      type: Boolean,
      required: true,
    },
    initialReactions: {
      type: Object,
      default: () => ({}),
    },
    initialUserReactions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      reactions: this.initialReactions,
      userReactions: this.isAuthenticated === "true" 
        ? Object.fromEntries(this.initialUserReactions.map(emoji => [emoji, true]))
        : this.getCookie(`userReactions_${this.reactionableType}_${this.reactionableId}`) || {},
      initialSortedEmojis: Object.keys(this.initialReactions).sort((a, b) => this.initialReactions[b] - this.initialReactions[a]),
    };
  },
  computed: {
    sortedReactions() {
      return this.initialSortedEmojis.filter((emoji) =>
        this.reactions.hasOwnProperty(emoji)
      );
    },
  },
  methods: {
    getCsrfToken() {
      return document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content");
    },
    toggleReaction(emoji) {
      if (this.isAuthenticated === "false" && this.allowAnonymous === false) {
        EventBus.$emit("openRegModalSignup");
        return;
      }
      const url = this.getReactionableUrl();
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.getCsrfToken(),
      };
      let body = { 
        emoji,
        reactionable_type: this.reactionableType,
        reactionable_id: this.reactionableId
      };

      if (this.isAuthenticated === "false") {
        if (this.userReactions[emoji]) {
          body.reaction_id = this.userReactions[emoji];
        }
      } else {
        body.is_authenticated = this.isAuthenticated;
      }

      fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success || data.action) {
            this.updateReactions(
              emoji,
              data.action === "added",
              data.reaction_id
            );
            // Emit an event to update parent component
            this.$emit('reaction-updated', {
              reactionableId: this.reactionableId,
              reactions: this.reactions,
              userReactions: this.userReactions,
            });
          }
        });
    },
    updateReactions(emoji, isAdding, reactionId) {
      if (isAdding) {
        if (this.isAuthenticated === "false") {
          this.$set(this.userReactions, emoji, reactionId);
        } else {
          this.$set(this.userReactions, emoji, true);
        }
        this.$set(this.reactions, emoji, (this.reactions[emoji] || 0) + 1);
      } else {
        this.$delete(this.userReactions, emoji);
        this.reactions[emoji]--;
        if (this.reactions[emoji] <= 0) {
          this.$delete(this.reactions, emoji);
        }
      }

      if (this.isAuthenticated === "false") {
        this.setCookie(
          `userReactions_${this.reactionableType}_${this.reactionableId}`,
          JSON.stringify(this.userReactions)
        );
      }
    },
    openEmojiPicker() {
      const position = this.$refs.emojiButton.getBoundingClientRect();
      EventBus.$emit('openEmojiPicker', {
        position: position,
        callback: this.addReaction,
        reactionableType: this.reactionableType,
        reactionableId: this.reactionableId,
        openButtonRef: this.$refs.emojiButton
      });
    },
    addReaction(emoji) {
      const nativeEmoji = emoji.native;
      if (!this.reactions.hasOwnProperty(nativeEmoji)) {
        if (this.isAuthenticated === "false" && this.allowAnonymous === true || this.isAuthenticated === "true") {
          this.$set(this.reactions, nativeEmoji, 0);
        }
        this.initialSortedEmojis.push(nativeEmoji);
      }
      this.toggleReaction(nativeEmoji);
    },
    hasUserReacted(emoji) {
      return !!this.userReactions[emoji];
    },
    setCookie(name, value, days = 7) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = "; expires=" + date.toUTCString();
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(";");
      for (let c of ca) {
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(nameEQ) === 0)
          return JSON.parse(c.substring(nameEQ.length));
      }
      return null;
    },
    getReactionableUrl() {
      if (this.reactionableType === 'Post') {
        return `/boards/${this.boardId}/posts/${this.reactionableId}/reactions`;
      } else if (this.reactionableType === 'ChangelogEntry') {
        return `/updates/${this.reactionableId}/reactions`;
      }
      throw new Error('Invalid reactionableType');
    },
  },
};
</script>

<style scoped>
.user-reacted {
  border-color: var(--accent-color);
  background-color: color-mix(in srgb, var(--accent-color) 10%, transparent);
  font-weight: 500;
}

.user-reacted:hover {
  border-color: var(--accent-color);
  background-color: color-mix(in srgb, var(--accent-color) 10%, transparent);
  font-weight: 500;
}
</style>
