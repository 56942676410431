<template>
  <div class="text-left">
    <div v-if="this.projectSsoOnly === 'true' && this.customWebsiteHref != ''" >
      <div class="text-center flex">
        <a
          :href="this.customWebsiteHref"
          class="btn-main"
        >
          <span v-if="this.placement === 'navbar'">
            <div class="hidden sm:inline text-center">{{ this.buttonText }}</div>
            <span class="sm:hidden"
              ><svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clip-rule="evenodd"
                ></path></svg
            ></span>
          </span>
          <div v-else >{{ this.buttonText }}</div>
        </a>
      </div>
    </div>
    <div v-else>
      <div class="text-center flex" v-if="buttonStatus === 'true'">
        <a
          href="#"
          @click.prevent="vote_signup()"
          class="btn-main"
          >{{ this.buttonText }}</a>
      </div>
      <div v-else>
        <a
          href="#"
          id="auth-login-btn"
          :class="this.buttonClass"
          @click.prevent="login_dialog_visible = true"
        >
          <span v-if="this.placement === 'navbar'">
            <div class="hidden sm:inline text-center">{{ this.buttonText }}</div>
            <span class="sm:hidden"
              ><svg
                class="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clip-rule="evenodd"
                ></path></svg
            ></span>
          </span>
          <div class="text-center" v-else >{{ this.buttonText }}</div>
        </a>
      </div>
    </div>

    <Dialog :title="this.sign_in_with_password ? $t('Log in') : this.sign_up_with_password ? $t('Sign up') : this.forgot_password ? $t('Forgot your password?') : $t('Sign up') + ' / ' + $t('Log in')"
    :custom-max-width-class="'max-w-md'"
    v-model="login_dialog_visible"
    :back-function="this.sign_in_with_password || this.sign_up_with_password || this.forgot_password ? backToHome : null"
    >
      <div class="px-4 py-4">
        <transition v-if="!this.sign_in_with_password && !this.sign_up_with_password && !this.forgot_password">
          <div>
            <div class="flex flex-col gap-2">

              <div class="flex justify-center">
                <a :href="this.googleHref" class="w-full bg-white border border-neutral-400 rounded-md hover:bg-neutral-100 hover:no-underline px-4 py-3 flex items-center justify-center gap-2">
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 18 18" version="1.1"><title>logo_googleg_48dp</title> <desc>Created with Sketch.</desc> <g id="Google-Button" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="btn_google_signin_dark_normal_web" transform="translate(-15.000000, -15.000000)"><g id="logo_googleg_48dp" transform="translate(15.000000, 15.000000)"><path d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727 17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z" id="Shape" fill="#4285F4"></path> <path d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727 3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182 C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z" id="Shape" fill="#34A853"></path> <path d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9 C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182 L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727 0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z" id="Shape" fill="#FBBC05"></path> <path d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455 L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0 2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727 6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z" id="Shape" fill="#EA4335"></path> <polygon id="Shape" points="0 0 18 0 18 18 0 18"></polygon></g></g></g></svg></div>
                  <span class="text-black">Continue with Google</span>
                </a>
              </div>
              <!-- <div class="flex justify-center">
                <a :href="this.fbHref" class="w-full bg-white border-2 border-neutral-200 rounded-xl hover:bg-neutral-200 hover:no-underline font-medium px-4 py-3 flex items-center justify-center gap-2">
                  <div> 
                      <svg
                    role="img"
                    class="w-6 h-6"
                    style="color: #1877f2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z"
                    />
                  </svg>
                  </div>
                  <span>Continue with Facebook</span>
                </a>
              </div>

              <div class="flex justify-center">
                <a :href="this.twitterHref" class="w-full bg-white border-2 border-neutral-200 rounded-xl hover:bg-neutral-200 hover:no-underline font-medium px-4 py-3 flex items-center justify-center gap-2">
                  <div> 
                  <svg
                    role="img"
                    class="w-6 h-6"
                    style="color: #1da1f2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="currentColor"
                      d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"
                    />
                  </svg>
                  </div>
                  <span>Continue with Twitter</span>
                </a>
              </div> -->
            </div>

            <div class="or my-6 text-sm"><span class="text-center text-neutral-800">Or use your email and password</span></div>

            
            <div class="flex flex-col gap-2">
              <button
                class="w-full bg-white border border-neutral-400 rounded-md hover:bg-neutral-100 hover:no-underline px-4 py-3 flex items-center justify-center gap-2"
                @click.prevent = "showEmailLogin"
              ><svg data-v-7f08cb0e="" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-neutral-500"><path data-v-7f08cb0e="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                Sign in with email
              </button>
              <button
                class="w-full bg-white border border-neutral-00 rounded-md hover:bg-neutral-100 hover:no-underline px-4 py-3 flex items-center justify-center gap-2"
                @click.prevent = "showEmailSignUp"
              ><svg data-v-7f08cb0e="" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-neutral-500"><path data-v-7f08cb0e="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                Sign up with email
              </button>
            </div>
         </div>
        </transition>
        <transition v-if="this.sign_in_with_password">
          <div>
            <form
              id="new_user"
              action="/users/sign_in"
              @submit.prevent="signInWithPassword"
              accept-charset="UTF-8"
              method="post"
              class="form"
              ref="loginform"
            >
              <div
                class="bg-red-100 rounded border border-red-300 text-red-900 px-4 py-3 mb-4"
                v-if="login_error_msg"
              >
                <p>{{ login_error_msg }}</p>
                <p class="mt-1">
                  {{$t("Forgot your password?")}}
                  <a
                    href="#"
                    @click.prevent="showForgotPassword"
                    class="text-red-900 underline hover:text-red-800 hover:no-underline"
                    >{{ $t("Click here") }}</a
                  >
                </p>
              </div>
              <input
                type="hidden"
                name="authenticity_token"
                :value="this.authToken"
              />
              <div>
                <label for="user_email" class="label">{{ $t("Email") }}</label>
                <input
                  autocomplete="email"
                  required="required"
                  type="email"
                  value=""
                  name="user[email]"
                  id="user_email"
                  class="input"
                />
              </div>
              <div class="mt-4">
                <label for="user_password" class="label">{{ $t("Password") }}</label>
                <input
                  autocomplete="off"
                  required="required"
                  type="password"
                  name="user[password]"
                  id="user_password"
                  class="input"
                />
              </div>
              <input
                checked
                type="checkbox"
                name="user[remember_me]"
                id="remember_me"
                class="hidden"
                value="1"
              />
              <div class="text-center w-full btn-main mt-6 px-4 py-3">
                <input
                    type="submit"
                    name="commit"
                    :value="$t('Log in')"
                    class="w-full bg-transparent font-medium"
                  />
              </div>
            </form>
          </div>
        </transition>
        <transition v-if="this.sign_up_with_password">
          <div>
            <form
            id="new_user"
            action="/users"
            @submit.prevent="signUp"
            accept-charset="UTF-8"
            method="post"
            class="form"
            ref="regform"
            v-if="this.use_email"
          >
            <div
              class="bg-red-100 rounded border border-red-300 text-red-900 px-4 py-2 mb-4"
              v-if="reg_error_msgs.length > 0"
            >
              <p class="mx-1" v-for="error in reg_error_msgs" :key="error[0]">
                {{ error }}
              </p>
            </div>
            <input name="utf8" type="hidden" value="✓" />
            <input
              type="hidden"
              name="authenticity_token"
              :value="this.authToken"
            />
            <div>
              <label for="user_name" class="label">{{ $t("Name") }}</label>
              <input
                required="required"
                type="text"
                name="user[name]"
                id="user_name"
                class="input"
                autofocus
              />
            </div>
            <div class="mt-4">
              <label for="user_email" class="label">{{ $t("Email") }}</label>
              <input
                required="required"
                type="email"
                value
                name="user[email]"
                id="user_email"
                class="input"
              />
            </div>
            <!-- <div class="mt-4">
              <input
                autocomplete="off"
                required="required"
                type="hidden"
                name="user[skip_password_validation]"
                value="true"
                id="user_password"
                class="input"
              />
            </div> -->
            <div class="mt-4">
            <label for="user_password" class="label">{{ $t("Password") }}</label>
              <input
                autocomplete="new-password"
                required="required"
                type="password"
                name="user[password]"
                id="user_password"
                class="input"
              />
            </div>
            <div class="mt-2">
              <label for="user_agree_checkbox" class="text-sm text-neutral-700 flex items-center gap-2">
                <input name="user[agree_checkbox]" type="hidden" value="0" />
                <input
                  required="required"
                  type="checkbox"
                  value="1"
                  name="user[agree_checkbox]"
                  id="user_agree_checkbox"
                />
                {{ $t("I agree with") }}
                <a
                  href="https://www.feedbear.com/privacy-policy"
                  title="Privacy Policy"
                  target="_blank"
                  class="text-neutral-600 underline hover:no-underline hover:text-project-500"
                  >{{ $t("Privacy Policy") }}</a
                >
                {{ $t("and") }}
                <a
                  href="https://www.feedbear.com/terms"
                  title="Terms of Service"
                  target="_blank"
                  class="text-neutral-600 underline hover:no-underline hover:text-project-500"
                  >{{ $t("Terms and Conditions") }}</a
                >
              </label>
            </div>

            <input
              :value="this.timeNow"
              type="hidden"
              name="user[agreed_with_privacy_policy]"
              id="user_agreed_with_privacy_policy"
            />
            <input
              :value="this.projectSubdomain"
              type="hidden"
              name="user[new_from_project]"
              id="user_new_from_project"
            />

            <div class="text-center w-full btn-main mt-6 px-4 py-3">
              <input
                type="submit"
                name="commit"
                :value="$t('Sign up')"
                class="w-full bg-transparent font-medium"
              />
            </div>
          </form>
          </div>
        </transition>
        <transition name="popdown" v-if="this.forgot_password">
        <div class="mx-auto my-4" v-if = "this.message && !this.forgot_email_does_not_exist">
          <p class="px-4 py-4 bg-green-100 text-green-800 border border-green-300 rounded-md">{{ $t("You will receive an email with instructions on how to reset your password in a few minutes") }}.</p>
        </div>
        <div class="mx-auto my-4" v-if = "this.message && this.forgot_email_does_not_exist">
          <p class="px-4 py-4 bg-green-100 text-green-800 border border-green-300 rounded-md">{{ $t("If you have an account with us, we've sent an email to") }} {{this.email_user}} {{ $t("with a link that you can use to reset your password") }}</p>
        </div>
        <div v-if = "!this.message">
          <form
            id="forgot_password"
            action="/users/password"
            @submit.prevent="forgotPassword"
            accept-charset="UTF-8"
            method="post"
            class="form"
            v-if="this.use_email"
            ref="forgotloginform"
          >
            <input
              type="hidden"
              name="authenticity_token"
              :value="this.authToken"
            />
            <input
              type="hidden"
              name="user_intent"
              :value="currentPath"
            />
            <div class="mt-2">
              <label for="user_email" class="label">{{ $t("Email") }}</label>
              <input
                autocomplete="email"
                required="required"
                type="email"
                value=""
                name="user[email]"
                id="forgot_password"
                class="input"
              />
            </div>
              <div class="text-center w-full btn-main mt-6 px-4 py-3">
                <input
                  type="submit"
                  name="commit"
                  :value="$t('Send email')"
                  class="w-full bg-transparent font-medium"
                />
            </div>
          </form>
        </div>
        </transition>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "./dialog";
import EventBus from "../event_bus";
import keyIcon from "images/icons/key.svg";

export default {
  props: [
    "button-class",
    "button-text",
    "time-now",
    "auth-token",
    "project-subdomain",
    "project-name",
    "google-href",
    "twitter-href",
    "fb-href",
    "placement",
    "button-status",
    "custom-website-href",
    "project-sso-only",
  ],
  data() {
    return {
      login_dialog_visible: false,
      signup_dialog_visible: false,
      use_email: true,
      login_error_msg: "",
      reg_error_msgs: [],
      login_togle: true,
      password_forgot_dialog_visible: false,
      message: false,
      email_does_not_exists: false,
      forgot_email_does_not_exist: false,
      email_user: "",
      keyIcon,
      sign_in_with_password: false,
      sign_up_with_password: false,
      forgot_password: false,
    };
  },
  computed: {
    currentPath () {
      return window.location.pathname
    }
  },
  mounted() {
    EventBus.$on("openRegModal", () => {
      this.login_dialog_visible = true;
      this.login_togle = true;
    });
    EventBus.$on("openRegModalSignup", () => {
      this.login_dialog_visible = true;
    });
  },


  components: {
    Dialog,
  },

  methods: {
    vote_signup() {
      this.login_dialog_visible = true;
    },
    signIn() {
      var data = new FormData(this.$refs.loginform);
      this.email_user = data.get('user[email]')
      Rails.ajax({
        url: "/users/send/link",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          if (data == "notExist")
          {
            this.email_does_not_exists = true;
            this.use_email = false;
          }
          else
          {
            this.email_does_not_exists = false;
            this.use_email = false;
          }
        },
        error: (data, status) => {
          console.log(data);
          this.login_error_msg = data.error;
        },
      });
    },

    forgotPassword(){
      var data = new FormData(this.$refs.forgotloginform);
      this.email_user = data.get('user[email]')
      Rails.ajax({
        url: "/users/forgot/link",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          if (data == "notExist")
          {
            this.forgot_email_does_not_exist = true;
            this.password_forgot_dialog_visible = true;
            this.message = true;
          }
          else
          {
            this.forgot_email_does_not_exist = false;
            this.password_forgot_dialog_visible = true;
            this.message = true;
          }
        },
        error: (data, status) => {
          console.log(data);
          this.login_error_msg = data.error;
        },
      });
    },

    showEmailLogin() {
      this.sign_in_with_password = true;
    },

    showEmailSignUp() {
      this.sign_up_with_password = true;
    },

    showForgotPassword() {
      this.sign_in_with_password = false;
      this.sign_up_with_password = false;
      this.forgot_password = true;
    },

    backToHome() {
      this.sign_in_with_password = false;
      this.sign_up_with_password = false;
      this.forgot_password = false;
    },

    signInWithPassword(){
      var data = new FormData(this.$refs.loginform);

      Rails.ajax({
        url: "/users/sign_in",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          if(window.location.href.split('/').at(-1) == 'private') {
            window.location.href = window.location.href.replace('/private', '')
          } else {
            reloadWithTurbolinks();
          }
        },
        error: (data, status) => {
          console.log(data);
          this.login_error_msg = data.error_message;
        },
      });
    },

    signUp() {
      var data = new FormData(this.$refs.regform);

      Rails.ajax({
        url: "/users",
        type: "POST",
        data: data,
        dataType: "json",
        beforeSend: function () {
          return true;
        },
        success: (data) => {
          // Assuming successful registration redirects or returns a success status
          // sign in the user
          // recreate the form data and submit as login
          var data = new FormData(this.$refs.regform);
          Rails.ajax({
            url: "/users/sign_in",
            type: "POST",
            data: data,
            dataType: "json",
            beforeSend: function () {
              return true;
            },
            success: (data) => {
              if(window.location.href.split('/').at(-1) == 'private') {
                window.location.href = window.location.href.replace('/private', '')
              } else {
                reloadWithTurbolinks();
              }
            },
            error: (data, status) => {
              console.log(data);
            }
          });
        },
        error: (data, status) => {
          this.reg_error_msgs = [];
          if (data.responseJSON && data.responseJSON.errors) {
            for (const error in data.responseJSON.errors) {
              this.reg_error_msgs.push(
                `${error.charAt(0).toUpperCase() + error.slice(1)} ${
                  data.responseJSON.errors[error].join(', ')
                }`
              );
            }
          } else {
            this.reg_error_msgs.push("An error occurred during sign up");
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-soc {
  @apply p-2 shadow-md rounded-md border border-neutral-100 transition duration-150 ease-in-out;
}

.btn-soc:hover {
  @apply shadow-lg;
}
.btn-spacing{
  margin-left: 25px;
  background-color: white;
  cursor: pointer;
}
.vote-button {
  background-color: white;
  color: black;
  padding: 6px;
  border-radius: 5px;
  width: 170px;
  margin: 10px;
  font-size: 20px;
}
@media screen and (max-width:640px) {
  .dialog-modal-login-method{
    flex-wrap: wrap;
    height: max-content;
    div{
      padding: 8px 16px; 
      input{
        margin: 0px;
      }
    }
  }
  #email-login-container{
    margin-top: 0px;
    @media only screen and (max-width: 480px){
      display: grid;
      text-align: center;
      
      #link_btn{
        padding-left: 20%;
      }
    }
  }
}

.or {
  @apply flex justify-center relative;

  span {
    @apply bg-white px-2 z-10 text-neutral-700;
  }

  &::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: theme("colors.neutral.400");
    transform: translateY(-50%);
    top: 50%;

    @apply z-0;
  }
}
</style>
