var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"text-xl font-bold text-neutral-800"},[_vm._v("Spam Settings")]),_vm._v(" "),_c('p',{staticClass:"text-neutral-800"},[_vm._v("Protect your project from spam and abuse.")]),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveSettings.apply(null, arguments)}}},[_c('div',{staticClass:"mt-4"},[_c('h4',{staticClass:"text-lg font-bold text-neutral-800"},[_vm._v("Strictness")]),_vm._v(" "),_c('p',{staticClass:"text-neutral-800"},[_vm._v("\n      Choose how strict you want to be when it comes to labeling content as\n      spam.\n    ")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-1 gap-4 mt-4"},[_c('label',{class:[
          'flex items-center gap-4 p-4 border-2 rounded-lg cursor-pointer transition-all duration-200',
          { 'border-[var(--accent-color)]': _vm.strictness === 'none' } ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.strictness),expression:"strictness"}],staticClass:"hidden",attrs:{"type":"radio","name":"strictness","value":"none"},domProps:{"checked":_vm._q(_vm.strictness,"none")},on:{"change":function($event){_vm.strictness="none"}}}),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-center bg-gray-100 rounded-full p-1 w-8 h-8"},[_c('div',{staticClass:"w-6 h-6 rounded-full",class:_vm.strictness === 'none' ? 'bg-gray-500' : 'bg-gray-300'})]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('label',{class:[
          'flex items-center gap-4 p-4 border-2 rounded-lg cursor-pointer transition-all duration-200',
          { 'border-[var(--accent-color)]': _vm.strictness === 'low' } ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.strictness),expression:"strictness"}],staticClass:"hidden",attrs:{"type":"radio","name":"strictness","value":"low"},domProps:{"checked":_vm._q(_vm.strictness,"low")},on:{"change":function($event){_vm.strictness="low"}}}),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-center bg-gray-100 rounded-full p-1 w-8 h-8"},[_c('div',{staticClass:"w-6 h-6 rounded-full",class:_vm.strictness === 'low' ? 'bg-emerald-500' : 'bg-gray-300'})]),_vm._v(" "),_vm._m(1)]),_vm._v(" "),_c('label',{class:[
          'flex items-center gap-4 p-4 border-2 rounded-lg cursor-pointer transition-all duration-200',
          { 'border-[var(--accent-color)]': _vm.strictness === 'medium' } ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.strictness),expression:"strictness"}],staticClass:"hidden",attrs:{"type":"radio","name":"strictness","value":"medium"},domProps:{"checked":_vm._q(_vm.strictness,"medium")},on:{"change":function($event){_vm.strictness="medium"}}}),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-center bg-gray-100 rounded-full p-1 w-8 h-8"},[_c('div',{staticClass:"w-6 h-6 rounded-full",class:_vm.strictness === 'medium' ? 'bg-yellow-400' : 'bg-gray-300'})]),_vm._v(" "),_vm._m(2)]),_vm._v(" "),_c('label',{class:[
          'flex items-center gap-4 p-4 border-2 rounded-lg cursor-pointer transition-all duration-200',
          { 'border-[var(--accent-color)]': _vm.strictness === 'high' } ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.strictness),expression:"strictness"}],staticClass:"hidden",attrs:{"type":"radio","name":"strictness","value":"high"},domProps:{"checked":_vm._q(_vm.strictness,"high")},on:{"change":function($event){_vm.strictness="high"}}}),_vm._v(" "),_c('div',{staticClass:"flex items-center justify-center bg-gray-100 rounded-full p-1 w-8 h-8"},[_c('div',{staticClass:"w-6 h-6 rounded-full",class:_vm.strictness === 'high' ? 'bg-rose-500' : 'bg-gray-300'})]),_vm._v(" "),_vm._m(3)])])]),_vm._v(" "),_c('div',{staticClass:"mt-8"},[_c('h4',{staticClass:"text-lg font-bold text-neutral-800"},[_vm._v("Spam Keywords")]),_vm._v(" "),_c('p',{staticClass:"text-neutral-800"},[_vm._v("\n      Add keywords or phrases that should be automatically flagged as spam.\n    ")]),_vm._v(" "),_c('div',{staticClass:"mt-4"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.spam_keywords),expression:"spam_keywords"}],staticClass:"input h-40",attrs:{"rows":"4","spellcheck":"false","placeholder":"Enter keywords or phrases separated by commas (e.g. 'spam, scam, fake')"},domProps:{"value":(_vm.spam_keywords)},on:{"input":function($event){if($event.target.composing){ return; }_vm.spam_keywords=$event.target.value}}})])]),_vm._v(" "),_vm._m(4)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"font-bold text-neutral-800"},[_vm._v("None")]),_vm._v(" "),_c('p',{staticClass:"text-sm text-gray-600"},[_vm._v("\n            No filtering, all content is allowed\n          ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"font-bold text-neutral-800"},[_vm._v("Low")]),_vm._v(" "),_c('p',{staticClass:"text-sm text-gray-600"},[_vm._v("\n            Minimal filtering, allows most content through\n          ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"font-bold text-neutral-800"},[_vm._v("Medium")]),_vm._v(" "),_c('p',{staticClass:"text-sm text-gray-600"},[_vm._v("\n            Balanced filtering, suitable for most use cases\n          ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"font-bold text-neutral-800"},[_vm._v("High")]),_vm._v(" "),_c('p',{staticClass:"text-sm text-gray-600"},[_vm._v("\n            Aggressive filtering, may block some legitimate content\n          ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-8"},[_c('button',{staticClass:"btn-main",attrs:{"type":"submit"}},[_vm._v("Save")])])}]

export { render, staticRenderFns }